import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from './store/reducers/rootReducer'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { reduxFirestore, getFirestore } from 'redux-firestore'
import { reactReduxFirebase, getFirebase } from 'react-redux-firebase'
import fbConfig from './config/fbConfig'
import config from './config/config.js'
import { Auth0Provider } from '@auth0/auth0-react'

console.log('process.env', process.env)

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore })),
    reduxFirestore(fbConfig),
    reactReduxFirebase(fbConfig, {
      useFirestoreForProfile: true,
      userProfile: 'users',
      attachAuthIsReady: true,
    }),
  ),
)

const redirectUri = process.env.REACT_APP_temp
  ? 'http://localhost:3000/admin_user'
  : 'https://fir-crud-2d38f.firebaseapp.com/admin_user'
// : config.configFb.authDomain + "/admin_user";//backup.

store.firebaseAuthIsReady.then(() => {
  ReactDOM.render(
    <Provider store={store}>
      <Auth0Provider
        domain="dev-betterspaces.us.auth0.com"
        clientId="EQ6dXrAcSdGj0emauerLA0pjwP90b6uR"
        redirectUri={redirectUri}
        useRefreshTokens={true}
        cacheLocation="localstorage"
        // audience={audience}
      >
        <App />
      </Auth0Provider>
    </Provider>,
    document.getElementById('root'),
  )
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
