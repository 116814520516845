import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { signUp } from '../../store/actions/authActions'
import { NavLink } from 'react-router-dom'
import firebase from 'firebase'
import { withStyles } from '@material-ui/core'
import { compose } from 'redux'
import LinearProgress from '@material-ui/core/LinearProgress'

const styles = theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
})

class SignUp extends Component {
  // async  componentDidMount() {
  //    const response = await fetch('https://api.betterspaces.com/api/v1/buildings/list');
  //     const data = await response.json();
  //     var bboption =[]
  //     await  data.forEach(function(entry) {
  //         var obj = {};
  //         obj["value"] = entry._id;
  //         obj["label"] = entry.name;
  //         bboption.push(obj);
  //     });
  //     this.setState({ boptions: bboption })
  //     }
  // state = {
  //     firstName: '',
  //     lastName: '',
  //     email: '',
  //     password: '',
  //     selectedOption: null,
  //     boptions: [],
  //     buildingId:'',
  //     buildingName:'',
  //     loading: false,
  // }

  state = {
    firstName: '',
    lastName: '',
    uid: '',
    email: '',
    password: '',
    isLoginWithGoole: false,
    isLoginWithApple: false,
    loading: false,
  }

  hideLoader = () => {
    this.setState({ loading: false })
  }

  showLoader = () => {
    this.setState({ loading: true })
  }

  handleChangeDropDown = selectedOption => {
    this.setState({ selectedOption }, () => {
      this.setState({
        buildingId: this.state.selectedOption.value,
        buildingName: this.state.selectedOption.label,
      })
    })
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value,
    })
  }

  handleSubmit = e => {
    this.showLoader()
    e.preventDefault()
    this.props.signUp(this.state)
  }

  responseGoogle = response => {
    console.log('respionse == ', response)
    // const accessToken = response.accessToken;
    // const googleId = response.googleId;
    const email = response
      ? response.profileObj
        ? response.profileObj.email
        : ''
      : ''
    // const name = response.profileObj.name
    this.setState({
      firstName: response.profileObj.givenName,
      lastName: response.profileObj.familyName,
      email: email,
      password: 'Q!W@E#R$',
    })

    this.props.signUp(this.state)
  }

  async signInWithApple() {
    this.showLoader()

    const provider = new firebase.auth.OAuthProvider('apple.com')
    const component = this
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function(result) {
        // The signed-in user info.
        // var user = result.user;
        component.setState({
          uid: result.user.uid,
          firstName: result.user.displayName,
          lastName: '',
          email: result.user.email,
          isLoginWithGoole: false,
          isLoginWithApple: true,
        })

        component.props.signUp(component.state)
      })
  }

  async signInWithGoogle() {
    this.showLoader()

    const provider = new firebase.auth.GoogleAuthProvider()
    const component = this
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function(result) {
        // The signed-in user info.
        // var user = result.user;
        component.setState({
          uid: result.user.uid,
          firstName: result.user.displayName,
          lastName: '',
          email: result.user.email,
          isLoginWithGoole: true,
          isLoginWithApple: false,
        })

        component.props.signUp(component.state)
      })
  }

  render() {
    const { plan, auth, authError } = this.props
    // const { selectedOption } = this.state;
    // const { boptions } = this.state;

    if (auth.uid && plan) {
      if (plan === '-') return <Redirect to="/welcome" />
      else return <Redirect to="/" />
    }

    // if (authError) {
    //     {this.hideLoader()}
    //   }

    return (
      <div className="">
        <div className={this.props.classes.root}>
          {this.state.loading ? <LinearProgress /> : null}
        </div>
        <div className="login-body sign-up-body">
          <h1 className="navbar-brand-logo">
            <a href="#" className="logo">
              <picture>
                <source
                  srcSet="assets/img/logo-bs-white.svg"
                  media="(min-width: 768px)"
                />
                <img src="assets/img/logo-bs.svg" alt="Logo" />
              </picture>
            </a>
          </h1>
          <div className="login-content">
            <div className="login-content-inner">
              <h2>Create Account</h2>
              <div className="social-icon-group">
                <a
                  href="#"
                  onClick={async () => {
                    await this.signInWithGoogle()
                  }}
                >
                  <img src="../assets/img/google-plus.png" />
                </a>
                <a
                  href="#"
                  onClick={async () => {
                    await this.signInWithApple()
                  }}
                >
                  <img src="assets/img/linkedin.png" />
                </a>
              </div>
              <p>or use your email account</p>
              <form onSubmit={this.handleSubmit}>
                <div className="field-input">
                  <div className="custom-input">
                    <span className="icon-input">
                      <img src="assets/img/icon-user.svg" />
                    </span>
                    <input
                      className="input-control"
                      type="text"
                      id="firstName"
                      onChange={this.handleChange}
                      placeholder="Name"
                    />
                  </div>
                </div>
                <div className="field-input">
                  <div className="custom-input">
                    <span className="icon-input">
                      <img src="assets/img/icon-email.svg" />
                    </span>
                    <input
                      className="input-control"
                      type="text"
                      id="email"
                      onChange={this.handleChange}
                      placeholder="Email or Phone Number"
                    />
                  </div>
                </div>
                <div className="field-input">
                  <div className="custom-input">
                    <span className="icon-input">
                      <img src="assets/img/icon-password.svg" />
                    </span>
                    <input
                      className="input-control"
                      type="password"
                      id="password"
                      onChange={this.handleChange}
                      placeholder="Password"
                    />
                  </div>
                </div>
                <button type="submit" className="btn btn-blue">
                  SIGN UP
                </button>
                <div className="red-text center">
                  {authError ? <p>{authError}</p> : null}
                </div>
              </form>
            </div>
          </div>
          <div className="login-bg">
            <div className="login-bg-content">
              <h2>Welcome Back!</h2>
              <p>
                Enjoy all the same interactive features from our award winning
                app on your desktop.
              </p>
              <NavLink to="/signin" className="btn btn-white">
                SIGN IN
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    plan: state.auth.plan,
    auth: state.firebase.auth,
    authError: state.auth.authError,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    signUp: newUser => dispatch(signUp(newUser)),
  }
}

//export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
const enhance = compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
)

export default enhance(SignUp)
