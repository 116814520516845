import React from 'react'
import CheckIcon from '../../../assets/image/Check'
import Close from '../../../assets/image/close'
import PlusIcon from '../../../assets/image/PlusIcon'
import './eventlist.css'

const ConfirmeCard = props => {
  console.log(
    '🚀 ~ file: ConformeCard.js ~ line 7 ~ ConfirmeCard ~ props',
    props,
  )
  return (
    <div className="confirm-popup-wraper">
      <div className="rsvp-confirm-card-wrapper fadeIn">
        <div className="close-icon-card" onClick={props.handleCloseModel}>
          {/* <Close fill="white" height="32px" width="32px" /> */}
          <img src="../../../assets/img/close-white.svg" alt="Close" />
        </div>
        <div className="checksymbol-confirm-card">
          {/* <CheckIcon fill="white" height="236px" width="236px" />{" "} */}
          <img src="../../../assets/img/icon_confirm_tick.svg" alt="Close" />
          <p>Confirmed!</p>
        </div>
        <div
          className="addcalander-button-wrapper-confirm-card"
          onClick={props.handelSubmitModel}
        >
          {/* <PlusIcon fill="white" /> */}
          <p>Add To Calendar</p>
        </div>
      </div>
    </div>
  )
}

export default ConfirmeCard
