import React, { Component } from 'react'
import { connect } from 'react-redux'
import $ from 'jquery'
import { compose } from 'redux'
import { Redirect, withRouter } from 'react-router-dom'
import 'react-sweet-progress/lib/style.css'
import './explore.css'
import { Backdrop, CircularProgress, withStyles } from '@material-ui/core'
import config from '../../../config/config'
import {
  FirebaseEvent,
  firebaseTrackEvent,
} from '../../../config/FirebaseManager'
import UserProfile from '../../auth/UserProfile'
import getProfileDetailsLegacy from '../../../requests/getProfileDetailsLegacy'

const styles = theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#287fde',
  },
})

// SegmentTypes : String
export const SegmentType = {
  category: 1,
  duration: 2,
  instructor: 3,
}

class ExploreCategories extends Component {
  constructor(props) {
    super(props)

    // content_viewopened ****************************
    firebaseTrackEvent(FirebaseEvent.content_viewopened, {})
    // ***************************************

    var selected_segment = localStorage.getItem('selected_segment')

    if (selected_segment) {
    } else {
      selected_segment = '1'
    }

    let categ = ''
    let durat = ''
    let instr = ''
    switch (parseInt(selected_segment, 10)) {
      case SegmentType.category:
        categ = 'nav-link active'
        durat = 'nav-link'
        instr = 'nav-link'
        break
      case SegmentType.duration:
        categ = 'nav-link'
        durat = 'nav-link active'
        instr = 'nav-link'
        break
      case SegmentType.instructor:
        categ = 'nav-link'
        durat = 'nav-link'
        instr = 'nav-link active'
        break
      default:
        categ = 'nav-link active'
        durat = 'nav-link'
        instr = 'nav-link'
        break
    }

    this.state = {
      loading: false,
      exploreContentList: [],
      exploreContentListFiltered: [],

      selectedSegment: parseInt(selected_segment, 10), //1 for category, 2 for duration, 3 for
      categoryClass: categ,
      durationClass: durat,
      instructorClass: instr,
    }
  }

  async componentDidMount() {
    // fetching profileDetails needed for sidebar to show amentities tab according to the activeBuilding of the current user.
    const isNotProfileDetailsLoaded = !this.props.profileDetails
      .isProfileDetailsLoaded

    if (isNotProfileDetailsLoaded) {
      const profileDetailsReq = await getProfileDetailsLegacy()

      if (profileDetailsReq.status === 'fulfilled') {
        const isStatusZero =
          profileDetailsReq.value && profileDetailsReq.value.status === 0

        if (isStatusZero) {
          return // handle status code 0 from backend.
        }
        const data = profileDetailsReq.value.data
        this.props.dispatch({
          type: 'profileDetails/update',
          payload: data,
        })
      }
      if (profileDetailsReq.status === 'rejected') {
        alert('rejected..')
      }
    }

    if (window.innerWidth >= 655) {
      $(document).ready(function() {
        document.body.classList.add('open-sidebar')

        $('.modal-toggle').on('click', function(e) {
          e.preventDefault()
          $('.modal').toggleClass('is-visible')
        })
      })
    } else {
      $(document).ready(function() {
        document.body.classList.remove('open-sidebar')

        $('.modal-toggle').on('click', function(e) {
          e.preventDefault()
          $('.modal').toggleClass('is-visible')
        })
      })
    }

    let accessToken = localStorage.getItem('accessToken')
    console.log('access token: ', accessToken)

    // FETCH Content list ****************/
    this.fetchContentList()
    // ********************************/
  }

  fetchContentList() {
    let accessToken = localStorage.getItem('accessToken')
    console.log('access token: ', accessToken)

    // FETCH Content list ****************/
    this.showLoader()

    fetch(config.api_url + '/api/v1/users/contents/', {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
        Authorization: accessToken,
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(), // body data type must match "Content-Type" header
    })
      .then(res => res.json())
      .then(data => {
        if (data.code === 200) {
          this.setState({
            exploreContentList: data.data.contents,
          })

          this.segmentButtonClicked(this.state.selectedSegment)
          // this.segmentChanged(this.state.selectedSegment);
        } else {
          if (data.code === 400) {
            // if (window.confirm(data.msg + "\nDo you want to logout?")) {
            console.log('InvalidToken: ' + localStorage.getItem('accessToken'))
            UserProfile.removeUser()
            this.props.history.push('/signin')
            // this.props.signOut();
            // console.log("Yes");
            // }
          }
        }

        this.hideLoader()
      })
    // ********************************/
  }

  segmentButtonClicked = Segment => {
    switch (Segment) {
      case SegmentType.category:
        this.setState({
          categoryClass: 'nav-link active',
          durationClass: 'nav-link',
          instructorClass: 'nav-link',
          selectedSegment: Segment,
        })
        break
      case SegmentType.duration:
        this.setState({
          categoryClass: 'nav-link',
          durationClass: 'nav-link active',
          instructorClass: 'nav-link',
          selectedSegment: Segment,
        })
        break
      case SegmentType.instructor:
        this.setState({
          categoryClass: 'nav-link',
          durationClass: 'nav-link',
          instructorClass: 'nav-link active',
          selectedSegment: Segment,
        })
        break
      default:
        break
    }

    this.segmentChanged(Segment)
  }

  exploreContent_clicked = content => {
    let dataToPass = ''
    switch (this.state.selectedSegment) {
      case SegmentType.category:
        dataToPass = content.category
        break
      case SegmentType.duration:
        dataToPass = content.duration
        break
      case SegmentType.instructor:
        dataToPass = content.instructor._id
        break
      default:
        break
    }

    this.props.history.push({
      pathname: '/contents',
      selectedSegment: this.state.selectedSegment,
      id: dataToPass,
      // search: '?query=abc',
      // state: { detail: 'some_value' }
    })
    // this.props.history.push('/exploreList');
  }

  showLoader = () => {
    this.setState({ loading: true })
  }

  hideLoader = () => {
    this.setState({ loading: false })
  }

  segmentChanged(e) {
    localStorage.setItem('selected_segment', e)

    switch (e) {
      case SegmentType.category:
        let filtered = this.state.exploreContentList.filter(
          (ele, ind) =>
            ind ===
            this.state.exploreContentList.findIndex(
              elem => elem.title && elem.title === ele.title,
            ),
        )

        filtered.sort(function(a, b) {
          if (a.title < b.title) {
            return -1
          }
          if (a.title > b.title) {
            return 1
          }
          return 0
        })

        this.setState({
          selectedSegment: SegmentType.category,
          exploreContentListFiltered: filtered,
        })
        break
      case SegmentType.duration:
        filtered = this.state.exploreContentList.filter(
          (ele, ind) =>
            ind ===
            this.state.exploreContentList.findIndex(
              elem => elem.duration && elem.duration === ele.duration,
            ),
        )
        filtered.sort((a, b) => (a.durationInt > b.durationInt ? 1 : -1))
        this.setState({
          selectedSegment: SegmentType.duration,
          exploreContentListFiltered: filtered,
        })
        break
      case SegmentType.instructor:
        filtered = this.state.exploreContentList.filter(
          (ele, ind) =>
            ind ===
            this.state.exploreContentList.findIndex(
              elem =>
                elem.instructor &&
                elem.instructor.name &&
                elem.instructor.name === ele.instructor.name,
            ),
        )

        filtered.sort(function(a, b) {
          if (a.instructor.name < b.instructor.name) {
            return -1
          }
          if (a.instructor.name > b.instructor.name) {
            return 1
          }
          return 0
        })

        this.setState({
          selectedSegment: SegmentType.instructor,
          exploreContentListFiltered: filtered,
        })
        break

      default:
        break
    }
  }

  render() {
    const { auth } = this.props
    let {
      loading,
      exploreContentListFiltered,
      categoryClass,
      durationClass,
      instructorClass,
    } = this.state

    if (localStorage.getItem('accessToken') === false && !auth.uid) {
      console.log(
        '🚀 ~ file: ExploreCategories.js ~ line 287 ~ ExploreCategories ~ render ~ localStorage',
        localStorage.getItem('accessToken'),
      )
      alert('got it 5')
      return <Redirect to="/signin" />
    }
    console.log('auth uid ' + auth.uid)

    return (
      <div>
        <Backdrop className={this.props.classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <div class="explore_main-wrap explore-layout-wrapper">
          {/* add content model code starts */}

          <div class="player-detail-inner" />
          {/* <div class="player-detail"> */}
          {/* <SegmentedControl
              name="oneDisabled"
              options={[
                { label: "Category", value: "category", default: true },
                { label: "Duration", value: "duration" },
                { label: "Instructor", value: "instructor" },
              ]}
              setValue={(newValue) => this.segmentChanged(newValue)}
              style={{ width: 400, color: "#287fde" }} // purple400
            /> */}

          {/* <SegmentedControl
              onChange={this.colorUpdated}
              value={this.state.color}
              name="color"
            >
              <span value="red">Red</span>
              <span value="blue">Blue</span>
            </SegmentedControl> */}
          {/* <div className="background" style={{ background: "#287fde" }} />
          </div> */}
          <ul className="tab-nav-wrap nav nav-pills">
            <li
              class="nav-item"
              onClick={() => this.segmentButtonClicked(SegmentType.category)}
            >
              <a href="#/" class={categoryClass}>
                Category
              </a>
            </li>
            <li
              class="nav-item"
              onClick={() => this.segmentButtonClicked(SegmentType.duration)}
            >
              <a href="#/" class={durationClass}>
                Duration
              </a>
            </li>
            <li
              class="nav-item"
              onClick={() => this.segmentButtonClicked(SegmentType.instructor)}
            >
              <a href="#/" class={instructorClass}>
                Instructor
              </a>
            </li>
          </ul>

          {/* Player model code starts */}
          <div class="row explore_views_row">
            {exploreContentListFiltered &&
              exploreContentListFiltered.map(content => {
                let imageURL = ''
                let contentTitle = ''

                switch (this.state.selectedSegment) {
                  case SegmentType.category:
                    imageURL = content.categoryImage
                    contentTitle = content.title
                    break
                  case SegmentType.duration:
                    imageURL = content.durationImage
                    contentTitle = content.duration + ' min'
                    break
                  case SegmentType.instructor:
                    imageURL = content.instructor.instructorImage
                    contentTitle = content.instructor.name
                    break
                  default:
                    break
                }

                return (
                  // <div class="col-12 col-md-6 col-xl-3 explore_box_row">
                  <div class="explore_box_row">
                    <div class="box-bg">
                      <div class="explore_box_list_item">
                        <div class="explore_box_image">
                          <img
                            onClick={() => this.exploreContent_clicked(content)}
                            class="explore_thumb-img"
                            src={imageURL || 'assets/img/placeholder.png'}
                            alt=""
                          />
                        </div>
                        <div class="explore_category_text">
                          <div class="explore_d-flex-box explore_main-title">
                            <h4>{contentTitle}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    posts: state.firestore.ordered.posts,
    auth: state.firebase.auth,
    notifications: state.firestore.ordered.notifications,
    profileDetails: state.profileDetails, // ~sahil
  }
}

const enhance = compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps),
)

export default enhance(withRouter(ExploreCategories))
