import React, { useState } from 'react'

const IosModel = props => {
  const [className, setclassName] = useState('')

  const closeModel = () => {
    setclassName('fadeOut')
    props.handleCloseModel()
  }

  return (
    <div className={`rsvp-confirm-model-wrapper fadeIn ${className}`}>
      <div className="rsvp-confirm-model" style={{height:`${props.termsAndCondition?"180px":"150px"}`}}>
        <h5 style={{ paddingLeft: 0 }}> {props.termsAndCondition ? "Terms and Conditions":"RSVP"}</h5>
        <p>{props.termsAndCondition ? "Please review our Terms and Condtions.In order to proceed, you must agree to our Terms and Conditions.":"Confirm that you would like to RSVP for this event"}</p>
        <div className="confirm-button-wrapper">
        {props.termsAndCondition && <button onClick={()=>{
          window.open(
            "https://storage.googleapis.com/betterspaces_content/room_tc_61d2ce9bbe04916cf0de4e03.pdf",
            '_blank' // <- This is what makes it open in a new window.
          )
        }}
         className={`cancel-modal ${props.termsAndCondition&&" button-model seprater"}`}>
          <a href="https://storage.googleapis.com/betterspaces_content/room_tc_61d2ce9bbe04916cf0de4e03.pdf"></a>
           Review
          </button>}
          <button onClick={closeModel} className={`cancel-modal ${props.termsAndCondition&&" button-model seprater red-button"}`}>
          {props.termsAndCondition?"Disagree":  "Cancel"}
          </button>
          <button onClick={props.handelSubmitModel}  className={`confirm-modal ${props.termsAndCondition&&" button-model "}`}>
          {props.termsAndCondition?"Agree":  "Confirm"}
          </button>
        </div>
      </div>
    </div>
  )
}

export default IosModel
