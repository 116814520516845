import React, { Component } from 'react'
import { compose } from 'redux'
import 'react-sweet-progress/lib/style.css'
import $ from 'jquery'
import './room.css'
import * as firebase from 'firebase'
import 'firebase/firestore'
import 'firebase/auth'
import moment from 'moment'
import config from '../../../config/config'
import UserProfile from '../../auth/UserProfile'
import { Backdrop, CircularProgress, withStyles } from '@material-ui/core'
import Modal from 'react-modal'
import AddToCalendar from 'react-add-to-calendar'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-calendar/dist/Calendar.css'

import {
  FirebaseEvent,
  firebaseTrackEvent,
} from '../../../config/FirebaseManager'
import LocationCard from './LocationCard'
import ChooseCalender from '../Events/ChooseCalender'

// Initialize Firebase
firebase.initializeApp(config.configFb)
firebase.firestore().settings({ timestampsInSnapshots: true })
firebase.auth()
firebase.analytics()

let event = {
  title: 'Location Name',
  description: 'Description',
  startTime: '',
  endTime: '',
}

let slot = {
  displayTime: '',
  dateTime: '',
  bookingId: '',
  locationId: '',
  location: '',
  description: '',
  bookingStart: '',
  bookingEnd: '',
}

var timeZone = moment().format('Z')
var startTime = moment().format('T00:00:00.000')
var endTime = moment().format('T23:59:59.000')

class LocationList extends Component {
  constructor(props) {
    super(props)
    firebaseTrackEvent(FirebaseEvent.rooms_screen, {})
  }

  state = {
    locations: [],
    selectedLocation: null,
    selectedBooking: null,
    loading: false,
    selectedSlot: slot,
    calendarEvent: event,
    slotsToBeRendered: [],

    openPostConfirmationPopUp: false,
    openCancelAlert: false,
    openAddToCalendarPopUp: false,
    openBookedDetails: false,
    openLocationDetails: false,
    description: '',
    isLessOn: true,

    changedDate: '',
    selectedDateShow: moment(),
    startDateTime: moment().format('yyyy-MM-DD') + startTime + timeZone,
    endDateTime: moment().format('yyyy-MM-DD') + endTime + timeZone,
  }

  async componentDidMount() {
    if (window.innerWidth >= 655) {
      $(document).ready(function() {
        document.body.classList.add('open-sidebar')

        $('.modal-toggle').on('click', function(e) {
          e.preventDefault()
          $('.modal').toggleClass('is-visible')
        })
      })
    } else {
      $(document).ready(function() {
        document.body.classList.remove('open-sidebar')

        $('.modal-toggle').on('click', function(e) {
          e.preventDefault()
          $('.modal').toggleClass('is-visible')
        })
      })
    }

    this.fetchLocations()
  }

  /*************** Hide/Show Loader ***************/
  hideLoader = () => {
    this.setState({ loading: false })
  }

  showLoader = () => {
    this.setState({ loading: true })
  }
  /** ******************************************* **/

  /*************** APIs Start ***************/
  fetchLocations = () => {
    this.showLoader()

    fetch(config.api_url + '/api/v1/locations/roomlist/', {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('accessToken'),
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify({
        end_date: this.state.endDateTime,
        start_date: this.state.startDateTime,
        endTime: '',
        startTime: '',
      }),
    })
      .then(res => res.json())
      .then(data => {
        console.log('fetchLocations ', data)

        if (data.code === 200) {
          this.setState({
            locations: data.data.result,
            loading: false,
          })
        } else {
          this.setState({
            locations: [],
            loading: false,
          })

          if (data.code === 400) {
            // if (window.confirm(data.msg + "\nDo you want to logout?")) {
            console.log('InvalidToken: ' + localStorage.getItem('accessToken'))
            UserProfile.removeUser()
            this.props.history.push('/signin')
            alert('got it 8')
            // }
          }
        }
      })
      .catch(console.log)
  }

  fetchLocationDetails = location => {
    this.showLoader()
    fetch(config.api_url + '/api/v1/locations/details/' + location._id, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('accessToken'),
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify({
        end_date: this.state.endDateTime,
        start_date: this.state.startDateTime,
        user_id: UserProfile.user_id(),
      }),
    })
      .then(res => res.json())
      .then(data => {
        this.hideLoader()
        console.log('fetchLocationDetails:', data)

        if (data && data.name && data.name !== '') {
          let desc = data.description
            ? data.description.substring(0, 100) + '...'
            : ''
          this.setState({
            selectedLocation: data,
            description: desc,
            loading: false,
            isLessOn: true,
          })
          this.openLocationDetailView()
        } else {
          this.setState({
            selectedLocation: null,
            loading: false,
            description: '',
            isLessOn: true,
          })
          this.closeLocationDetailView()
        }
      })
      .catch(console.log)
  }

  updateLocationDetailsOnDateChange = location => {
    let currentDate = this.state.changedDate.format('yyyy-MM-DD')
    let startDateTime = currentDate + startTime + timeZone
    let endDateTime = currentDate + endTime + timeZone

    this.setState({
      selectedDateShow: this.state.changedDate,
      startDateTime: startDateTime,
      endDateTime: endDateTime,
    })

    this.showLoader()
    fetch(config.api_url + '/api/v1/locations/details/' + location._id, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('accessToken'),
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify({
        end_date: endDateTime,
        start_date: startDateTime,
        user_id: UserProfile.user_id(),
      }),
    })
      .then(res => res.json())
      .then(data => {
        this.hideLoader()
        console.log('updateLocationDetailsOnDateChange:', data)

        if (data && data.name && data.name !== '') {
          this.setState({
            selectedLocation: data,
            loading: false,
          })

          let slots = this.createSlots()
          this.setState({
            slotsToBeRendered: slots,
          })

          this.setState({
            slotsToBeRendered: slots,
          })
        } else {
          this.setState({
            selectedLocation: null,
            slotsToBeRendered: [],
            loading: false,
          })
        }
      })
      .catch(console.log)
  }

  redefineCurrentBookingSlots = (slot, room, index) => {
    let { slotsToBeRendered } = this.state
    let preSelectedSlots = slotsToBeRendered.filter(
      ele => ele.isSelectedForBooking === true,
    )

    if (slot.isSelectedForBooking) {
      // SLOT IS SELECTED, SO SET UNSELECTION LOGIC

      // ALLOW TO UNSELECT ONLY LEFT AND RIGHT SLOTS, NOT MIDDLE SLOTS
      var isPrevSelected = false
      var isNextSelected = false
      if (index < slotsToBeRendered.length - 1) {
        let nextTimeslot = slotsToBeRendered[index + 1]
        if (nextTimeslot.isSelectedForBooking) {
          isNextSelected = true
        }
      }
      if (index > 0) {
        let prevTimeslot = slotsToBeRendered[index - 1]
        if (prevTimeslot.isSelectedForBooking) {
          isPrevSelected = true
        }
      }
      if (!(isPrevSelected && isNextSelected)) {
        var oldSlots = this.state.slotsToBeRendered
        slot.isSelectedForBooking = !slot.isSelectedForBooking
        slot.slotPrebookingClassName = ''
        oldSlots[index] = slot
        this.setState({ slotsToBeRendered: oldSlots })

        this.updateCurrentSelectedSlots()
      }
    } else {
      // SLOT IS UNSELECTED, SO SET SELECTION LOGIC
      var allowToSelect = false
      if (preSelectedSlots.length === 0) {
        // THERE IS NO PREVIOUS SELECTION, SO ALLOW
        allowToSelect = true
      } else {
        // THERE IS PREVIOUS SELECTION, SO ALLOW ONLY PREVIOUS OR NEXT SLOT TO BE GET SELECTED
        if (index < slotsToBeRendered.length - 1) {
          let nextTimeslot = slotsToBeRendered[index + 1]
          if (nextTimeslot.isSelectedForBooking) {
            allowToSelect = true
          }
        }

        if (index > 0) {
          let prevTimeslot = slotsToBeRendered[index - 1]
          if (prevTimeslot.isSelectedForBooking) {
            allowToSelect = true
          }
        }
      }

      if (allowToSelect) {
        oldSlots = this.state.slotsToBeRendered
        slot.isSelectedForBooking = !slot.isSelectedForBooking
        oldSlots[index] = slot
        this.setState({ slotsToBeRendered: oldSlots })

        this.updateCurrentSelectedSlots()
      }
    }
  }

  // bookSlots = (slot, room) => {
  bookSlots = () => {
    let room = this.state.selectedLocation
    if (this.state.selectedLocation.isLocationPayable === true) {
      window.alert('You can only book this room in the app. Please download the app to do so.')
      //this.openPaymentSummaryView();
      return
    }

    let preSelectedSlots = this.state.slotsToBeRendered.filter(
      ele => ele.isSelectedForBooking === true,
    )

    let slotStartDate = ''
    let slotEndDate = ''

    if (preSelectedSlots.length > 0) {
      slotStartDate = moment(preSelectedSlots[0].startBookingDateTime).format()
      slotEndDate = moment(
        preSelectedSlots[preSelectedSlots.length - 1].startBookingDateTime,
      )
        .add(30, 'minutes')
        .format()
    } else {
      return
    }

    // this.setState({ selectedSlot: slot });
    // let slotEndDate = moment(slot.dateTime)
    //   .add(30, "minutes")
    //   .format();

    let component = this
    this.showLoader()
    fetch(config.api_url + '/api/v1/locations/book', {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('accessToken'),
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify({
        author: UserProfile.user_id(),
        user_id: UserProfile.user_id(),
        platform: 'android',
        location_id: room._id,
        // start_date: slot.dateTime,
        start_date: slotStartDate,
        end_date: slotEndDate,
      }), // body data type must match "Content-Type" header
    })
      .then(res => res.json())
      .then(data => {
        console.log('bookSlots: ', data)
        component.hideLoader()

        if (data['code']) {
          if (data.code !== 200) window.alert(data.error)
        } else {
          let roomDetails = {
            room_id: this.state.selectedSlot.locationId,
            room_name: this.state.selectedSlot.location,
          }
          firebaseTrackEvent(FirebaseEvent.unbook_room, roomDetails)
          if (data && data.location && data.location.name !== '') {
            var desc = ''
            if (data.location.description) {
              desc = data.location.description

              desc = desc.substring(0, 100) + '...'
            }

            this.setState({
              selectedLocation: data.location,
              description: desc,
              loading: false,
            })
            this.openPostConfirmationView()
          } else {
            this.setState({
              // selectedLocation: null,
              loading: false,
              description: '',
            })
          }
          // this.closePreBookingDetailView()
        }
      })
      .catch(console.log)
  }
  unBookSlots = () => {
    this.closeCancelAlertView()
    let component = this
    this.showLoader()
    fetch(config.api_url + '/api/v1/locations/unbook', {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('accessToken'),
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify({
        author: UserProfile.user_id(),
        user_id: UserProfile.user_id(),
        platform: 'android',
        location_id: this.state.selectedSlot.locationId,
        start_date: this.state.selectedSlot.startBookingDateTime,
        end_date: this.state.selectedSlot.endBookingDateTime,
      }), // body data type must match "Content-Type" header
    })
      .then(res => res.json())
      .then(data => {
        console.log('unBookSlots: ', data)
        component.hideLoader()

        if (data['code']) {
          if (data.code !== 200) window.alert(data.error)
        } else {
          let roomDetails = {
            room_id: this.state.selectedSlot.locationId,
            room_name: this.state.selectedSlot.location,
          }
          firebaseTrackEvent(FirebaseEvent.unbook_room, roomDetails)

          this.closeBookingDetailView()

          if (data && data.location && data.location.name !== '') {
            var desc = ''
            if (data.location.description) {
              desc = data.location.description

              desc = desc.substring(0, 100) + '...'
            }

            this.setState({
              selectedLocation: data.location,
              description: desc,
              loading: false,
            })
          } else {
            this.setState({
              selectedLocation: null,
              loading: false,
              description: '',
            })
          }
        }
      })
      .catch(console.log)
  }

  /*************** APIs End ***************/

  /*************** Click Events Start ***************/
  locationCardClick = location => {
    this.fetchLocationDetails(location)
  }
  /*************** Click Events End ***************/

  /*************** Modal State Start ***************/
  openLocationDetailView = () => {
    let slots = this.createSlots()
    this.setState({
      openLocationDetails: true,
      slotsToBeRendered: slots,
      changedDate: this.state.selectedDateShow,
    })

    this.setState({
      slotsToBeRendered: this.state.slotsToBeRendered,
    })
  }
  closeLocationDetailView = () => {
    let selectedDateShow = moment()
    let startDateTime = moment().format('yyyy-MM-DD') + startTime + timeZone
    let endDateTime = moment().format('yyyy-MM-DD') + endTime + timeZone
    this.setState({
      selectedDateShow: selectedDateShow,
      startDateTime: startDateTime,
      endDateTime: endDateTime,
    })

    this.setState({ openLocationDetails: false })
  }

  /***************************/
  /***************************/
  openBookingDetailView = (slot, location) => {
    event.title = location.name
    event.location = location.name
    event.description = location.description
    event.startTime = slot.startBookingDateTime //slot.bookingStart;
    event.endTime = slot.endBookingDateTime //slot.bookingEnd;

    this.setState({
      openBookedDetails: true,
      openLocationDetails: false,
      selectedSlot: slot,
      selectedLocation: location,
      calendarEvent: event,
    })
  }

  closeBookingDetailView = () => {
    this.setState({ openBookedDetails: false, openLocationDetails: true })
  }

  /***************************/
  openPostConfirmationView = () => {
    let slots = this.createSlots()
    this.setState({ slotsToBeRendered: slots })
    this.setState({
      openPostConfirmationPopUp: true,
      openLocationDetails: false,
    })
  }

  closePostConfirmationView = () => {
    this.setState({
      openPostConfirmationPopUp: false,
      openLocationDetails: true,
    })
  }
  /***************************/

  /***************************/
  leftArrowClick = () => {
    let day = this.state.changedDate.subtract(1, 'days')
    this.setState({ changedDate: day, selectedDateShow: day })

    this.updateLocationDetailsOnDateChange(this.state.selectedLocation)
  }

  rightArrowClick = () => {
    let day = this.state.changedDate.add(1, 'days')
    this.setState({ changedDate: day, selectedDateShow: day })

    this.updateLocationDetailsOnDateChange(this.state.selectedLocation)
  }
  /***************************/
  openAddToCalendarView = () => {
    this.setState({ openAddToCalendarPopUp: true, openBookedDetails: false })
  }

  closeAddToCalendarView = () => {
    this.setState({ openAddToCalendarPopUp: false, openBookedDetails: true })
  }

  /***************************/
  openCancelAlertView = () => {
    this.setState({ openCancelAlert: true, openBookedDetails: false })
  }

  closeCancelAlertView = () => {
    this.setState({ openCancelAlert: false, openBookedDetails: true })
  }

  /***************************/
  toggleDesc = () => {
    if (this.state.isLessOn) {
      this.setState({
        description: this.state.selectedLocation.description,
        isLessOn: false,
      })
    } else {
      this.setState({
        description:
          this.state.selectedLocation.description.substring(0, 100) + '...',
        isLessOn: true,
      })
    }
  }

  /***************************/
  createSlots = () => {
    let slotsToBeRendered = []

    const { selectedLocation, selectedDateShow } = this.state

    selectedLocation.availableTimes.map(availableTime => {
      if (availableTime.weekday === selectedDateShow.weekday()) {
        // EARLIER BELOW CONDITION WAS THERE
        let startTime = moment(availableTime.startDate).set({
          year: selectedDateShow.year(),
          month: selectedDateShow.month(),
          date: selectedDateShow.date(),
        })
        let endTime = moment(availableTime.endDate).set({
          year: selectedDateShow.year(),
          month: selectedDateShow.month(),
          date: selectedDateShow.date(),
        })

        if (startTime.isAfter(endTime)) endTime.add(1, 'day')

        while (startTime.isBefore(endTime)) {
          let slot = {
            displayTime: startTime.format('hh:mm'),
            dateTime: startTime.format(),
            startBookingDateTime: startTime.format(),
            endBookingDateTime: '',
            location: selectedLocation.name,
            locationId: selectedLocation._id,
            description: selectedLocation.description,
            bookingId: '',
            bookingStart: '',
            bookingEnd: '',
            booking: null,
            toBeEdited: true,
            isSelectedForBooking: false,
          }
          slotsToBeRendered.push(slot)
          startTime.add(30, 'minutes')
        }
      }
    })

    // slotsToBeRendered.map((slot) => {
    //   slotsToBeRendered.push(slot);
    // });

    // return slotsToBeRendered.filter(
    //   (_, i) => i > slotsToBeRendered.length / 2
    // );

    return slotsToBeRendered
  }

  /***************************/
  updateCurrentSelectedSlots = () => {
    // START "selected-date-start-prebooking time-btn"
    // END "selected-date-end-prebooking time-btn"
    // SINGLE "selected-date-single-prebooking time-btn"
    // MIDDLE "selected-date-middle-prebooking time-btn"

    const { slotsToBeRendered } = this.state
    for (const [index, timeslot] of slotsToBeRendered.entries()) {
      var newTimeSlot = timeslot
      let status = timeslot.isSelectedForBooking
      if (status) {
        newTimeSlot.slotPrebookingClassName =
          'selected-date-single-prebooking time-btn' // FOR BOOKED CLASS SLOT THAT IS FIRST
        var isLeftLinked = false
        var isRightLinked = false
        if (index < slotsToBeRendered.length - 1) {
          let nextSlot = slotsToBeRendered[index + 1]
          if (nextSlot && nextSlot.isSelectedForBooking) {
            isRightLinked = true
            newTimeSlot.slotPrebookingClassName =
              'selected-date-start-prebooking time-btn' // FOR BOOKED CLASS SLOT THAT IS FIRST
          }
        }
        if (index > 0) {
          let prevSlot = slotsToBeRendered[index - 1]
          if (prevSlot) {
            if (prevSlot.isSelectedForBooking) {
              isLeftLinked = true
              newTimeSlot.slotPrebookingClassName =
                'selected-date-end-prebooking time-btn' // FOR BOOKED CLASS SLOT THAT IS LAST
            } else {
            }
          }
        }
        if (isLeftLinked && isRightLinked) {
          newTimeSlot.slotPrebookingClassName =
            'selected-date-middle-prebooking time-btn' // FOR BOOKED CLASS FALLING IN MIDDLE
        } else if (isLeftLinked && !isRightLinked) {
          newTimeSlot.slotPrebookingClassName =
            'selected-date-end-prebooking time-btn' // FOR BOOKED CLASS SLOT THAT IS LAST
        } else if (!isLeftLinked && isRightLinked) {
          newTimeSlot.slotPrebookingClassName =
            'selected-date-start-prebooking time-btn' // FOR BOOKED CLASS SLOT THAT IS FIRST
        }
      }
    }
  }

  /***************************/
  getBookedClassName = (isSlotBookedByMe, index, bookedSlots, slot) => {
    var bookedSlotClassName = 'selected-date-single time-btn' // FOR SINGLE BOOKED CLASS SLOT

    if (isSlotBookedByMe) {
      var isLeftLinked = false
      var isRightLinked = false
      if (index < bookedSlots.length - 1) {
        let nextSlot = bookedSlots[index + 1]
        if (
          nextSlot &&
          nextSlot.bookingId &&
          nextSlot.bookingId === slot.booking._id
        ) {
          isRightLinked = true
          bookedSlotClassName = 'selected-date-start time-btn' // FOR BOOKED CLASS SLOT THAT IS FIRST
        }
      }
      if (index > 0) {
        let prevSlot = bookedSlots[index - 1]
        if (prevSlot.booking) {
          if (prevSlot.booking._id === slot.booking._id) {
            isLeftLinked = true
            bookedSlotClassName = 'selected-date-end time-btn' // FOR BOOKED CLASS SLOT THAT IS LAST
          } else {
          }
        }
      }
      if (isLeftLinked && isRightLinked) {
        bookedSlotClassName = 'selected-date-middle time-btn' // FOR BOOKED CLASS FALLING IN MIDDLE
      } else if (isLeftLinked && !isRightLinked) {
        bookedSlotClassName = 'selected-date-end time-btn' // FOR BOOKED CLASS SLOT THAT IS LAST
      } else if (!isLeftLinked && isRightLinked) {
        bookedSlotClassName = 'selected-date-start time-btn' // FOR BOOKED CLASS SLOT THAT IS FIRST
      }
    }

    return bookedSlotClassName
  }
  /*************** Modal State End ***************/

  render() {
    const {
      openPostConfirmationPopUp,

      openCancelAlert,
      openAddToCalendarPopUp,
      openBookedDetails,
      openLocationDetails,
      selectedLocation,
      selectedSlot,
      description,
      isLessOn,
      changedDate,
      selectedDateShow,
      slotsToBeRendered,
    } = this.state

    return (
      <div>
        <Backdrop
          className={this.props.classes.backdrop}
          open={this.state.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        {/************************************************************************************************/}
        {/* MODAL POST CONFIRMATION START *******************************************************************/}
        {openPostConfirmationPopUp ? (
          <Modal
            isOpen={openPostConfirmationPopUp}
            // onRequestClose={() => this.closePostConfirmationView()}
            contentLabel="Example Alert"
            className="reservation-alert-modal post-confirmation-modal"
          >
            <div className="post-confirmation">
              <div className="imgbox">
                <img src="assets/img/icon_confirm_tick.svg" alt="Close" />
              </div>
              <h2>Confirmed!</h2>
              <button
                className="modal-close"
                onClick={() => this.closePostConfirmationView()}
              >
                <img src="assets/img/close-white-new.svg" alt="Close" />
              </button>
            </div>
          </Modal>
        ) : (
          ''
        )}
        {/* MODAL POST CONFIRMATINO END *********************************************************************/}

        {/* MODAL LOCTION DETAIL START *******************************************************************/}
        {openLocationDetails ? (
          <Modal
            isOpen={openLocationDetails}
            // onRequestClose={() => this.closeLocationDetailView()}
            contentLabel="Example Alert"
            className="reservation-alert-modal booking-details"
          >
            <div className="location_box_row">
              {/* //<div className="col-12 col-md-6 col-lg-4 col-xl-3 location_box_row"> */}
              <div className="box-bg rooms-card">
                <div className="box_list_item card-wrap">
                  <img
                    className="thumb-img"
                    src={
                      selectedLocation.listImage || 'assets/img/user-img.png'
                    }
                    alt=""
                  />
                  <button
                    className="modal-close"
                    onClick={() => this.closeLocationDetailView()}
                  >
                    <img
                      src="../../assets/img/close-white-new.svg"
                      alt="Close"
                    />
                  </button>
                  <div className="date-time-box">
                    <div className="d-flex-box">
                      <span>
                        {selectedLocation.costPoint > 1
                          ? selectedLocation.costPoint + ' points' || ''
                          : selectedLocation.costPoint + ' point' || ''}
                      </span>
                    </div>
                    <div className="d-flex-box main-title">
                      <h3>{selectedLocation.name || 'NA'}</h3>
                    </div>
                  </div>
                </div>

                <div className="box_description">
                  {selectedLocation.description ? (
                    <div className="col-desc br-btm">
                      <p>{description}</p>
                      <p
                        className="more_less-btn"
                        onClick={() => this.toggleDesc()}
                      >
                        {isLessOn ? 'more' : 'less'}
                      </p>
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="calenday-wrap">
                    <div className="date-time-box">
                      {/* <div className="d-flex-box-date-picker"></div> */}
                      <div className="event-date">
                        <button
                          className="modal-arrow"
                          onClick={() => this.leftArrowClick()}
                        >
                          <img
                            src="assets/img/left_arrow_blue.svg"
                            alt="Close"
                          />
                        </button>
                        <big>
                          {changedDate.format('dddd') +
                            ', ' +
                            changedDate.format('MMM DD')}
                        </big>
                        <button
                          className="modal-arrow"
                          onClick={() => this.rightArrowClick()}
                        >
                          <img
                            src="assets/img/right_arrow_blue.svg"
                            alt="Close"
                          />
                        </button>
                      </div>
                    </div>
                    <div className="d-flex-box br-btm event-instructor">
                      <div className="event-atten">
                        {slotsToBeRendered.map((slot, index) => {
                          let isSlotBooked = false
                          let isSlotBookedByMe = false
                          let slotStartTime = moment(slot.dateTime)
                          let slotEndTime = moment(slot.dateTime).add(
                            30,
                            'minutes',
                          )
                          //---------------------------------------
                          selectedLocation.bookings
                            ? selectedLocation.bookings.map(
                                (booking, _bookingIndex) => {
                                  let bookingStartTime = moment(
                                    booking.startDate,
                                  )
                                  let bookingEndTime = moment(booking.endDate)

                                  // check whether slot is booked - Need to add condition for continous slotsToBeRendered
                                  if (
                                    !(
                                      slotStartTime.isBefore(
                                        bookingStartTime,
                                      ) || slotEndTime.isAfter(bookingEndTime)
                                    ) &&
                                    !isSlotBooked
                                  ) {
                                    isSlotBooked = true
                                    slot.bookingId = booking._id
                                    slot.bookingStart = bookingStartTime.format(
                                      'hh:mm',
                                    )
                                    slot.startBookingDateTime = bookingStartTime.format()
                                    slot.bookingEnd = bookingEndTime.format(
                                      'hh:mm',
                                    )
                                    slot.endBookingDateTime = bookingEndTime.format()
                                    if (
                                      booking.user._id === UserProfile.user_id()
                                    ) {
                                      isSlotBookedByMe = true
                                    }
                                    slot.booking = booking
                                  }
                                },
                              )
                            : selectedLocation.availableTimes.map(booking => {
                                let bookingStartTime = moment(booking.startDate)
                                let bookingEndTime = moment(booking.endDate)

                                // check whether slot is booked - Need to add condition for continous slotsToBeRendered
                                if (
                                  !(
                                    slotStartTime.isBefore(bookingStartTime) ||
                                    slotEndTime.isAfter(bookingEndTime)
                                  ) &&
                                  !isSlotBooked
                                ) {
                                  isSlotBooked = true
                                  slot.bookingId = booking._id
                                  slot.bookingStart = bookingStartTime.format(
                                    'hh:mm',
                                  )
                                  slot.bookingEnd = bookingEndTime.format(
                                    'hh:mm',
                                  )
                                  if (
                                    booking.user._id === UserProfile.user_id()
                                  ) {
                                    isSlotBookedByMe = true
                                  }
                                }
                              })

                          //---------------------------------------
                          // if (index < slotsToBeRendered.length / 2) {
                          //   return "";
                          // }
                          //---------------------------------------
                          var bookedSlotClassName = this.getBookedClassName(
                            isSlotBookedByMe,
                            index,
                            slotsToBeRendered,
                            slot,
                          )
                          //---------------------------------------
                          console.log(slot.isSelectedForBooking)
                          var availableSlotclassName = 'slot-available time-btn'
                          var availableSlotDisabled = false
                          if (
                            slotStartTime.isBefore(
                              moment().format('YYYY-MM-DDTHH:mm:ssZ'),
                            )
                          ) {
                            availableSlotDisabled = true
                            availableSlotclassName = 'slot-unavailable time-btn'
                          } else {
                            if (slot.isSelectedForBooking) {
                              availableSlotclassName =
                                slot.slotPrebookingClassName
                            }
                          }

                          return isSlotBooked ? (
                            isSlotBookedByMe ? (
                              <button
                                type="submit"
                                className={bookedSlotClassName}
                                onClick={() =>
                                  this.openBookingDetailView(
                                    slot,
                                    selectedLocation,
                                  )
                                }
                              >
                                {slot.displayTime.toString()}
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="slot-unavailable time-btn"
                                disabled={true}
                              >
                                {slot.displayTime.toString()}
                              </button>
                            )
                          ) : (
                            <button
                              type="submit"
                              className={availableSlotclassName}
                              disabled={availableSlotDisabled}
                              onClick={() =>
                                this.redefineCurrentBookingSlots(
                                  slot,
                                  this.state.selectedLocation,
                                  index,
                                )
                              }
                            >
                              {slot.displayTime.toString()}
                            </button>
                          )
                        })}
                      </div>
                    </div>
                    {slotsToBeRendered.filter(
                      ele => ele.isSelectedForBooking === true,
                    ).length !== 0 ? (
                      <div className="btn-wrap">
                        <button
                          type="button"
                          className="btn btn-primary book-btn"
                          onClick={() => this.bookSlots()}
                        >
                          BOOK
                        </button>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        ) : (
          ''
        )}
        {/* MODAL LOCTION DETAIL END *********************************************************************/}

        {/* MODAL BOOKED SLOT START **********************************************************************/}
        {openBookedDetails ? (
          <Modal
            isOpen={openBookedDetails}
            // onRequestClose={() => this.closeBookingDetailView()}
            contentLabel="Example Alert"
            className="reservation-alert-modal booking-details"
          >
            <div className="location_box_row">
              <div className="box-bg rooms-card">
                <div className="box_list_item card-wrap">
                  <img
                    className="thumb-img"
                    src={
                      selectedLocation.listImage || 'assets/img/user-img.png'
                    }
                    alt=""
                    style={{
                      height: 260,
                    }}
                  />
                  <button
                    className="modal-close"
                    onClick={() => this.closeBookingDetailView()}
                  >
                    <img src="assets/img/close-white-new.svg" alt="Close" />
                  </button>
                </div>
                <div className="box_description">
                  <div className="room-attending-info">
                    <div className="info">
                      <div className="row">
                        <div className="col-4">
                          {/* <label>Room</label> */}
                          <h6>{this.state.selectedLocation.name}</h6>
                        </div>
                      </div>
                    </div>
                    <div className="info">
                      <div className="date-info">
                        <div className="date-wrap">
                          <label>Date</label>
                          <h7>
                            {selectedDateShow.format('ddd') +
                              ', ' +
                              selectedDateShow.format('MMM DD')}
                          </h7>
                        </div>
                        <div className="date-wrap">
                          <label>Time</label>
                          <h7>
                            {selectedSlot.bookingStart} -{' '}
                            {selectedSlot.bookingEnd}
                          </h7>
                        </div>
                        <div className="attending-lable">
                          <label>Attending</label>
                          <h7>
                            {selectedSlot &&
                            selectedSlot.booking &&
                            selectedSlot.booking.invitees
                              ? selectedSlot.booking.invitees.length
                              : 0}
                          </h7>
                        </div>
                      </div>
                    </div>
                    <div className="btn-wrap">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => this.openAddToCalendarView()}
                      >
                        {/* <img src="assets/img/plus-white.svg" alt="Close" /> */}
                        Add to calendar
                      </button>
                      <button
                        type="button"
                        className="btn btn-theme"
                        onClick={() => this.openCancelAlertView()}
                      >
                        Cancel Reservation
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        ) : (
          ''
        )}
        {/* MODAL BOOKED SLOT END ************************************************************************/}
        {/* ****************************** */}

        {/* MODAL ADD TO CALENDAR START */}
        {openAddToCalendarPopUp ? (
          <ChooseCalender
            handleCloseModel={() => this.closeAddToCalendarView()}
            // handelSubmitModel={this.handleCalenderSubmit}
            eventData={event}
          />
        ) : (
          // <Modal
          //   isOpen={openAddToCalendarPopUp}
          //   onRequestClose={() => this.closeAddToCalendarView()}
          //   contentLabel="Example Alert"
          //   className="reservation-alert-modal add-to-calendar-modal"
          // >
          //   <button
          //     className="modal-close"
          //     onClick={() => this.closeAddToCalendarView()}
          //   >
          //     <img src="assets/img/close-blue.svg" alt="Close" />
          //   </button>
          //   <h2>Add to Calendar</h2>
          //   <div className="btnbox">
          //     <AddToCalendar
          //       event={event}
          //       listItems={items}
          //       buttonTemplate={icon}
          //       buttonLabel={"CHOOSE CALENDAR"}
          //       dropdownClass="drpdown"
          //       buttonClassOpen="drpdown_open"
          //       buttonClassClosed="drpdown_closed"
          //       optionsOpen={true}
          //     />
          //   </div>
          // </Modal>
          ''
        )}
        {/* MODAL ADD TO CALENDAR END *********************************************************************/}
        {/* ****************************** */}

        {/* MODAL CANCEL ALERT VIEW START *****************************************************************/}
        {openCancelAlert ? (
          <Modal
            isOpen={openCancelAlert}
            // onRequestClose={() => this.closeCancelAlertView()}
            contentLabel="Cancel Alert"
            className="reservation-alert-modal"
          >
            <div className="modal-body">
              <h4>Cancel Reservation</h4>
              <p>Are you sure you want to cancel your Reservation?</p>
            </div>
            <div className="modal-footer">
              <div className="modal-btnbox">
                <button
                  type="button"
                  className="btn"
                  onClick={() => this.closeCancelAlertView()}
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn"
                  onClick={() => this.unBookSlots()}
                >
                  Yes
                </button>
              </div>
            </div>
          </Modal>
        ) : (
          ''
        )}
        {/* MODAL CANCEL ALERT VIEW END *******************************************************************/}

        {/* ****************************** */}
        {/* ****************************** */}
        <div className="main-wrap location-layout-wrapper">
          <div className="breadcrumb-row" />
          <div className="row event-title-box views_row">
            {this.state.locations &&
              this.state.locations.map((location, index) => {
                let slotsToBe = []
                return (
                  <LocationCard
                    index={index}
                    location={location}
                    selectedDateShow={selectedDateShow}
                    slotsToBeRendered={slotsToBe}
                    // bookSlot={this.bookSlot}
                    UserProfile={UserProfile}
                    locationCardClick={this.locationCardClick}
                  />
                )
              })}
          </div>{' '}
        </div>
        {/* ****************************** */}
        {/* ****************************** */}
      </div>
    )
  }
}

const enhance = compose(withStyles({ withTheme: true }))
export default enhance(LocationList)
