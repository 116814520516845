var UserProfile = (function() {
  // var full_name = "";

  const KEY = {
    ROLE: 'role',
    EMAIL: 'email',
    USER_ID: 'user_id',
    USER_NAME: 'user_name',
    COMPANY_NAME: 'company_name',
    BUILDING_NAME: 'building_name',
    BUILDING_ID: 'building_id',
    IMAGE: 'image',
    STRIPE_CUST_ID: 'stripeCustomerId',
    STRIPE_PAYMENT_METHOD: 'stripePaymentMethod',
  }

  var role = function() {
    return localStorage.getItem(KEY.ROLE)
  }
  var email = function() {
    return localStorage.getItem(KEY.EMAIL)
  }
  var user_id = function() {
    return localStorage.getItem(KEY.USER_ID) // Or pull this from cookie/localStorage
  }
  var user_name = function() {
    return localStorage.getItem(KEY.USER_NAME)
  }
  var company_name = function() {
    return localStorage.getItem(KEY.COMPANY_NAME)
  }
  var building_name = function() {
    return localStorage.getItem(KEY.BUILDING_NAME)
  }
  var building_id = function() {
    return localStorage.getItem(KEY.BUILDING_ID)
  }
  var image = function() {
    return localStorage.getItem(KEY.IMAGE)
  }
  var stripeCustomerId = function() {
    return localStorage.getItem(KEY.STRIPE_CUST_ID)
  }
  var stripePaymentMethod = function() {
    return localStorage.getItem(KEY.STRIPE_PAYMENT_METHOD)
  }

  // var setName = function(name) {

  //   full_name = name;
  //   // Also set this in cookie/localStorage
  // };

  var setUser = function(userData) {
    console.log(userData)

    //role
    localStorage.setItem(KEY.ROLE, userData.role)

    //email
    localStorage.setItem(KEY.EMAIL, userData.email)

    //user_id
    localStorage.setItem(KEY.USER_ID, userData._id)

    //role
    if (userData.fullName !== null || userData.fullName !== '') {
      localStorage.setItem(KEY.USER_NAME, userData.fullName)
    } else {
      let name = userData.firstName + userData.lastName
      localStorage.setItem(KEY.USER_NAME, name)
    }

    //company_name
    localStorage.setItem(KEY.COMPANY_NAME, userData.company)

    //building_name
    localStorage.setItem(KEY.BUILDING_NAME, userData.activeBuilding.name)

    //building_id
    localStorage.setItem(KEY.BUILDING_ID, userData.activeBuilding._id)

    //image
    localStorage.setItem(KEY.IMAGE, userData.image)

    //stripeCustomerId
    localStorage.setItem(KEY.STRIPE_CUST_ID, userData.stripeCustomerId)

    //stripePaymentMethod
    localStorage.setItem(
      KEY.STRIPE_PAYMENT_METHOD,
      userData.stripePaymentMethod,
    )
  }

  var removeUser = function() {
    localStorage.removeItem(KEY.ROLE)
    localStorage.removeItem(KEY.EMAIL)
    localStorage.removeItem(KEY.USER_ID)
    localStorage.removeItem(KEY.USER_NAME)
    localStorage.removeItem(KEY.COMPANY_NAME)
    localStorage.removeItem(KEY.BUILDING_NAME)
    localStorage.removeItem(KEY.BUILDING_ID)
    localStorage.removeItem(KEY.IMAGE)
    localStorage.removeItem(KEY.STRIPE_CUST_ID)
    localStorage.removeItem(KEY.STRIPE_PAYMENT_METHOD)

    localStorage.removeItem('emailForSignIn')
    localStorage.removeItem('accessToken')
    localStorage.removeItem('preLoginToken')
    localStorage.removeItem('role')
    localStorage.removeItem('email')
    localStorage.removeItem('selected_segment')
  }

  return {
    //METHODS
    // getName: getName,
    // setName: setName
    setUser,
    removeUser,

    //VARIABLES
    role,
    email,
    user_id,
    user_name,
    company_name,
    building_name,
    building_id,
    image,
    stripeCustomerId,
    stripePaymentMethod,
  }
})()

export default UserProfile
