import firebase from 'firebase/app'
import UserProfile from '../components/auth/UserProfile'

var FirebaseManager = {}

// FirebaseEvents : String
export const FirebaseEvent = {
  content_viewopened: 'content_viewopened',
  media_open: 'media_open',
  media_closed: 'media_closed',
  events_screen: 'events_screen',
  rooms_screen: 'rooms_screen',
  rsvp_event: 'rsvp_event',
  event_attendees: 'event_attendees',
  cancel_rsvp_event: 'cancel_rsvp_event',
  book_room: 'book_room',
  unbook_room: 'unbook_room',
}

// FirebaseKeys : String
export const FirebaseKey = {
  // EXPLORE/CONTENT
  content_name: 'content_name',
  content_id: 'content_id',
  content_category: 'content_category',
}

export function firebaseTrackEvent(eventName, eventDetails) {
  // console.log("user_name:", UserProfile.getName())
  eventDetails.app_type = 'betterspaces_web'
  eventDetails.email = UserProfile.email()
  eventDetails.user_id = UserProfile.user_id()
  eventDetails.user_name = UserProfile.user_name()
  eventDetails.company_name = UserProfile.company_name()
  eventDetails.building_name = UserProfile.building_name()
  eventDetails.building_id = UserProfile.building_id()
  eventDetails.date_and_time = Date()

  console.log('eventName:', eventName)
  console.log('eventDetails:', eventDetails)

  firebase.analytics().logEvent(eventName, { eventDetails })
  // firebase.analytics().logEvent("event_name", {"key_1": "value_1", "key_2": "value_2"});
}

// FirebaseManager.handleChangeBuildings = (selectedOptionBuildings) => {
// }

export default FirebaseManager
