import config from '../config/config'

export default async locationId => {
  let res = await fetch(
    `${config.api_url}/api/v1/locations/details/${locationId}`,
    {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: localStorage.getItem('accessToken'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        start_date: '2021-10-13T00:00:00.000-0400',
        end_date: '2021-10-13T23:59:59.999-0400',
      }),
    },
  )
  let [settledRequest] = await Promise.allSettled([res.json()])
  return settledRequest
}
