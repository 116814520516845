import React, { Component } from 'react'
// import AddToCalendar from "react-add-to-calendar";
// import { Dropdown, DropdownButton } from "react-bootstrap";
// import ConfirmeCard from "./ConformeCard";
// import ChooseCalender from "./ChooseCalender";
// import IosModel from "./IosModel";
// import $ from "jquery";
// import moment from "moment";
// import ReactMomentCountDown from "react-moment-countdown";
// import { CountDowm } from "../assets/js/healper";
// import Countdown from "react-countdown";
// import SimpleCheckIcon from "../../../assets/SimpleCheck";

export const Completionist = () => <span>Live</span>
export default class LocationCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      confirmEventModel: false,
      openconfirmModel: false,
      openCalenderModel: false,
      collapsible: false,
    }
  }

  componentDidMount = () => {
    // const countdown = CountDowm(this.props.post.dateWithDuration);
    // console.log(
    //   "🚀 ~ file: EventCard.js ~ line 23 ~ EventCard ~ countdown",
    //   CountDowm(this.props.post.dateWithDuration)
    // );
  }

  onCloseRsvpTime = () => {
    this.setState({
      confirmEventModel: false,
    })
  }

  // handleOpenConfirmRsvp = () => {

  // };

  handelConformation = evt => {
    this.setState({
      openconfirmModel: true,
    })
    // this.setState({
    //   confirmEventModel: false,
    //   openconfirmModel: true,
    // });
    this.props.handleSubmitEvent()
    this.props.onSelectRsvpTime(evt, this.props.post, this.props.event)
  }

  handleConfirmClose = () => {
    this.setState({
      openconfirmModel: false,
      openCalenderModel: false,
    })
  }

  handleCalenderClose = () => {
    this.setState({
      openCalenderModel: false,
    })
  }

  handleCalenderSubmit = () => {
    // this.setState({
    //   openCalenderModel: false,
    // });
  }

  handleConfirmSubmit = () => {
    this.setState({
      // openconfirmModel: false,
      openCalenderModel: true,
    })
  }

  // Renderer callback with condition
  renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      return <Completionist />
    } else {
      // Render a countdown
      return (
        <div>
          {days > 0 ? (
            <span>
              Start in {days} days, {hours} hrs, {minutes} mins
            </span>
          ) : (
            <span>
              Start in {hours} hrs, {minutes} mins, {seconds} sec
            </span>
          )}
        </div>
      )
    }
  }

  handleCollaps = () => {
    this.setState({
      collapsible: !this.state.collapsible,
    })
  }

  render() {
    const { index, location, locationCardClick } = this.props

    return (
      <div className="location_box_row" key={index}>
        {/* <div className="col-12 col-md-6 col-lg-4 col-xl-3 location_box_row" key={index}> */}
        <div className="box-bg rooms-card">
          <div className="box_list_item card-wrap" onClick={this.handleCollaps}>
            <img
              className="thumb-img"
              src={location.listImage || 'assets/img/user-img.png'}
              alt=""
              onClick={() => locationCardClick(location)}
            />
            <div className="date-time-box">
              <div className="d-flex-box">
                <span>
                  {location.costPoint > 1
                    ? location.costPoint + ' points' || ''
                    : location.costPoint + ' point' || ''}
                </span>
              </div>
              <div className="d-flex-box main-title">
                {this.state.collapsible ? (
                  <h3>{location.name || 'NA'}</h3>
                ) : (
                  <h4>{location.name || 'NA'}</h4>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
