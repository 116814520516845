const initState = {
  amenitiesList: [],
  amenitiesListLoading: true, // ! to do to add logic for infinite loading bug.
  amenitiesDetails: [],
}

const amenitiesReducer = (state = initState, action) => {
  switch (action.type) {
    case 'amenities/list':
      // alert('amenities/list')
      return {
        ...state,
        amenitiesList: action.payload,
        amenitiesListLoading: false,
      }

    case 'amenities/amenityDetails':
      // alert('amenities/amenityDetails')
      return {
        ...state,
        amenitiesDetails: [...state.amenitiesDetails, action.payload],
      }
    default:
      return state
  }
}

export default amenitiesReducer
