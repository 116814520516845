import React, { useEffect } from 'react'
import { makeStyles, Button } from '@material-ui/core'
import Modal from '@material-ui/core/Modal'
import './event.css'
import { getDate, getTime } from '../../../common/common.js'

function rand() {
  return Math.round(Math.random() * 20) - 10
}

function getModalStyle() {
  const top = 50 + rand()
  const left = 50 + rand()

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    borderRadius: '25px',
    border: '2px solid #000',
    overflow: 'hidden',
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },
}))

export default function EventModal(props) {
  const classes = useStyles()
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle)
  const [open, setOpen] = React.useState(false)

  useEffect(() => {
    console.log(props)
    setOpen(props.flag)
  }, [props])

  const { name, image, startDate, duration } = props.eventData

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <div className="img-container">
        <img src={image} />
        <span className="cross-icon" onClick={handleClose}>
          X
        </span>
      </div>

      <div className="event-info">
        <h4>{name}</h4>
        <div className="event">
          <div className="event-day">
            <label>Date</label>
            <h4> {getDate(startDate)}</h4>
          </div>
          <div className="event-time">
            <label>Time</label>
            <h4> {props.session && props.session.split(',')[0]}</h4>
          </div>
          <div className="event-duration">
            <label>Duration</label>
            <h4> {duration}</h4>
          </div>
        </div>

        <div className="event-btn">
          <Button
            variant="contained"
            className="btn btn-primary"
            onClick={() => props.rsvp(props.session, props.eventData)}
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  )

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
    </>
  )
}
