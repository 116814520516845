import React, { Component } from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import IosModel from './IosModel'
import { CountDowm } from '../assets/js/healper'
import Countdown from 'react-countdown'
import SimpleCheckIcon from '../../../assets/SimpleCheck'
import { animateScroll as scroll } from 'react-scroll'
import betterDescription from '../../amenities/betterDescription'

export const Completionist = ({ videoUrl }) => (
  <span onClick={() => window.open(videoUrl)}>Live</span>
)
export default class EventCard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      confirmEventModel: false,
      confirmEventModelMulti: false,
      openconfirmModel: false,
      openCalenderModel: false,
      collapsible: false,
      isLessOn: this.props.isLessOn,
      openCancelAlert: false,
      closeCancelAlert: false,
      confirmJoinNow: false,
      remainingTime: false,
      evtData: '',
      islive: false,
    }
  }


  // componentDidMount = () => {
  //   // const countdown = CountDowm(this.props.post.dateWithDuration);
  //   // console.log(
  //   //   "🚀 ~ file: EventCard.js ~ line 23 ~ EventCard ~ countdown",
  //   //   CountDowm(this.props.post.dateWithDuration)
  //   // );
  // };

  // componentWillUpdate = (prevProps, prevState) => {
  //   // $("#cancel").on("click", function() {
  //   //   $("html, body").animate({
  //   //     scrollTop: $("#confirmPopUp").position(),
  //   //   });
  //   // });
  //   // $("#cancel").on("click", function() {
  //   //   $("html, body").animate({
  //   //     scrollTop: $("#confirmPopUp").position(),
  //   //   });
  //   // });
  // };

  componentDidUpdate(preProps,preState){
    let user = this.props.user
    if (user && user.isProfileDetailsLoaded && user.activeBuilding && preProps.user && preProps.user.isProfileDetailsLoaded && preProps.user.activeBuilding){
      if((user.activeBuilding._id!==preProps.user.activeBuilding._id)){
        this.setState({ collapsible:false  });
      }
    }
    if(preProps.upcomingEvents.length!==this.props.upcomingEvents.length){
      this.setState({ collapsible:false  });
    }
  }

  onCloseRsvpTime = () => {
    this.setState({
      confirmEventModel: false,
    })
  }

  onCloseRsvpTimeMulti = () => {
    this.setState({
      confirmEventModelMulti: false,
      evtData: '',
    })
  }

  handleOpenConfirmRsvp = () => {
    this.setState({
      confirmEventModel: true,
    })
  }

  onCloseJoinNow = () => {
    this.setState({
      confirmJoinNow: false,
    })
  }

  handleOpenJoinNow = () => {
    this.setState({
      confirmJoinNow: true,
    })
  }

  openCancelAlertView = (date, duration, e) => {
    var testDiv = document.getElementById(this.props.post._id)
    console.log(testDiv.offsetTop)
    !this.props.myBookingeventData && scroll.scrollTo(testDiv.offsetTop - 130)
    let dt = new Date(date)
    dt.setMinutes(dt.getMinutes() - duration)
    if (this.state.islive) {
      window.location.href = this.props.post.videoUrl
      this.setState({ openCancelAlert: false })
    } else {
      this.setState({ openCancelAlert: true })
    }
  }

  closeCancelAlertView = () => {
    this.setState({ openCancelAlert: false })
  }

  cancelRsvp = async post => {
    await this.props.cancelrsvp(post)
    this.closeCancelAlertView()
  }

  handleOpenConfirmRsvpMultipleSlot = evt => {
    this.setState({
      confirmEventModelMulti: true,
      evtData: evt,
    })
  }

  handelConformation = async evt => {
    this.setState({
      openconfirmModel: true,
    })
    this.props.handleSubmitEvent()
    await this.props.onSelectRsvpTime(evt, this.props.post, this.props.event)
    await this.onCloseRsvpTime()
    await this.onCloseRsvpTimeMulti()
  }

  handleSingleSlot = session => {
    let sessionTime = new Intl.DateTimeFormat('en', {
      hour: 'numeric',
      minute: 'numeric',
    }).format(new Date(session.startDateTime))
    this.handelConformation([sessionTime, session._id].join(','))
  }

  handleJoinNow = async post => {
    const session = post.ses_det[0]
    let sessionTime = new Intl.DateTimeFormat('en', {
      hour: 'numeric',
      minute: 'numeric',
    }).format(new Date(session.startDateTime))
    await this.props.onSelectRsvpTime(
      [sessionTime, session._id].join(','),
      this.props.post,
      this.props.event,
      true,
    )
    this.onCloseJoinNow()
    this.props.joinMeeting(post)
  }

  handleConfirmClose = () => {
    this.setState({
      openconfirmModel: false,
      openCalenderModel: false,
    })
  }

  handleCalenderClose = () => {
    this.setState({
      openCalenderModel: false,
    })
  }

  handleCalenderSubmit = () => {
    // this.setState({
    //   openCalenderModel: false,
    // });
  }

  handleConfirmSubmit = () => {
    this.setState({
      // openconfirmModel: false,
      openCalenderModel: true,
    })
  }

  // Renderer callback with condition
  renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (
      days == 0 &&
      hours == 0 &&
      minutes <= 5 &&
      seconds <= 60 &&
      this.state.islive == false
    ) {
      this.setState({
        islive: true,
      })
    }

    if (completed) {
      // Render a complete state
      return <Completionist videoUrl={this.props.post.videoUrl} />
    } else {
      // Render a countdown
      return (
        <div>
          {days > 0 ? (
            <span>
              Start in {days} days, {hours} hrs, {minutes} mins, {seconds} sec
            </span>
          ) : (
            <span>
              {days == 0 && hours == 0 && minutes <= 5 && seconds <= 60 ? (
                <div>Live</div>
              ) : (
                <div>
                  Start in {days} days, {hours} hrs,{minutes} mins, {seconds}{' '}
                  sec{' '}
                </div>
              )}
            </span>
          )}
        </div>
      )
    }
  }

  /***************************/
  toggleDesc = () => {
    if (this.state.isLessOn) {
      this.setState({
        isLessOn: false,
      })
    } else {
      this.setState({
        isLessOn: true,
      })
    }
  }

  /***************************/
  handleCollaps = () => {
    this.setState({
      collapsible: !this.state.collapsible,
      isLessOn:false
    })
  }

  render() {
    const {
      index,
      post,
      eventDate,
      eventTime,
      attendingCount,
      fetchEventDetails,
      fetchAttendeesDetails,
      joinMeeting,
      singleEvent,
      shareClicked,
      closeSingleEvent,
      upcomingEvents, myBookingeventData
    } = this.props
    console.log("and-event--time--tst-->>", eventTime);
    const { isLessOn, openCancelAlert, confirmJoinNow } = this.state
    const shouldShowRsvpAndShareButton = true
      // upcomingEvents && upcomingEvents[index].capacity !== 0
    let RsvpButtonElement = shouldShowRsvpAndShareButton && post.capacity!==0 ? (
      <button
        type="button"
        className="btn btn-primary book-btn"
        onClick={() => this.handleOpenConfirmRsvp()}
      >
        RSVP
      </button>
    ) : null
    
    return (
      <div
        className={`box_row ${singleEvent &&
          'single-event-modal'} event-layout-box ${myBookingeventData && "myBookingCard"}`}
        // className={`col-12	col-sm-12	col-md-6	col-lg-6	col-xl-3 box_row ${singleEvent &&
        //   "single-event-modal"}`}
        // className={`col-12 col-md-6 col-xl-3 box_row ${singleEvent && "single-event-modal"}`}
        id={post._id}
        key={index}
        onClick={e => e.stopPropagation()}
        // style={{"backgroundColor": "green"}}
      >
        <div className="box-bg ios-popconfirm">
          <div className="box_list_item">
            <div onClick={this.handleCollaps}>
              <div className="event-image-container">
                {!this.state.collapsible ? (
                  <img
                    className="thumb-img"
                    onClick={() => fetchEventDetails(post, index)}
                    src={post.image || '../../../assets/img/user-img.png'}
                    alt=""
                  />
                ) : (
                  <img
                    className="thumb-img"
                    src={post.image || '../../../assets/img/user-img.png'}
                    alt=""
                  />
                )}
                {singleEvent ? (
                  <button
                    className="modal-close-blue-event"
                    onClick={() => closeSingleEvent()}
                  >
                    <img
                      src="../../assets/img/close-white-new.svg"
                      alt="Close"
                    />
                  </button>
                ) : (
                  ''
                )}
                {post.attendingflag ? (
                  <div className="bottom-left-rsvp-btn">
                    <SimpleCheckIcon fill="white" />
                    Attending
                  </div>
                ) : (
                  <div />
                )}
              </div>
              {!this.state.collapsible ? (
                <div
                  className="date-time-box-event"
                  onClick={() => fetchEventDetails(post, index)}
                >
                  <div className="d-flex-box">
                    {console.log('eventTime', eventTime)}
                    <span className="date">{eventDate || 'NA'}<span>,&nbsp;&nbsp;{eventTime}</span></span>
                    <span className="min">
                      {' ' + post.duration + ' Min' || 'NA'}{' '}
                    </span>
                  </div>
                  <div className="d-flex-box main-title">
                    {this.state.collapsible ? (
                      <h3>{post.name || 'NA'}</h3>
                    ) : (
                      <h4>{post.name || 'NA'}</h4>
                    )}
                    <img
                      src="../../../assets/img/clock-icon.png"
                      width="14"
                      height="14"
                      alt=""
                    />
                  </div>
                </div>
              ) : (
                <div className="date-time-box-event">
                  <div className="d-flex-box">
                    <span className="date">{eventDate || 'NA'}<span>,&nbsp;&nbsp;{eventTime}</span></span>
                    <span className="min">
                      {' ' + post.duration + ' Min' || 'NA'}{' '}
                    </span>
                  </div>
                  <div className="d-flex-box main-title">
                    {this.state.collapsible ? (
                      <h3>{post.name || 'NA'}</h3>
                    ) : (
                      <h4>{post.name || 'NA'}</h4>
                    )}
                    <img
                      src="../../../assets/img/clock-icon.png"
                      // src="assets/img/clock-icon.png"
                      width="14"
                      height="14"
                      alt=""
                    />
                  </div>
                </div>
              )}
            </div>
            {this.state.collapsible ? (
              <div
                className="box_description"
                style={{
                  display: `${this.state.collapsible ? 'block' : 'none'}`,
                }}
              >
                {post.description && post.description.length > 100 ? (
                  <div className="col-desc br-btm-desc">
                    {isLessOn ? (
                      <pre
                        onClick={() => this.setState({ collapsible: false })}
                      >
                        {betterDescription(post.description)}
                        <span
                          className="more_less-btn"
                          onClick={(e) => { this.toggleDesc(); e.stopPropagation() }}
                        >less </span>
                      </pre>
                    ) : (
                      <pre onClick={() => this.setState({ collapsible: false })}>
                        {post.description.substring(0, 100)}
                        <span
                          className="more_less-btn"
                          onClick={(e) => { this.toggleDesc(); e.stopPropagation() }}
                        > ...more </span>
                      </pre>
                    )}
                  </div>
                ) : (
                  <div className="col-desc br-btm">
                    <p onClick={() => this.setState({ collapsible: false })}>
                      {betterDescription(post.description)}
                    </p>
                  </div>
                )}

                <div
                  className="event-time d-flex-box br-btm"
                  onClick={() => this.setState({ collapsible: false })}
                >
                  <div className="event-date">
                    <small>Date</small>
                    <span>{eventDate || 'NA'}</span>
                  </div>
                  <div className="event-date">
                    <small>Time</small>
                    <span>
                      {post.ses_det && post.ses_det.length === 1
                        ? eventTime || 'NA'
                        : `${post.ses_det.length} time slots`}
                    </span>
                  </div>
                  <div className="event-date">
                    <small>Duration</small>
                    <span>{post.duration + ' Min' || 'NA'}</span>
                  </div>
                </div>
                {post.instructor._id && (
                  <div className="d-flex-box br-btm event-instructor instructor-details">
                    <div className="instructor-bio">
                      <img
                        src={
                          post.instructor.instructorImage ||
                          '../../../assets/img/user-img.png'
                        }
                        alt=""
                      />
                      <div className="title">
                        <h6>{post.instructor.name || 'NA'}</h6>
                      </div>
                    </div>

                    <div className="event-atten">
                      <div className="instructor-attending">
                        <h6 onClick={() => fetchAttendeesDetails(post)}>
                          {attendingCount} attending
                        </h6>
                        <img
                          src="../../../assets/img/next-arrow.jpg"
                          alt=""
                          onClick={() => fetchAttendeesDetails(post)}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {post.attendingflag ? (
                  [
                    post.isStreamLive ? (
                      <div>
                        <div className="rsvped-btn">
                          <a href="#" onClick={() => joinMeeting(post)}>
                            Live
                          </a>
                        </div>
                        {/* <div className="rsvp-btn">
                      <AddToCalendar
                        event={event}
                        listItems={items}
                        buttonTemplate={icon}
                      />
                    </div> */}
                      </div>
                    ) : (
                      <div>
                        <div className="rsvped-btn">
                          <button
                            onClick={e =>
                              this.openCancelAlertView(
                                post.dateWithDuration,
                                post.duration,
                                e,
                              )
                            }
                          >
                            <Countdown
                              date={
                                Date.now() +
                                CountDowm(post.dateWithDuration, post.duration)
                              }
                              renderer={this.renderer}
                            />
                          </button>
                          {/* <a
                            id="cancel"
                            href="#"
                            onClick={() =>
                              this.openCancelAlertView(
                                post.dateWithDuration,
                                post.duration
                              )
                            }
                          >
                            <Countdown
                              date={
                                Date.now() +
                                CountDowm(post.dateWithDuration, post.duration)
                              }
                              renderer={this.renderer}
                            />
                          </a> */}
                        </div>
                        {/* <div className="rsvp-btn">
                      <AddToCalendar
                        event={event}
                        listItems={items}
                        buttonTemplate={icon}
                      />
                    </div> */}
                      </div>
                    ),
                  ]
                ) : // <>
                // {post.ses_det.length === 1 ? <}
                // </>
                post.isStreamLive ? (
                  <div className="rsvped-btn">
                    <a href="#" onClick={() => this.handleOpenJoinNow()}>
                      Join Now
                    </a>
                  </div>
                ) : post.ses_det && post.ses_det.length === 1 ? (
                  RsvpButtonElement
                ) : (
                  <DropdownButton
                    id="dropdown-basic-button"
                    className="rsvp-button-drop-down"
                    onSelect={this.handleOpenConfirmRsvpMultipleSlot}
                    title="RSVP"
                  >
                    {post.ses_det.map((session, index) => {
                      let sessionTime = new Intl.DateTimeFormat('en', {
                        hour: 'numeric',
                        minute: 'numeric',
                      }).format(new Date(session.startDateTime))
                      return (
                        <Dropdown.Item eventKey={[sessionTime, session._id]}>
                          {sessionTime}
                        </Dropdown.Item>
                      )
                    })}
                  </DropdownButton>
                )}

                {shouldShowRsvpAndShareButton && (
                  <button
                    type="button"
                    className="btn btn-primary book-btn"
                    onClick={() => shareClicked(post)}
                    style={{ 'text-transform': 'capitalize' }}
                  >
                    Share
                  </button>
                )}

                {this.state.confirmEventModel ? (
                  <IosModel
                    handleCloseModel={this.onCloseRsvpTime}
                    handelSubmitModel={() =>
                      this.handleSingleSlot(post.ses_det[0])
                    }
                  />
                ) : (
                  ''
                )}
                {this.state.confirmEventModelMulti && (
                  <IosModel
                    handleCloseModel={this.onCloseRsvpTimeMulti}
                    handelSubmitModel={() =>
                      this.handelConformation(this.state.evtData)
                    }
                  />
                )}

                {/* MODAL CANCEL ALERT VIEW START *****************************************************************/}
                {openCancelAlert && (
                  <div
                    className={`rsvp-confirm-model-wrapper fadeIn`}
                    id="confirmPopUp"
                  >
                    <div className="rsvp-confirm-model">
                      <h5 style={{ paddingLeft: 0 }}>Cancel RSVP</h5>
                      <p>Are you sure you want to cancel RSVP?</p>
                      <div className="confirm-button-wrapper">
                        <button
                          onClick={this.closeCancelAlertView}
                          className="cancel-modal"
                        >
                          No
                        </button>
                        <button
                          onClick={() => this.cancelRsvp(post)}
                          className="confirm-modal"
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {/* MODAL CANCEL ALERT VIEW END *******************************************************************/}

                {confirmJoinNow && (
                  <div className={`rsvp-confirm-model-wrapper fadeIn`}>
                    <div className="rsvp-confirm-model">
                      <h5 style={{ paddingLeft: 0 }}>Join Now</h5>
                      <p>Confirm that you would like to join now this event?</p>
                      <div className="confirm-button-wrapper">
                        <button
                          onClick={this.onCloseJoinNow}
                          className="cancel-modal"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={() => this.handleJoinNow(post)}
                          className="confirm-modal"
                        >
                          Confirm
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {/* {this.state.openconfirmModel ? (
              <ConfirmeCard
                handleCloseModel={this.handleConfirmClose}
                handelSubmitModel={this.handleConfirmSubmit}
              />
            ) : (
              ""
            )}
            {this.state.openCalenderModel ? (
              <ChooseCalender
                handleCloseModel={this.handleCalenderClose}
                handelSubmitModel={this.handleCalenderSubmit}
              />
            ) : (
              ""
            )} */}
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    )
  }
}
