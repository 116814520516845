import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import LinearProgress from '@material-ui/core/LinearProgress'
import { Backdrop, CircularProgress, withStyles } from '@material-ui/core'
import firebase from 'firebase'
import { signIn } from '../../store/actions/authActions'
import termsPdf from '../../common/assets/Better Spaces Terms Of Service.pdf'
import privacyPdf from '../../common/assets/Better Spaces Privacy.pdf'
import './signin.css'
import { compose } from 'redux'
import config from '../../config/config'

const styles = theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#287fde',
  },
})

class SignInPasswordless extends Component {
  state = {
    email: process.env.REACT_APP_BUILD === 'staging' ? 'sahil@blulabs.in' : '',
    loading: false,
  }

  // ***********
  hideLoader = () => {
    this.setState({ loading: false })
  }

  showLoader = () => {
    this.setState({ loading: true })
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value,
    })
  }

  handleKeypress = e => {
    //it triggers by pressing the enter key
    if (e.key === 'Enter') {
      // console.log("enter pressed")
      this.handleNext()
    }
  }

  openTermsPdf = () => {
    window.open(termsPdf)
  }

  openPrivacyPdf = () => {
    window.open(privacyPdf)
  }

  handleNext = () => {
    var email = this.state.email

    if (email === '') {
      window.alert('Please enter an email address')
      return
    } else if (
      !RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email)
    ) {
      window.alert('Please enter valid email address')
      return
    }

    this.showLoader()
    const component = this
    // param.preventDefault();

    var actionCodeSettings = {
      url: config.firebase_url,
      handleCodeInApp: true,
      dynamicLinkDomain: config.dynamicLinkDomain,
    }

    const appIdentifier =
      process.env.REACT_APP_ENV === 'production'
        ? 'com.betterspaces'
        : 'devWebApp'

    console.log('appIdentifier:1:sendOtp', appIdentifier)

    // this is POST and the other is PUT(i.e,. check otp.)
    fetch(config.authWithOtp, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        appIdentifier,
        apiversion: '1.1',
      },
      body: JSON.stringify({
        email: email,
      }),
    })
      .then(res => res.json())
      .then(data => {
        this.hideLoader()
        localStorage.setItem('emailForSignIn', email)
        localStorage.setItem('emailForSignInFirebaseId', data.firebaseId)
        this.props.history.push({
          pathname: '/magiclink',
          // email: email,
        })
      })
      .catch(e => {
        this.hideLoader()
      })

    /** // ? Old auth setup. */
    // firebase
    //   .auth()
    //   .sendSignInLinkToEmail(email, actionCodeSettings)
    //   .then(function() {
    //     component.hideLoader()
    //     localStorage.setItem('emailForSignIn', email)
    //     //window.alert("Please check your email for verification link.");
    //     // PUSH TO NEW SCREEN OF MAGIC LINK
    //     component.props.history.push({
    //       pathname: '/magiclink',
    //       // email: email,
    //     })
    //   })
    //   .catch(function(error) {
    //     component.hideLoader()
    //     console.log('error ', error)
    //     // Some error occurred, you can inspect the code: error.code
    //   })
    // this.props.signIn(this.state);
  }

  render() {
    // const { plan, auth, loading } = this.props;
    const { loading } = this.props
    // if (auth.uid && plan) {
    //   if (plan === "-") return <Redirect to="/welcome" />;
    //   else return <Redirect to="/" />;
    // }

    return (
      <div>
        <Backdrop
          className={this.props.classes.backdrop}
          open={this.state.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className={this.props.classes.root}>
          {loading ? <LinearProgress /> : null}
        </div>
        <div className="xs-text-center">
          <div className="login-back-img">
            <div className="container-fluid">
              <div className="logo-header">
                <a href="#" className="logo">
                  <img
                    src="../assets/img/logo-bs.svg"
                    className="img-fluid"
                    alt="logo"
                  />
                </a>
              </div>
              <div className="modal-layout">
                <h2>Log in</h2>
                <div className="col-lg-12 col-md-12 col-sm-12 p-0 email-input">
                  <input
                    type="email"
                    id="email"
                    style={{ textAlign: 'center', fontSize: '2.3rem' }}
                    value={this.state.email}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeypress}
                    className="form-control"
                    placeholder="Your email address"
                  ></input>
                </div>
                <p>We’ll send you a quick email to confirm your address.</p>
                <div className="btn-next">
                  <a href="#" onClick={() => this.handleNext()}>
                    Next
                  </a>
                </div>
                <div className="link-info text-left">
                  <a href="#" onClick={() => this.openTermsPdf()}>
                    {' '}
                    Terms of Service{''}
                  </a>
                  <span> and </span>{' '}
                  <a href="#" onClick={() => this.openPrivacyPdf()}>
                    {' '}
                    Privacy Policy{' '}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    plan: state.auth.plan,
    authError: state.auth.authError,
    auth: state.firebase.auth,
    loading: state.auth.loading,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    signIn: creds => dispatch(signIn(creds)),
  }
}

//export default compose(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true }))(SignIn);
const enhance = compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
)
export default enhance(withRouter(SignInPasswordless))
