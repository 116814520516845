const initState = {
  isProfileDetailsLoaded: false,
}

const profileDetailsReducer = (state = initState, action) => {
  switch (action.type) {
    case 'profileDetails/update':
      //   console.log('created post', action.post)
      return {
        ...state,
        isProfileDetailsLoaded: true,
        ...action.payload,
      }

    default:
      return state
  }
}

export default profileDetailsReducer
