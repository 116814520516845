import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setPlan, setEnterpriseEmail } from '../../store/actions/authActions'
import './stylenew.css'
import { Redirect } from 'react-router-dom'

class Welcome extends Component {
  constructor(props) {
    super(props)
    this.state = {
      plan: null,
      email: '',
    }
  }

  handleChange = e => {
    this.setState({
      email: e.target.value,
    })
  }

  goToHome = e => {
    localStorage.setItem('plan', e.target.id)
    this.props.setPlan(e.target.id)
  }

  setEmail = () => {
    localStorage.removeItem('plan')
    this.props.setEnterpriseEmail(this.state.email)
  }

  render() {
    const { plan } = this.props
    const savedPlan = localStorage.getItem('plan')

    if ((plan || savedPlan) && plan !== '-') {
      if (plan === 'enterprise' || savedPlan === 'enterprise') {
        return (
          <div className="welcome">
            <h2>Enterprise Plan</h2>
            <p>Enter your email id to connect with an Enterprise Account.</p>
            <br></br>
            <div className="field-input">
              <div className="custom-input">
                <span className="icon-input">
                  <img src="../assets/img/icon-email.svg" />
                </span>
                <input
                  className="input-control"
                  type="email"
                  id="email"
                  onChange={this.handleChange}
                  placeholder="Email"
                />
              </div>
            </div>
            <br></br>
            <button
              type="submit"
              className="btn btn-white"
              onClick={this.setEmail}
            >
              SUBMIT
            </button>
          </div>
        )
      } else {
        localStorage.removeItem('plan')
        return <Redirect to="/" />
      }
    }

    return (
      <div className="welcome">
        <h2>Welcome to The Betterspaces</h2>
        <p>
          Choose your plan before you proceed to our features. You can always
          change your plan later.
        </p>
        <br></br>
        <button
          id="free"
          type="submit"
          className="btn btn-white"
          onClick={this.goToHome}
        >
          FREE PLAN
        </button>
        <br></br>
        <button
          id="company"
          type="submit"
          className="btn btn-white"
          onClick={this.goToHome}
        >
          COMPANY PLAN
        </button>
        <br></br>
        <button
          id="enterprise"
          type="submit"
          className="btn btn-white"
          onClick={this.goToHome}
        >
          ENTERPRISE PLAN
        </button>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    plan: state.auth.plan,
    authError: state.auth.authError,
    auth: state.firebase.auth,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setPlan: plan => dispatch(setPlan(plan)),
    setEnterpriseEmail: email => dispatch(setEnterpriseEmail(email)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Welcome)
