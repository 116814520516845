export const createPost = post => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to db
    const firestore = getFirestore()
    const profile = getState().firebase.profile
    const authorId = getState().firebase.auth.uid
    console.log('post.buildingId  ' + post.buildingId)
    firestore
      .collection('posts')
      .add({
        title: post.title,
        content: post.content,
        imagelink: post.imagelink,
        zoomlink: post.zoomlink,
        zoomuser: post.zoomuser,
        zoompswd: post.zoompswd,
        buildingId: post.buildingId,
        buildingName: post.buildingName,
        authorFirstName: profile.firstName,
        authorLastName: profile.lastName,
        authorId: authorId,
        createdAt: new Date(),
      })
      .then(() => {
        dispatch({ type: 'CREATE_POST', post: post })
      })
      .catch(err => {
        dispatch({ type: 'CREATE_POST_ERROR', err })
      })
  }
}
