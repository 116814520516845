export const signIn = credentials => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase()
    const firestore = getFirestore()

    dispatch({ type: 'LOADING' })
    if (credentials.isLoginWithApple || credentials.isLoginWithGoole) {
      console.log('apple/google sign in....', credentials.uid)
      const usersRef = firestore.collection('users').doc(credentials.uid)
      usersRef.get().then(docSnapshot => {
        if (docSnapshot.exists) {
          usersRef.onSnapshot(doc => {
            // do stuff with the data
            console.log('login success....auth actions ', doc.data().plan)
            dispatch({ type: 'LOGIN_SUCCESS', plan: doc.data().plan })
          })
        } else {
          console.log('create new user....')
          firestore
            .collection('users')
            .doc(credentials.uid)
            .set({
              firstName: credentials.firstName,
              lastName: credentials.lastName,
              initials: credentials.firstName[0],
              email: credentials.email,
              plan: '-',
              //photo:credentials.photo
            })
            .then(() => {
              console.log('sign up success....')
              dispatch({ type: 'SIGNUP_SUCCESS' })
            })
            .catch(err => {
              // dispatch({ type: 'SIGNUP_ERROR', err })
            })
        }
      })
    } else {
      dispatch({ type: 'LOGIN_SUCCESS', plan: '-' })

      // firebase.auth().signInWithEmailAndPassword(
      //     credentials.email,
      //     credentials.password
      // ).then((resp) => {
      //     const usersRef = firestore.collection('users').doc(resp.user.uid)
      //     usersRef.get().then((docSnapshot) => {
      //         if (docSnapshot.exists) {
      //               usersRef.onSnapshot((doc) => {
      //                  // do stuff with the data
      //                  dispatch({ type: 'LOGIN_SUCCESS',plan:doc.data().plan })
      //              });
      //         }
      //         else
      //              console.log('else of docSnapshot...')
      //     });
      //   }).catch((err) => {
      //     dispatch({ type: 'LOGIN_ERROR', err })
      // })
    }
  }
}

export const signOut = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase()

    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: 'SIGNOUT_SUCCESS' })
      })
  }
}

export const signUp = credentials => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase()
    const firestore = getFirestore()

    if (credentials.isLoginWithApple || credentials.isLoginWithGoole) {
      console.log('apple/google sign in....', credentials.uid)
      const usersRef = firestore.collection('users').doc(credentials.uid)
      usersRef.get().then(docSnapshot => {
        if (docSnapshot.exists) {
          console.log('login success....')

          usersRef.onSnapshot(doc => {
            // do stuff with the data
            dispatch({ type: 'LOGIN_SUCCESS', plan: doc.data().plan })
          })
        } else {
          console.log('create new user....')
          firestore
            .collection('users')
            .doc(credentials.uid)
            .set({
              firstName: credentials.firstName,
              lastName: credentials.lastName,
              initials: credentials.firstName[0],
              email: credentials.email,
              plan: '-',
            })
            .then(() => {
              console.log('sign up success....')
              dispatch({ type: 'SIGNUP_SUCCESS' })
            })
            .catch(err => {
              dispatch({ type: 'SIGNUP_ERROR', err })
            })
        }
      })
    } else {
      // create user using firebase_authf
      firebase
        .auth()
        .createUserWithEmailAndPassword(
          credentials.email,
          credentials.password,
          // create a new collection with the same user id to store more info
        )
        .then(resp => {
          firestore
            .collection('users')
            .doc(resp.user.uid)
            .set({
              firstName: credentials.firstName,
              lastName: credentials.lastName,
              initials: credentials.firstName[0],
              email: resp.user.email,
              plan: '',
            })
            .then(() => {
              // dispatch an action => signup was a success
              dispatch({ type: 'SIGNUP_SUCCESS' })
            })
        })
        .catch(err => {
          dispatch({ type: 'SIGNUP_ERROR', err })
        })
    }
  }
}

export const signInWithEmail = credentials => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase()
    firebase
      .auth()
      .getUserByEmail(credentials.email)
      .then(function(userRecord) {
        // See the UserRecord reference doc for the contents of userRecord.

        console.log('Successfully fetched user data:', userRecord.toJSON())
        dispatch({ type: 'LOGIN_SUCCESS' })
      })
      .catch(function(error) {
        console.log('Error fetching user data:', error)
        dispatch({ type: 'LOGIN_ERROR', error })
      })
  }
}

export const signInWithPhone = () => {
  return (dispatch, getState, { getFirebase }) => {
    dispatch({ type: 'LOGIN_SUCCESS' })
  }
}

export const setPlan = plan => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase()
    const firestore = getFirestore()

    var user = firebase.auth().currentUser

    if (user) {
      // User is signed in.
      firestore
        .collection('users')
        .doc(user.uid)
        .update({
          plan: plan,
        })
        .then(() => {
          dispatch({ plan: plan, type: 'PLAN_SET' })
        })
        .catch(err => {
          dispatch({ type: 'PLAN_ERROR', err })
        })
    }
  }
}

export const setEnterpriseEmail = email => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase()
    const firestore = getFirestore()

    var user = firebase.auth().currentUser

    if (user) {
      // User is signed in.
      firestore
        .collection('users')
        .doc(user.uid)
        .update({
          enterprise_email: email,
        })
        .then(() => {
          dispatch({ type: 'EMAIL_SET' })
        })
        .catch(err => {
          dispatch({ type: 'EMAIL_ERROR', err })
        })
    }
  }
}
