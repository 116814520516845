import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

// Initialize Firebase
// var config = {
//     apiKey: "AIzaSyCKU5pQhiZ2JkzP7lee34jYiT7jl9XSHN0",
//     authDomain: "fir-crud-2d38f.firebaseapp.com",
//     databaseURL: "https://fir-crud-2d38f.firebaseio.com",
//     projectId: "fir-crud-2d38f",
//     storageBucket: "fir-crud-2d38f.appspot.com",
//     messagingSenderId: "401961946698",
//     appId: "1:401961946698:web:ffb765b70cc9323220a0b3"
// };

// firebase.initializeApp(config);
// firebase.firestore().settings({ timestampsInSnapshots: true });
// firebase.auth();

export default firebase
