import { combineReducers } from 'redux'
// All Reducers
import authReducer from './authReducer'
import postReducer from './postReducer'
import amenitiesReducer from './amenitiesReducer'
import bookingsReducer from './bookingsReducer'
import profileDetailsReducer from './profileDetailsReducer'
import { firestoreReducer } from 'redux-firestore'
import { firebaseReducer } from 'react-redux-firebase'

const rootReducer = combineReducers({
  auth: authReducer,
  post: postReducer,
  amenities: amenitiesReducer,
  booking: bookingsReducer,
  profileDetails: profileDetailsReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
})

export default rootReducer
