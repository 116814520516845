import React, { Component } from 'react'
import { connect } from 'react-redux'
import { signIn } from '../../store/actions/authActions'
import './signin.css'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import termsPdf from '../../common/assets/Better Spaces Terms Of Service.pdf'
import privacyPdf from '../../common/assets/Better Spaces Privacy.pdf'
import config from '../../config/config'
import LinearProgress from '@material-ui/core/LinearProgress'
import { Backdrop, CircularProgress, withStyles } from '@material-ui/core'
import UserProfile from '../auth/UserProfile'
var md5 = require('md5')

const styles = theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#287fde',
  },
})

class SignInWithPassword extends Component {
  state = {
    firstName: '',
    lastName: '',
    uid: '',
    email: localStorage.getItem('emailForSignIn'),
    password: '',
    loading: false,
  }

  // ***********
  hideLoader = () => {
    this.setState({ loading: false })
  }

  showLoader = () => {
    this.setState({ loading: true })
  }
  // ***********

  handleKeypress = e => {
    //it triggers by pressing the enter key
    if (e.key === 'Enter') {
      // console.log("enter pressed")
      this.onClickNext()
    }
  }

  // ***********
  openTermsPdf = () => {
    window.open(termsPdf)
  }

  openPrivacyPdf = () => {
    window.open(privacyPdf)
  }
  // ***********

  // ***********
  handleChange = e => {
    // console.log(e)
    this.setState({
      [e.target.id]: e.target.value,
    })
  }

  onClickNext = () => {
    //console.log(this.props);

    const { email, password } = this.state

    if (email === '') {
      window.alert('Please enter an email address')
    } else if (
      !RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email)
    ) {
      window.alert('Please enter valid email address')
    } else if (password === '') {
      window.alert('Please enter a password')
    } else if (password.length <= 5) {
      window.alert('Password must have at least 6 characters')
    } else {
      this.loginApiCall()
    }
  }

  // ***********
  async loginApiCall() {
    this.showLoader()
    fetch(config.api_url + '/api/v1/auth/login', {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        password: md5(this.state.password),
        email: this.state.email,
      }),
    })
      .then(res => res.json())
      .then(data => {
        // this.hideLoader();
        if (data && data.email) {
          console.log('login response ', data)
          this.fetchProfileDetails(data.email, data._id)
          localStorage.setItem('accessToken', data.token)
          localStorage.setItem('emailForSignIn', data.email)
        } else if (data.error) {
          console.log('login error', data)
          window.alert(data.error)
        }
      })
      .catch(console.log)
  }
  // ***********

  async fetchProfileDetails(email, uid) {
    // this.showLoader();
    fetch(config.api_url + '/api/v1/users/profileDetails', {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ uid: uid, email: email }),
    })
      .then(res => res.json())
      .then(data => {
        // console.log("data ", data.data.token);
        // console.log("role ", data);

        // if (data && data.msg && data.msg === "success") {
        this.setState({
          uid: uid,
          firstName: data.data.firstName,
          lastName: data.data.lastName,
          email: data.data.email,
        })
        UserProfile.setUser(data.data)
        console.log(this.state)
        console.log(this.props)
        this.props.signIn(this.state)
        window.location.href = config.base_url
        // this.props.history.push({
        //   pathname: "/events",
        //   // email: email,
        // });
        this.hideLoader()
      })
      .catch(console.log)
  }

  render() {
    // const { plan, auth, loading } = this.props;
    // console.log(
    //   "🚀 ~ file: SignInWithPassword.js ~ line 133 ~ SignInWithPassword ~ render ~ plan",
    //   plan
    // );
    // if (plan) {
    //   if (plan === "free") return <Redirect to="/events" />;
    //   else return <Redirect to="/" />;
    // }

    return (
      <div>
        <Backdrop
          className={this.props.classes.backdrop}
          open={this.state.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className={this.props.classes.root}>
          {this.state.loading ? <LinearProgress /> : null}
        </div>
        <div className="xs-text-center">
          <div className="loginpass-back-img">
            <div className="container-fluid">
              <div className="logo-header">
                <a href="#" className="logo">
                  <img
                    src="../assets/img/logo-bs.svg"
                    className="img-fluid"
                    alt="logo"
                  />
                </a>
              </div>
              <div className="modal-layout">
                <h2>Log in</h2>
                <div className="col-lg-12 col-md-12 col-sm-12 p-0 email-input mb-4">
                  <input
                    type="text"
                    id="email"
                    value={this.state.email}
                    className="form-control"
                    onChange={this.handleChange}
                    placeholder="Your email address"
                  ></input>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 p-0 email-input mb-4">
                  <input
                    type="password"
                    id="password"
                    value={this.state.password}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeypress}
                    className="form-control"
                    placeholder="Password"
                  ></input>
                </div>
                <div className="mb-4">
                  {/* <a href="#" className="forgot-pass">
                  Forgot Password?
                </a> */}
                </div>
                <div className="btn-next">
                  <a href="#" onClick={() => this.onClickNext()}>
                    Next
                  </a>
                </div>
                <div>
                  <p className="text-center mb-2">
                    By creating an account you agree to our
                  </p>
                </div>
                <div className="link-info text-center">
                  <a href="#" onClick={() => this.openTermsPdf()}>
                    {' '}
                    Terms of Service{' '}
                  </a>{' '}
                  <span> & </span>{' '}
                  <a href="#" onClick={() => this.openPrivacyPdf()}>
                    {' '}
                    Privacy Policy{' '}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    plan: state.auth.plan,
    authError: state.auth.authError,
    auth: state.firebase.auth,
    loading: state.auth.loading,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    signIn: creds => dispatch(signIn(creds)),
  }
}

const enhance = compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
)

export default enhance(withRouter(SignInWithPassword))
