import { CircularProgress } from '@material-ui/core'
import React from 'react'

export default description => {
  if (!description)
    return (
      <div className="d-flex w-100 align-items-center justify-content-center">
        <CircularProgress color="primary" />
      </div>
    )

  let s = description
  // ? Probably we can also link to map applications as well using the gps IMO, in meeting if get gps locations of each buildings, so it would directly point to google maps applicatoin.
  // ? Probably we can need to figure out inteligent way to save buildings gps addresses for system for our usage, saving gps location would never be tedious task.
  // https://developers.google.com/maps/documentation/urls/get-started
  // ^^ some reference to create deep link (i.e., link that'll open google-maps using the link that has gps coordingate to help provide the opening of the gps location.)
  // GOOGLE _SEARCH >>?? deep link for gps google maps

  // ? for testing...
  // let s = `USPS, UPS, FedEx. ddisarno@aveliving.com USPS membership@parkavenueclub.com https://www.parkavenueclub.com (800) 275-8777 2:00PM - 4:00PM UPS (973) 660-0800 100 Bldg: 11:30AM - 12:00PM Other Buildings: 12:00PM-2:00PM FedEx (800) GO FEDEX 11:30AM - 12:30PM`

  let urlR = /\bhttps?:\/\/\S+/gi
  let matches1 = s.match(urlR)
  //   ^^ this is an arrary of matched urls in the text.
  if (matches1) {
    matches1.forEach(url => {
      s = s.replace(url, `<a target="_blank" href="${url}">${url}</a>`)
    })
  }
  console.log(s)

  let emailR = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/g
  let matches2 = s.match(emailR)
  //   ^^ this is an array of email addresses in string.
  if (matches2) {
    matches2.forEach(url => {
      s = s.replace(url, `<a href="mailto:${url}">${url}</a>`)
    })
  }
  console.log(s)

  let phoneR1 = /\(\d+\)\s\d+-\d+/g
  let matches3 = s.match(phoneR1)
  // ^^ this is an array of phone numbers in string, i.e., ['(800) 275-8777', '(973) 660-0800']
  if (matches3) {
    matches3.forEach(url => {
      s = s.replace(url, `<a href="tel:${url}">${url}</a>`)
    })
  }

  let phoneR2 = /\d+-\d+-\d+/g
  let matches4 = s.match(phoneR2)
  // ^^ this is an array of phone numbers in string, i.e., ['973-271-8539']
  if (matches4) {
    matches4.forEach(url => {
      s = s.replace(url, `<a href="tel:${url}">${url}</a>`)
    })
  }
  console.log(s)

  return (
    <div
      dangerouslySetInnerHTML={{
        // __html: 'description<a href="https://google.com">I am google.com</a>',
        __html: s,
      }}
    ></div>
  )
  // return description
}
