import React, { Component } from 'react'
import moment from 'moment'
// import '../dashboard/Rooms/room.css';
import '../dashboard/Rooms/room.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-calendar/dist/Calendar.css'
// import AddToCalendar from "react-add-to-calendar";
// import { Dropdown, DropdownButton } from "react-bootstrap";
// import ConfirmeCard from "./ConformeCard";
// import ChooseCalender from "./ChooseCalender";
// import IosModel from "./IosModel";
// import $ from "jquery";
// import moment from "moment";
// import ReactMomentCountDown from "react-moment-countdown";
// import { CountDowm } from "../assets/js/healper";
// import Countdown from "react-countdown";
// import SimpleCheckIcon from "../../../assets/SimpleCheck";
import betterDescription from '../amenities/betterDescription'
import upArrow from '../../assets/img/upArrow.svg'
import downArrow from '../../assets/img/downArrow.svg'
import Calendar from 'react-calendar'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import 'date-fns'
import Modal from 'react-modal'

import DateFnsUtils from '@date-io/date-fns'
import DatePicker from "react-datepicker"
import addDays from 'date-fns/addDays'
import "react-datepicker/dist/react-datepicker.css";
import e from 'cors'
import IosModel from '../dashboard/Events/IosModel'
import {CircularProgress} from '@material-ui/core';

// import {TextField} from '@material-ui/core'
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
// import Stack from '@mui/material/Stack';

export const Completionist = () => <span>Live</span>

export default class BookingCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      confirmEventModel: false,
      openconfirmModel: false,
      openCalenderModel: false,
      collapsible:  false,
      isLessOn: false,
      preBookingFlag:false,
      preBookingModelData:[],
      selectedDate: this.props.myBookingData && this.props.myBookingData.length ? new Date(this.props.myState.displayDate) : new Date(),
    }
  }

  componentDidMount = () => {
    const {myBookingData, myState} = this.props
    // const countdown = CountDowm(this.props.post.dateWithDuration);
    // console.log(
    //   "🚀 ~ file: EventCard.js ~ line 23 ~ EventCard ~ countdown",
    //   CountDowm(this.props.post.dateWithDuration)
    // );
    // this.props.myBookingData.length && this.setState({
    //   selectedDate: 
    // })
    this.getClassName(this.props.myState, this.props.bookingSlot)
  }

  componentDidUpdate(prevProps, prevState) {
    // if (this.props.myBookingData && prevState.collapsible !== this.state.collapsible) {
    //   this.getClassName(this.props.myState, this.props.bookingSlot)
    // }
    if (prevProps.myState && this.props.myState) {
      if (prevProps.myState.slotsToBeRendered !==
        this.props.myState.slotsToBeRendered ||
        (prevProps.myState.currentDate && prevProps.myState.currentDate) !==
        (this.props.myState.currentDate && this.props.myState.currentDate)) {
        this.getClassName(this.props.myState, this.props.bookingSlot)
      }
    }
    if (prevProps.bookingSlot !== this.props.bookingSlot) {
      this.getClassName(this.props.myState, this.props.bookingSlot)
    }

    if(this.state.selectedDate !== prevState.selectedDate){
     let date = new Date().toISOString().split("T")[0];
     let selected = this.state.selectedDate.toISOString().split("T")[0];
     if(date == selected) {this.setState({ blurBack:true,blurFront: false  });return}
     if(selected == addDays(new Date(),90).toISOString().split("T")[0]) {this.setState({ blurBack:false,blurFront: true  });return}
     else{this.setState({ blurBack:false,blurFront:false  })};
    }
  }

  unAvailPopup = (slot, myState) => {
    if (this.state.preBookingFlag) {
      this.setState({preBookingFlag: false});
      return
    }
    let filtered = myState.selectedLocation.bookings.filter((data) => data._id == slot.bookingId)
    this.setState({
      preBookingModelData: filtered,
      preBookingFlag: true
    });
  }

  getRandomColor() {
    let color = "hsl(" + Math.random() * 360 + ", 100%, 75%)";
    return color;
  }
  onCloseRsvpTime = () => {
    this.setState({
      confirmEventModel: false,
    })
  }

  // handleOpenConfirmRsvp = () => {

  // };

  handelConformation = evt => {
    this.setState({
      openconfirmModel: true,
    })
    // this.setState({
    //   confirmEventModel: false,
    //   openconfirmModel: true,
    // });
    this.props.handleSubmitEvent()
    this.props.onSelectRsvpTime(evt, this.props.post, this.props.event)
  }

  handleConfirmClose = () => {
    this.setState({
      openconfirmModel: false,
      openCalenderModel: false,
    })
  }

  handleCalenderClose = () => {
    this.setState({
      openCalenderModel: false,
    })
  }

  handleCalenderSubmit = () => {
    // this.setState({
    //   openCalenderModel: false,
    // });
  }

  handleConfirmSubmit = () => {
    this.setState({
      // openconfirmModel: false,
      openCalenderModel: true,
    })
  }

  toggleDesc = () => {
    if (this.state.isLessOn) {
      this.setState({
        isLessOn: false,
      })
    } else {
      this.setState({
        isLessOn: true,
      })
    }
  }

  // Renderer callback with condition
  renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      return <Completionist />
    } else {
      // Render a countdown
      return (
        <div>
          {days > 0 ? (
            <span>
              Start in {days} days, {hours} hrs, {minutes} mins
            </span>
          ) : (
            <span>
              Start in {hours} hrs, {minutes} mins, {seconds} sec
            </span>
          )}
        </div>
      )
    }
  }

  handleCollaps = () => {
    this.setState({
      collapsible: !this.state.collapsible,
      isLessOn: false,
      selectedDate: this.props.myBookingData && this.props.myBookingData.length ? new Date(this.props.myState.displayDate) : new Date()
    })
  }

  timeSlots = props => {
    // array of N elements, where N is the number of rows needed
    const rows = [...Array(Math.ceil(props.length / 4))]
    // chunk the products into the array of rows
    // const productRows = rows.map( (row, idx) => props.products.slice(idx * 4, idx * 4 + 4) ); );
    const productRows = rows.map((row, index) => {
      props.slice(index * 4, index * 4 + 4)
    })
    // map the rows as div.row
    // const content = productRows.map((row, idx) => (
    //     <div className="row" key={idx}>
    //       // map products in the row as article.col-md-3
    //       { row.map( product => <article key={product} className="col-md-3">{ product }</article> )}
    //     </div> )
    // );
    // return (
    //     <div>
    //       {content}
    //     </div>
    // );
  }


  getClassName = (data, bookingSlot) => {
    const {myState, UserProfile, getBookedClassName} = this.props
    const newData = [];
    data && data.slotsToBeRendered && data.slotsToBeRendered.length && data.slotsToBeRendered.map((slot, index) => {
      let isSlotBooked = false
      let slotStartTime = moment(slot.dateTime)
      let isSlotBookedByMe = false
      const shadowSlot = {...slot}
      let slotEndTime = moment(slot.dateTime).add(
        30,
        'minutes',
      )
      const renderData = data.selectedLocation.bookings || data.selectedLocation.availableTimes
      //---------------------------------------
      renderData.map(
        (booking, _bookingIndex) => {
          let bookingStartTime = moment(booking.startDate)
          let bookingEndTime = moment(booking.endDate) 
          console.log('bookingStartTime',bookingStartTime.format("D-M-YYYY hh:mm A"),slotStartTime.format("D-M-YYYY hh:mm A"),slotStartTime.isBefore(bookingStartTime))
          // check whether slot is booked - Need to add condition for continous slotsToBeRendered
          if (
            !(
              slotStartTime.isBefore(bookingStartTime) ||
              slotEndTime.isAfter(bookingEndTime) 
            ) &&
            !isSlotBooked
          ) {
            isSlotBooked = true
            myState.slotsToBeRendered[index].bookingId = booking._id
            slot.isSlotBooked = true
            shadowSlot.bookingId = booking && booking._id
            slot.bookingStart = bookingStartTime.format(
              'hh:mm A',
            )
            slot.startBookingDateTime = bookingStartTime.format()
            slot.bookingEnd = bookingEndTime.format(
              'hh:mm A',
            )
            slot.endBookingDateTime = bookingEndTime.format()
            if (
              (booking && booking.user&& booking.user._id) === (UserProfile && UserProfile.user_id())
            ) {
              isSlotBookedByMe = true
              slot.isSlotBookedByMe = true
            } 
            slot.booking = booking
          }
        },
      )
      newData.push(slot)
    })
    this.setState({
      newData: newData
    })
  }

  render() {
    const {
      index,
      location,
      myState,
      getBookedClassName,
      bookSlots,
      redefineCurrentBookingSlots,
      openBookingDetailView,
      onDateClick,
      fetchLocationDetails,
      refetchData, myBookingData
    } = this.props
    const {isLessOn} = this.state
    
    return (
      <div
        className={`box_row
        'single-event-modal' event-layout-box ${myBookingData && "myBookingCard"}`}
        id={location._id}
        key={index}
        onClick={e => e.stopPropagation()}      
      >             
        <div
          className="box-bg ios-popconfirm"
        >
          <div className="box_list_item">
            <div >
              <div className="event-image-container">
                {!this.state.collapsible ? (
                  <img
                    className="thumb-img"
                    src={
                      location.listImage || '../../../assets/img/user-img.png'
                    }
                    alt=""
                  />
                ) : (
                  <img
                    className="thumb-img"
                    src={
                      location.listImage || '../../../assets/img/user-img.png'
                    }
                    alt=""
                  />
                )}
              </div>
              {!this.state.collapsible ? (
                <div
                  className="date-time-box-event"   
                >
                  <div className="d-flex-box"></div>
                  <div className="d-flex-box main-title">
                    {this.state.collapsible ? (
                      <h3>{location.name || 'NA'}</h3>
                    ) : (
                      <h4>{location.name || 'NA'}</h4>
                    )}
                    <div className='imgDiv'
                      onClick={(e) => {
                        fetchLocationDetails(location, this.state.selectedDate, location.myBooking_start_date, location.myBooking_end_date)
                        this.handleCollaps()
                      }}
                    >
                    <img
                      // src="../../../assets/img/clock-icon.png"
                      src={!this.state.collapsible ? downArrow : upArrow}
                      width="27"
                      height="27"
                      alt=""                    
                    />
                    </div>
                  </div>
                </div>
              ) : (
                <div
                    className="date-time-box-event"
                >
                  <div className="d-flex-box"></div>
                  <div className="d-flex-box main-title">
                    {this.state.collapsible ? (
                      <h3>{location.name || 'NA'}</h3>
                    ) : (
                      <h4>{location.name || 'NA'}</h4>
                    )}
                     <div className='imgDiv'   
                        onClick={() => {
                          this.handleCollaps()
                          myState && myState.selectedLocation && redefineCurrentBookingSlots(myState.selectedLocation, index)
                        }}
                        >
                    <img
                      src={!this.state.collapsible ? downArrow : upArrow}
                      // src="../../../assets/img/clock-icon.png"
                      width="27"
                      height="27"
                      alt=""                      
                    />
                    </div>
                  </div>
                </div>
              )}
            </div>
            {this.state.collapsible ? (
              <div
                className="box_description"
                style={{
                  display: `${this.state.collapsible ? 'block' : 'none'}`,
                }}
              >
                {myState && myState.description && myState.description.length && (
                  <div className="col-desc ">
                    {isLessOn ? (
                      <pre
                        className="desc-class"
                      >
                        {betterDescription(myState.description)}
                    <span
                      className="more_less-btn"
                          onClick={(e) => {this.toggleDesc(); e.stopPropagation()}}
                        >less </span>
                      </pre>
                    ) : (
                      <pre
                        className="desc-class"
                      >
                          {myState.description.substring(0, 100)}<span
                            className="more_less-btn"
                            onClick={(e) => {this.toggleDesc(); e.stopPropagation()}}
                          > ...more </span>
                    </pre>
                    )}
                  </div>
                )
                }

                {/* {myState.calendarOpen ? (
                    <Calendar
                      onChange={() => this.onCalendarChange()}
                      value={this.state.calendarDate}
                    />
                  ) : null} */}
                <div className="calenday-wrap">
                  <div className="date-time-box">
                    {myState && (myState.newchangedDate || myState.myBooking_start_date) ? (
                      <div className="event-date">
                        <button
                          className={`${(myBookingData || this.state.blurBack)&& "blur"} modal-arrow`}
                          onClick={() => {
                            let day = moment(this.state.selectedDate).subtract(1, 'days').format()
                            if(this.state.blurBack){
                              return
                            }
                            !myBookingData && this.setState({
                              selectedDate: new Date(day)
                            })
                            !myBookingData && refetchData(location._id, day)

                         //   leftArrowClick(location._id)
                            // onCalendarChange()
                          }}
                        >
                          <img
                            src="assets/img/left_arrow_blue.svg"
                            alt="Close"
                          />
                        </button>

                        <big style={{position: "relative"}} >

                          {!myBookingData && <DatePicker
                            value={moment(this.state.selectedDate) || moment(myState.newchangedDate)}
                            selected={this.state.selectedDate}
                            minDate={new Date()}
                            maxDate={addDays(new Date(), 90)}
                            showDisabledMonthNavigation
                            onChange={(e) => {
                              !myBookingData && this.setState({
                                selectedDate: e,
                                //  calandy: !this.state.calandy
                              })
                              !myBookingData && refetchData(location._id, e)

                              //onCalendarChange(e, myState.id)
                            }}
                           // onCalendarClose={() => this.setState({calandy: false})}
                            className="date-picker"
                           // disabled={myBookingData}
                          />}

                          <div className='date-line' onClick={(e) => {
                            e.stopPropagation(); 
                            onDateClick(location._id); 
                            }} >
                            {(myState.newchangedDate || myState.myBooking_start_date)
                            ? moment(this.state.selectedDate || moment(myState.newchangedDate)).format('dddd') +
                              ', ' +
                            moment(this.state.selectedDate || moment(myState.newchangedDate)).format('MMM DD')
                              : null}</div>
                            {/* // moment(myState.calendarDate).format('dddd') + ', ' + moment(myState.calendarDate).format('MMM DD') } */}
                        </big>
                        <button
                          className={`${(myBookingData || this.state.blurFront) && "blur"} modal-arrow`}
                          onClick={() => {
                            let day = moment(this.state.selectedDate).add(1, 'days').format()
                            if(this.state.blurFront){
                              return
                            }
                            !myBookingData && this.setState({
                              selectedDate: new Date(day)
                            })
                            !myBookingData && refetchData(location._id, day)
                            // rightArrowClick(location._id)
                            // onCalendarChange()
                          }
                          }
                        >
                          <img
                            src="assets/img/right_arrow_blue.svg"
                            alt="Close"
                          />
                        </button>   
                      </div>
                    ) : null}
                  </div>

                  <div className="d-flex-box  event-instructor ">
                    <div className="event-atten">
                      {myState && this.state.newData.length ? this.state.newData.map((slot, index) => {
                        let isSlotBooked = false
                        let isSlotBookedByMe = false
                        let slotStartTime = moment(slot.dateTime)
                        let slotEndTime = moment(slot.dateTime).add(
                          30,
                          'minutes',
                        )
                        //---------------------------------------

                        //---------------------------------------
                        // if (index < slotsToBeRendered.length / 2) {
                        //   return "";
                        // }
                        //---------------------------------------
                        var bookedSlotClassName = getBookedClassName(
                          slot.isSlotBookedByMe,
                          index,
                          this.state.newData,
                          slot,
                        )
                        // isSlotBooked = slot.bookingId ? true : false
                        // isSlotBookedByMe = slot && slot.booking && slot.booking.user._id === UserProfile.user_id();
                        //---------------------------------------
                        var availableSlotclassName = 'slot-available time-btn'
                        var availableSlotDisabled = false
                        if (
                          slotStartTime.isBefore(
                            // timeConvert(
                              moment().format('YYYY-MM-DDTHH:mm:ssZ'),
                            // ),
                          )
                        ) {
                          availableSlotDisabled = true
                          availableSlotclassName = 'slot-unavailable time-btn'
                        } else {
                          if (slot.isSelectedForBooking) {
                            availableSlotclassName =
                              slot.slotPrebookingClassName
                          }
                        }
                        return slot.isSlotBooked ? (
                          slot.isSlotBookedByMe ? (
                            <div
                              type="submit"
                              className={bookedSlotClassName}
                              onClick={() =>
                                {openBookingDetailView(
                                  slot,
                                  myState.selectedLocation,
                                )}}
                            >
                              {/* {formatAMPM(slot.displayTime.toString())} */}
                              {slot.displayTime.toString()}
                            </div>
                          ) : (
                              <div
                              type="submit"
                              className="slot-unavailable time-btn"
                                onClick={() => {
                                  this.unAvailPopup(slot, myState)
                                }}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center"
                                }}
                          // disabled={true}
                            >
                              {/* {timeConvert(slot.displayTime.toString())} */}
                              {slot.displayTime.toString()}
                              </div>
                          )
                        ) : (
                          <div
                            type="submit"
                            className={`${availableSlotclassName}`}
                          //  disabled={availableSlotDisabled}
                              onClick={(e) => {
                                e.stopPropagation();
                                !myBookingData && !availableSlotDisabled &&  redefineCurrentBookingSlots(
                                slot,
                                myState.selectedLocation,
                                index,
                              )
                              myBookingData &&  this.unAvailPopup(slot, myState)
                            }}
                          >
                            {slot.displayTime.toString()}
                            {/* {formatAMPM(slot.displayTime.toString())} */}
                          </div>
                        )
                      }):                     
                      <div 
                      className="col-desc " 
                      style={{
                              textAlign:"center",
                              alignItems:"center",
                              width:"100%",
                              display:"flex",
                              justifyContent:"center"
                            }}
                       >                       
                     <p style={{
                                textAlign:"center", 
                                alignItems:"center" ,
                                width:"100%" ,
                                display:"flex",
                                justifyContent:"center"
                              }} 
                        onClick={() => this.setState({ collapsible: false })}
                    >
                         {[1,2,3,4,5].includes(moment(this.state.selectedDate).weekday()) &&  <CircularProgress/>}
                      </p>
                    </div>
                      }
                    </div>
                  </div>
                  {myState && this.state.newData.filter(

                    ele => ele.isSelectedForBooking === true,
                  ).length !== 0 && !myBookingData ? (
                    <div className="btn-wrap">
                      <button
                        type="button"
                        className="btn btn-primary book-btn"
                          onClick={() =>{
                            if( myState.selectedLocation.building.name=="Chesterbrook")
                            {this.setState({ termsAndConditionBox:true  });return}
                             bookSlots(location._id)
                          } }
                      >
                        BOOK
                      </button>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ) : null}
            {this.state.preBookingFlag && this.state.preBookingModelData && this.state.preBookingModelData.length ? (
              <Modal
              // onRequestClose={() => this.closeBookingDetailView()}
              className="reservation-alert-modal booking-details"
                    isOpen={this.state.preBookingFlag}
                    // onRequestClose={() => this.closeCancelAlertView()}
                    contentLabel="Cancel Alert"
                    id="alert-cancel-modal"
                    style={{
                      margin: "auto",
                      paddingBottom: "10px"
                    }}
                  >
                    <div className="modal-body"
                      style={{position: "relative"}}
                    >
                      <div class="close-icon-card modal-close" onClick={() => {
                        // this.setState({preBookingFlag: false});
                        this.unAvailPopup()
                      }}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg"
                          width="32px"
                          height="32px"
                          fill="#287FDE"
                          class="prefix__bi prefix__bi-x-circle"
                          viewBox="0 0 16 16">
                          <path d="M8 15A7 7 0 118 1a7 7 0 010 14zm0 1A8 8 0 108 0a8 8 0 000 16z">
                          </path>
                          <path d="M4.646 4.646a.5.5 0 01.708 0L8 7.293l2.646-2.647a.5.5 0 01.708.708L8.707 8l2.647 2.646a.5.5 0 01-.708.708L8 8.707l-2.646 2.647a.5.5 0 01-.708-.708L7.293 8 4.646 5.354a.5.5 0 010-.708z">
                          </path>
                        </svg>
                      </div>
                    </div >
                    <div className="prebook-model">
                      {(() => {
                        let TimeArray = []
                        this.state.preBookingModelData.map((data, i) => {
                          let duration = `${moment(data.startDate).format("hh:mm A")} - ${moment(data.endDate).format("hh:mm A")}`
                          if (TimeArray.includes(duration)) {} else {TimeArray.push(duration)}
                          // {data.user.fullName.split[" "][0].charAt(0)}{data.user.fullName.split[" "][1].charAt(0)}
                        })
                        return TimeArray.map((time) => {
                          return ( <>
                              <p className='time-duration'>{time}</p>
                              <p> {
                                this.state.preBookingModelData.map((data, i) => {
                                  console.log("🚀 ~ file: BookingCard.js ~ line 681 ~ BookingCard ~ this.state.preBookingModelData.map ~ data", data)
                                  let duration = `${moment(data.startDate).format("hh:mm A")} - ${moment(data.endDate).format("hh:mm A")}`
                                  if (time == duration) {
                                    return (
                                      <p className="user-name">
                                        <div className="user-logo" 
                                        style={{
                                          backgroundColor: `${this.getRandomColor()}`
                                          }}
                                          >
                                          {data.user && data.user.firstName.charAt(0).toUpperCase() || ""}{data.user && data.user.lastName.charAt(0).toUpperCase() || ""}
                                          </div>
                                          <span className='name-line' title={`${data.user && data.user.firstName} ${data.user && data.user.lastName}`}>
                                          {`${ data.user && data.user.firstName} ${ data.user && data.user.lastName} ${data.user && data.user.email &&  `- ${data.user.email.split("@")[1]}`}`}
                                          </span>
                                      </p>
                                    )}})}
                              </p>
                            </>)})})()}
                    </div>
                
              </Modal>

            ) : ""
            }
            {
             this.state.termsAndConditionBox &&  <IosModel
               handleCloseModel={()=>{this.setState({ termsAndConditionBox:false  });}}
               termsAndCondition = {true}
               handelSubmitModel={() =>
               {
                 this.setState({ termsAndConditionBox: false });
                 bookSlots(location._id)
              }
               }
             />
            }
          </div>
        </div>
      </div>
    )
  }
}

// <div className="location_box_row" key={index} id={location._id} onClick={e => e.stopPropagation()}>
// //initialy commented</div><div className="col-12 col-md-6 col-lg-4 col-xl-3 location_box_row" key={index}>
// <div className="box-bg rooms-card">
//   <div className="box_list_item card-wrap" onClick={this.handleCollaps}>
//     <img
//       className="thumb-img"
//       src={location.listImage || 'assets/img/user-img.png'}
//       alt=""
//       onClick={() => locationCardClick(location)}
//     />
//     <div className="date-time-box">
//       <div className="d-flex-box">
//         {/* <span>
//           {location.costPoint > 1
//             ? location.costPoint + ' points' || ''
//             : location.costPoint + ' point' || ''}
//         </span> */}
//       </div>
//       <div className="d-flex-box main-title">
//         {this.state.collapsible ? (
//           <h3>{location.name || 'NA'}</h3>
//         ) : (
//           <h4>{location.name || 'NA'}</h4>
//         )}
//       </div>
//     </div>
//   </div>
// </div>
// </div>
