const initState = {
  authError: null,
  plan: null,
  loading: false,
  apiSuccess: false,
}

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case 'LOADING':
      return {
        ...state,
        loading: true,
      }
    case 'LOGIN_ERROR':
      console.log('login error')
      return {
        ...state,
        authError: action.err.message,
        loading: false,
      }
    case 'LOGIN_SUCCESS':
      console.log('login success....auth reducer', action.plan)
      return {
        ...state,
        authError: null,
        plan: action.plan,
        loading: false,
        apiSuccess: true,
      }
    case 'SIGNOUT_SUCCESS':
      console.log('signout success')
      return state
    case 'SIGNUP_SUCCESS':
      console.log('signup success')
      return {
        ...state,
        authError: null,
        plan: '-',
        loading: false,
      }
    case 'SIGNUP_ERROR':
      console.log('signup error')
      return {
        ...state,
        authError: action.err.message,
        loading: false,
      }
    case 'PLAN_SET':
      console.log('PLAN SET')
      return {
        ...state,
        authError: null,
        plan: action.plan,
      }
    case 'EMAIL_SET':
      console.log('EMAIL SET')
      return {
        ...state,
        authError: null,
        plan: 'success',
      }
    default:
      return state
  }
}

export default authReducer
