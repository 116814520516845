import config from '../config/config';

export default async () => {
  let res = await fetch(`${config.api_url}/api/v1/locations/roomlist`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Authorization: localStorage.getItem('accessToken'),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      startTime: '',
      endTime: '',
      start_date: "2022-02-15T00:00:00.000-0500",
      end_date: "2022-02-15T23:59:59.000-0500",
      isBookable :true,
    }),
  })
  let [settledRequest] = await Promise.allSettled([res.json()])
  return settledRequest
}
