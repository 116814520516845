const backgroundColor = '#768e9c !important'
let l = console.log

export const reactSelectStyle10 = {
  control: (provided, state) => ({
    ...provided,
    background: '#fff',
    borderColor: '#9e9e9e',
    minHeight: '30px',
    height: '30px',
    boxShadow: state.isFocused ? null : null,
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    height: '30px',
    padding: '0 6px',
  }),

  input: (provided, state) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: state => ({
    display: 'none',
  }),

  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '30px',
  }),
}
const width = '300px'

export const reactSelectStyle3 = {
  control: base => ({
    ...base,
    boxShadow: base.boxShadow ? '0 0 0 0.25rem rgb(50 31 219 / 25%)' : null,
    // I am overriding the default boxShadow from react-select to my own according to the desirale theme.
    width,
    margin: '20px',
    borderRadius: '8px',
  }),
  menu: base => ({
    ...base,
    zIndex: '5',
    width,
    left: '23px',
    top: '71%',
  }),
}

// with shagun-original.// this is in use!!
let height = '30px'
export const reactSelectStyle1 = {
  control: base => ({
    ...base,
    height,
    minHeight: height,
    backgroundColor,
    boxShadow: null,
    borderColor: 'white',
    '&:hover': {
      borderColor: 'white',
      // !experimental
      height,
      minHeight: height,
    },
    '&:active': {
      // !experimental
      height,
      minHeight: height,
    },
    '&:focus': {
      // !experimental
      height,
      minHeight: height,
    },
  }),
  valueContainer: (base, state) => {
    l('valueContainerState', state)
    return {
      ...base,
      height,
      // padding: '20px 6px',
      // padding: '0px 6px', //from example of smaller sized react-select.
      padding: '0px', //from example of smaller sized react-select.
      color: 'white',
      backgroundColor: 'none',
      '&:hover': {
        // !experimental
        height,
        padding: '0px',
      },
      '&:focus': {
        // !experimental
        height,
        padding: '0px',
      },
      '&:active': {
        // !experimental
        height,
        padding: '0px',
      },
    }
  },
  input: (provided, state) => ({
    ...provided,
    margin: '0px',
    caretColor: 'transparent',
    borderColor: null,
    color: 'white',
    marginTop: '-8px',
    marginLeft: '5px',
  }),
  indicatorSeparator: state => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height,
    // !experimental
    '&:hover': {
      height,
    },
    '&:focus': {
      height,
    },
    '&:active': {
      height,
    },
  }),
  // ^^^ that much matters only...
  container: base => ({
    ...base,
  }),
  option: base => ({
    ...base,
    backgroundColor,
  }),
  singleValue: (base, state) => {
    l('start-->', base, state)
    return {
      ...base,
      margin: 0,
      // fontSize: 17,
      color: 'white',
      marginLeft: '5px !important',
      // '&:focus': {
      //   // !experimental
      //   padding: 0,
      //   margin: 0,
      // },
    }
  },
  menuList: base => {
    return { ...base, backgroundColor }
  },
  menu: base => {
    return { ...base, marginTop: 0 }
  },
}
