import React, { Component } from 'react'
import { compose } from 'redux'
import 'react-sweet-progress/lib/style.css'
import $ from 'jquery'
import { withRouter } from 'react-router-dom'
import '../dashboard/Rooms/room.css'
import * as firebase from 'firebase'
import 'firebase/firestore'
import './booking.css'
import 'firebase/auth'
import moment from 'moment'
import config from '../../config/config'
import UserProfile from '../auth/UserProfile'
import { Backdrop, CircularProgress, withStyles } from '@material-ui/core'
import Modal from 'react-modal'
import AddToCalendar from 'react-add-to-calendar'
import Calendar from 'react-calendar'
import { connect } from 'react-redux'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-calendar/dist/Calendar.css'
import Close from '../../assets/image/close'
import { FirebaseEvent, firebaseTrackEvent } from '../../config/FirebaseManager'
import BookingCard from './BookingCard'
import ChooseCalender from '../dashboard/Events/ChooseCalender'
import getProfileDetailsLegacy from '../../requests/getProfileDetailsLegacy'
import _ from 'lodash'

// Initialize Firebase
// firebase.initializeApp(config.configFb)
firebase.firestore().settings({ timestampsInSnapshots: true })
firebase.auth()
firebase.analytics()

let event = {
  title: 'Location Name',
  description: 'Description',
  startTime: '',
  endTime: '',
}

let slot = {
  displayTime: '',
  dateTime: '',
  bookingId: '',
  locationId: '',
  location: '',
  description: '',
  bookingStart: '',
  bookingEnd: '',
}

var timeZone = moment().format('Z')
var startTime = moment().format('T00:00:00.000')
var endTime = moment().format('T23:59:59.000')

const styles = theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    // color: '#287fde',
    color: '#287fde',
  },
})
class Bookings extends Component {
  constructor(props) {
    super(props)
    firebaseTrackEvent(FirebaseEvent.rooms_screen, {})
  }

  state = {
    locations: [],
    selectedLocation: null,
    selectedBooking: null,
    loading: false,
    selectedSlot: slot,
    calendarEvent: event,
    slotsToBeRendered: [],
    msgflag: true,
    openPostConfirmationPopUp: false,
    openCancelAlert: false,
    openInviteAlert: false,
    openAddToCalendarPopUp: false,
    openBookedDetails: false,
    openLocationDetails: false,
    description: '',
    isLessOn: true,
    changedDate: '',
    calendarOpen: false,
    sendInvite: false,
    inviteData: '',
    calendarDate: new Date(),
    selectedDateShow: moment(),
    invitationSent: false,
    confirmSendInvite: false,
    startDateTime: moment().format('yyyy-MM-DD') + startTime + timeZone,
    endDateTime: moment().format('yyyy-MM-DD') + endTime + timeZone,
    preBookingFlag: false,
    preBookingModelData: [],
  }

  async componentDidMount() {
    const profileDetailsReq = await getProfileDetailsLegacy()
    if (profileDetailsReq.status === 'fulfilled') {
      const isStatusZero =
        profileDetailsReq.value && profileDetailsReq.value.status === 0
      if (isStatusZero) {
        return // handle status code 0 from backend.
      }
      const data = profileDetailsReq.value.data
      this.props.dispatch({
        type: 'profileDetails/update',
        payload: data,
      })
    }
    if (profileDetailsReq.status === 'rejected') {
      alert('rejected profile details request.')
    }
    if (window.innerWidth >= 655) {
      $(document).ready(function() {
        document.body.classList.add('open-sidebar')

        $('.modal-toggle').on('click', function(e) {
          e.preventDefault()
          $('.modal').toggleClass('is-visible')
        })
      })
    } else {
      $(document).ready(function() {
        document.body.classList.remove('open-sidebar')

        $('.modal-toggle').on('click', function(e) {
          e.preventDefault()
          $('.modal').toggleClass('is-visible')
        })
      })
    }

    this.fetchLocations()
  }

  componentDidUpdate = (preProps, PreState) => {
    const { bookingData } = this.props
    if (
      bookingData &&
      preProps.bookingData.length !== this.props.bookingData.length
    ) {
      this.fetchLocations()
    }

    if (this.props.profileDetails.isProfileDetailsLoaded) {
      if (!this.props.profileDetails.activeBuilding.showRoomsTab) {
        this.props.history.push('/')
      }
    }
    if (
      preProps.profileDetails.activeBuilding &&
      this.props.profileDetails.activeBuilding.name !==
        preProps.profileDetails.activeBuilding.name
    ) {
      this.fetchLocations()
    }
  }
  /*****   open calendar on big date         ***************/

  onDateClick = id => {
    this.setState({ calendarOpen: !this.state.calendarOpen })
    this.findIndex(id, 'calendarOpen', 'togle')
  }

  findIndex = (id, tag, value, type) => {
    let data = this.state.cardDataArray
    let indx = data.findIndex(data => data.id == id)
    data[indx][tag] =
      value == 'togle' ? !data[indx][tag] : type == 'array' ? [...value] : value
    this.setState({
      cardDataArray: data,
    })
  }

  /*************** Hide/Show Loader ***************/
  hideLoader = () => {
    this.setState({ loading: false })
  }

  showLoader = () => {
    this.setState({ loading: true })
  }
  /** ******************************************* **/

  /*************** APIs Start ***************/
  fetchLocations = async () => {
    this.showLoader()
    await fetch(config.api_url + '/api/v1/locations/roomlist', {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('accessToken'),
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify({
        // end_date: this.state.endDateTime,
        // start_date: this.state.startDateTime,
        start_date: new Date(),
        end_date: new Date(),
        // start_date: '2022-02-15T00:00:00.000-0500',
        // end_date: '2022-02-15T23:59:59.000-0500',
        endTime: '',
        startTime: '',
        isBookable: true,
      }),
    })
      .then(res => res.json())
      .then(data => {
        if (data.code === 200) {
          let filter = this.props.bookingData
            ? this.props.bookingData
                .map(val1 => {
                  let obj = {
                    ...data.data.result.find(val => val._id == val1.id),
                  }
                  let startTime
                  let endTime
                  Object.keys(obj).length &&
                    obj.availableTimes.map(availableTime => {
                      startTime = moment(availableTime.startDate)
                        .add(30, 'minutes')
                        .format('hh:mm A')
                      endTime = moment(availableTime.endDate).format('hh:mm A')
                    })
                  if (Object.keys(obj).length) {
                    var format = 'hh:mm:ss'
                    let togledate = moment(
                      moment(val1.startDate).format('hh:mm:ss'),
                      format,
                    )
                    var beforeTime = moment('00:00:00', 'hh:mm:ss')
                    var afterTime = moment(endTime, 'hh:mm:ss')
                    var togleMybook = moment(val1.startDate)
                    if (
                      togledate.isBetween(beforeTime, afterTime) ||
                      togledate.format('hh:mm:ss') == '12:00:00'
                    ) {
                      togleMybook = moment(val1.startDate).subtract(1, 'days')
                    }
                    obj.myBookingId = val1._id
                    obj.myBooking_start_date = val1.startDate
                    obj.myBooking_end_date = val1.endDate
                    let disFormat = `MM-DD-YYY ${startTime}`
                    obj.displayDate = togleMybook.format()
                    return obj
                  }
                })
                .filter(data => data !== undefined)
            : data.data.result
          this.setState({
            locations: data.data.result,
            loading: false,
            msgflag: false,
            myBookingArray: [...filter],
          })
        } else {
          this.setState({
            locations: [],
            loading: false,
          })

          if (data.code === 400) {
            // if (window.confirm(data.msg + "\nDo you want to logout?")) {
            UserProfile.removeUser()
            this.props.history.push('/signin')
            alert('got it 8')
            // }
          }
        }
      })
      .catch(console.log)
  }

  fetchLocationDetails = (location, togle, startDate, endDate) => {
    // this.showLoader()
    fetch(config.api_url + '/api/v1/locations/details/' + location._id, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('accessToken'),
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify({
        // start_date: '2022-02-15T00:00:00.000-0500',
        // end_date: '2022-02-15T23:59:59.000-0500',
        start_date: startDate ? startDate : this.state.bookingStart, // to render the booked slot properly
        end_date: endDate ? endDate : this.state.bookingEnd,
        // end_date: this.state.endDateTime,
        // start_date: this.state.startDateTime,
        user_id: UserProfile.user_id(),
      }),
    })
      .then(res => res.json())
      .then(data => {
        this.hideLoader()

        if (data && data.name && data.name !== '') {
          let desc = data.description ? data.description : ''
          this.setState({
            selectedLocation: { ...data },
            description: desc,
            loading: false,
            isLessOn: true,
          })
          // var format = 'hh:mm:ss'
          // let togledate = moment(moment(togle).format("HH:mm:ss"), format)

          // var time = moment('01:00:00', format),
          //   beforeTime = moment('00:00:00', format),
          //   afterTime = moment('02:00:00', format);
          // var togleMybook = moment(togle);
          // if (togledate.isBetween(beforeTime, afterTime) || togledate.isSame(beforeTime) || togledate.isSame(afterTime)) {
          //   togleMybook = moment(togle).subtract(1, 'days')
          // }

          this.openLocationDetailView(
            {
              selectedLocation: { ...data },
              description: desc,
              loading: false,
              isLessOn: true,
              id: location._id,
              currentDate: togle || data.pointsRenewDate,
            },
            startDate,
          )
        } else {
          this.setState({
            selectedLocation: null,
            loading: false,
            description: '',
            isLessOn: true,
          })
          this.closeLocationDetailView()
        }
      })
      .catch(console.log)
  }

  refetchData = (id, date) => {
    this.updateLocationDetailsOnDateChange(id, date)
  }

  updateLocationDetailsOnDateChange = (id, date) => {
    let currentDate = moment(date).format('yyyy-MM-DD')
    let startDateTime = currentDate + startTime + timeZone
    let endDateTime = currentDate + endTime + timeZone

    // this.showLoader()
    fetch(config.api_url + '/api/v1/locations/details/' + id, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('accessToken'),
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify({
        // end_date: endDateTime,
        // start_date: startDateTime,
        user_id: UserProfile.user_id(),
      }),
    })
      .then(res => res.json())
      .then(data => {
        this.hideLoader()
        if (data && data.name && data.name !== '') {
          this.setState({
            selectedLocation: data,
            loading: false,
            selectedDateShow: moment(date),
          })
          let slots = this.createSlots(data, date)
          let newdata = this.state.cardDataArray
          let indx = newdata.findIndex(data => data.id == id)
          newdata[indx]['selectedLocation'] = data
          newdata[indx]['slotsToBeRendered'] = slots
          newdata[indx]['bookings'] = data.bookings
          newdata[indx]['availableTimes'] = data.availableTimes
          newdata[indx]['currentDate'] = date
          this.setState({
            slotsToBeRendered: slots,
            cardDataArray: newdata,
            startDateTime: startDateTime,
            endDateTime: endDateTime,
          })
          this.updateCurrentSelectedSlots(id)
        } else {
          this.setState({
            selectedLocation: null,
            slotsToBeRendered: [],
            loading: false,
          })
        }
      })
      .catch(console.log)
  }

  redefineCurrentBookingSlots = (slot, room, index) => {
    let { slotsToBeRendered } = this.state.cardDataArray.find(
      data => data.id == slot.locationId || slot._id,
    )
    let preSelectedSlots = slotsToBeRendered.filter(
      ele => ele.isSelectedForBooking === true,
    )

    if (slot.isSelectedForBooking) {
      // SLOT IS SELECTED, SO SET UNSELECTION LOGIC

      // ALLOW TO UNSELECT ONLY LEFT AND RIGHT SLOTS, NOT MIDDLE SLOTS
      var isPrevSelected = false
      var isNextSelected = false
      if (index < slotsToBeRendered.length - 1) {
        let nextTimeslot = slotsToBeRendered[index + 1]
        if (nextTimeslot.isSelectedForBooking) {
          isNextSelected = true
        }
      }
      if (index > 0) {
        let prevTimeslot = slotsToBeRendered[index - 1]
        if (prevTimeslot.isSelectedForBooking) {
          isPrevSelected = true
        }
      }
      if (!(isPrevSelected && isNextSelected)) {
        var oldSlots = this.state.cardDataArray.find(
          data => data.id == slot.locationId || slot._id,
        ).slotsToBeRendered
        slot.isSelectedForBooking = !slot.isSelectedForBooking
        slot.slotPrebookingClassName = ''
        oldSlots[index] = slot
        this.setState({ slotsToBeRendered: oldSlots })
        this.findIndex(
          slot.locationId || slot._id,
          'slotsToBeRendered',
          oldSlots,
          'array',
        )
        this.updateCurrentSelectedSlots(slot.locationId || slot._id)
      }
    } else {
      // SLOT IS UNSELECTED, SO SET SELECTION LOGIC
      var allowToSelect = false
      if (preSelectedSlots.length === 0) {
        // THERE IS NO PREVIOUS SELECTION, SO ALLOW
        allowToSelect = true
      } else {
        // THERE IS PREVIOUS SELECTION, SO ALLOW ONLY PREVIOUS OR NEXT SLOT TO BE GET SELECTED
        if (index < slotsToBeRendered.length - 1) {
          let nextTimeslot = slotsToBeRendered[index + 1]
          if (nextTimeslot.isSelectedForBooking) {
            allowToSelect = true
          }
        }

        if (index > 0) {
          let prevTimeslot = slotsToBeRendered[index - 1]
          if (prevTimeslot.isSelectedForBooking) {
            allowToSelect = true
          }
        }
      }

      if (allowToSelect) {
        oldSlots = this.state.cardDataArray.find(
          data => data.id == slot.locationId || slot._id,
        ).slotsToBeRendered
        slot.isSelectedForBooking = !slot.isSelectedForBooking
        oldSlots[index] = slot
        this.setState({ slotsToBeRendered: oldSlots })
        this.findIndex(
          slot.locationId || slot._id,
          'slotsToBeRendered',
          oldSlots,
          'array',
        )

        this.updateCurrentSelectedSlots(slot.locationId || slot._id)
      }
    }
  }

  // bookSlots = (slot, room) => {
  bookSlots = id => {
    let room = this.state.cardDataArray.find(data => data.id == id)
    if (room.selectedLocation.isLocationPayable === true) {
      // let room = this.state.selectedLocation
      // console.log('room', room, id)
      // if (this.state.selectedLocation.isLocationPayable === true) {
      window.alert(
        'This room is only bookable in our mobile app. Please download the Merge by Merritt on your mobile device and try again.',
      )
      //this.openPaymentSummaryView();
      return
    }

    let preSelectedSlots = room.slotsToBeRendered.filter(
      ele => ele.isSelectedForBooking === true,
    )

    let slotStartDate = ''
    let slotEndDate = ''
    if (preSelectedSlots.length > 0) {
      slotStartDate = moment(preSelectedSlots[0].startBookingDateTime).format()
      slotEndDate = moment(
        preSelectedSlots[preSelectedSlots.length - 1].startBookingDateTime,
      )
        .add(30, 'minutes')
        .format()
      if (this.props.profileDetails.activeBuilding.name == 'Chesterbrook') {
        // this.setState({ termsAndConditionBox:true  });
        slotEndDate = moment(
          preSelectedSlots[preSelectedSlots.length - 1].startBookingDateTime,
        )
          .add(4, 'hours')
          .format()
      }
    } else {
      return
    }

    // this.setState({ selectedSlot: slot });
    // let slotEndDate = moment(slot.dateTime)
    //   .add(30, "minutes")
    //   .format();

    let component = this
    this.showLoader()
    //apiprod.betterspaces.com/api/v1/locations/book
    https: fetch(config.api_url + '/api/v1/locations/book', {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('accessToken'),
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify({
        author: UserProfile.user_id(),
        user_id: UserProfile.user_id(),
        platform: 'android',
        location_id: room.id || room.locationId,
        // start_date: slot.dateTime,
        start_date: slotStartDate,
        end_date: slotEndDate,
      }), // body data type must match "Content-Type" header
    })
      .then(res => res.json())
      .then(data => {
        component.hideLoader()

        if (data['code']) {
          if (data.code !== 200) window.alert(data.error)
        } else {
          let roomDetails = {
            room_id: this.state.selectedSlot.locationId,
            room_name: this.state.selectedSlot.location,
          }
          firebaseTrackEvent(FirebaseEvent.unbook_room, roomDetails)
          if (data && data.location && data.location.name !== '') {
            var desc = ''
            if (data.location.description) {
              desc = data.location.description

              desc = desc.substring(0, 100) + '...'
            }

            this.setState({
              selectedLocation: data.location,
              description: desc,
              loading: false,
            })
            let date = this.state.cardDataArray.find(data => data.id == id)
              .currentDate
            this.findIndex(id, 'currentDate', date)
            this.openPostConfirmationView(data.location, date)
            this.fetchLocationDetails(this.state.selectedLocation, date)
          } else {
            this.setState({
              // selectedLocation: null,
              loading: false,
              description: '',
            })
          }
          // this.closePreBookingDetailView()
        }
      })
      .catch(console.log)
  }

  unBookSlots = () => {
    this.closeCancelAlertView()
    let component = this
    this.showLoader()
    fetch(config.api_url + '/api/v1/locations/unbook', {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('accessToken'),
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify({
        author: UserProfile.user_id(),
        user_id: UserProfile.user_id(),
        platform: 'android',
        location_id: this.state.selectedSlot.locationId,
        start_date: this.state.selectedSlot.startBookingDateTime,
        end_date: this.state.selectedSlot.endBookingDateTime,
      }), // body data type must match "Content-Type" header
    })
      .then(res => res.json())
      .then(data => {
        component.hideLoader()

        if (data['code']) {
          if (data.code !== 200) window.alert(data.error)
        } else {
          let roomDetails = {
            room_id: this.state.selectedSlot.locationId,
            room_name: this.state.selectedSlot.location,
          }
          firebaseTrackEvent(FirebaseEvent.unbook_room, roomDetails)
          this.closeBookingDetailView()
          let date = this.state.cardDataArray.find(
            data => data.id == this.state.selectedSlot.locationId,
          ).currentDate
          this.findIndex(
            this.state.selectedSlot.locationId,
            'currentDate',
            date,
          )
          this.fetchLocationDetails(this.state.selectedLocation, date)

          let filter =
            this.state.myBookingArray.length &&
            this.state.myBookingArray.filter(
              data => data.myBookingId !== this.state.selectedSlot.bookingId,
            )
          this.props.removeCard &&
            this.props.removeCard(this.state.selectedSlot.bookingId, 'booking')
          filter.length && this.setState({ myBookingArray: filter })
          if (data && data.location && data.location.name !== '') {
            var desc = ''
            if (data.location.description) {
              desc = data.location.description
              desc = desc.substring(0, 100) + '...'
            }

            this.setState({
              selectedLocation: data.location,
              description: desc,
              loading: false,
            })
          } else {
            this.setState({
              selectedLocation: null,
              loading: false,
              description: '',
            })
          }
        }
      })
      .catch(console.log)
  }

  invitePeople = () => {
    let component = this
    this.showLoader()
    //apiprod.betterspaces.com/api/v1/locations/book
    https: fetch(config.api_url + '/api/v1/locations/addRoomInvitee', {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('accessToken'),
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify({
        booking_id: this.state.selectedSlot.bookingId,
        // email: [this.props.profileDetails.email],
        email: [this.state.inviteData],
        // start_date: slot.dateTime,
        // start_date: "2022-02-15T00:00:00.000-0500",
        // end_date: "2022-02-15T23:59:59.000-0500",
        // start_date: slotStartDate,
        // end_date: slotEndDate,
      }), // body data type must match "Content-Type" header
    })
      .then(res => res.json())
      .then(data => {
        component.hideLoader()

        if (data['code']) {
          if (data.code !== 200) window.alert(data.error)
        } else {
          let roomDetails = {
            room_id: this.state.selectedSlot.locationId,
            room_name: this.state.selectedSlot.location,
          }
          firebaseTrackEvent(FirebaseEvent.unbook_room, roomDetails)
          if (data && data.location && data.location.name !== '') {
            var desc = ''
            if (data.location.description) {
              desc = data.location.description

              desc = desc.substring(0, 100) + '...'
            }

            this.setState({
              selectedLocation: data.location,
              description: desc,
              loading: false,
            })
            this.openPostConfirmationView(data.location)
          } else {
            this.setState({
              // selectedLocation: null,
              loading: false,
              description: '',
            })
          }
          // this.closePreBookingDetailView()
        }
      })
      .catch(console.log)
  }

  /*************** APIs End ***************/

  /*************** Click Events Start ***************/
  locationCardClick = location => {
    //  this.fetchLocationDetails(location)
  }
  /*************** Click Events End ***************/

  /*************** Modal State Start ***************/
  openLocationDetailView = (data, date) => {
    let slots = this.createSlots(data.selectedLocation, data.currentDate)
    this.setState({
      openLocationDetails: true,
      slotsToBeRendered: slots,
      changedDate: this.state.selectedDateShow,
    })

    this.setState({
      slotsToBeRendered: this.state.slotsToBeRendered,
    })

    var newCardData = this.state.cardDataArray || []
    const UpdateMyBookingArray = [...this.state.myBookingArray] || []
    UpdateMyBookingArray.map((val, i) => {
      if (
        val.myBookingId ==
        (data.selectedLocation.bookings.length &&
          data.selectedLocation.bookings[0]._id)
      ) {
        UpdateMyBookingArray[i]['slotsToBeRendered'] = [...slots]
        UpdateMyBookingArray[i]['selectedLocation'] = {
          ...data.selectedLocation,
        }
        UpdateMyBookingArray[i]['description'] = data.description
      }
    })

    let conmanObj = {
      slotsToBeRendered: [...this.state.slotsToBeRendered],
      openLocationDetails: true,
      newchangedDate: this.state.selectedDateShow,
      ...data,
      calendarOpen: false,
    }
    if (newCardData.length) {
      let indx = newCardData.findIndex(val => val.id == data.id)
      // if (indx !== -1) {
      //   newCardData[indx] = {...conmanObj}
      // } else {
      //   newCardData.push({...conmanObj})
      // }
      indx !== -1
        ? (newCardData[indx] = { ...conmanObj })
        : newCardData.push({ ...conmanObj })
    } else {
      newCardData.push({
        ...conmanObj,
      })
    }
    this.setState({
      cardDataArray: newCardData,
      myBookingArray: [...UpdateMyBookingArray],
    })
  }
  closeLocationDetailView = () => {
    let selectedDateShow = moment()
    let startDateTime = moment().format('yyyy-MM-DD') + startTime + timeZone
    let endDateTime = moment().format('yyyy-MM-DD') + endTime + timeZone
    this.setState({
      selectedDateShow: selectedDateShow,
      startDateTime: startDateTime,
      endDateTime: endDateTime,
    })

    this.setState({ openLocationDetails: false })
  }

  /***************************/
  /***************************/
  openBookingDetailView = (slot, location) => {
    event.title = location.name
    event.location = location.name
    event.description = location.description
    event.startTime = slot.startBookingDateTime //slot.bookingStart;
    event.endTime = slot.endBookingDateTime //slot.bookingEnd;

    //let newSlot = {...slot}
    // newSlot.bookingEnd = moment(slot.endBookingDateTime).subtract(30, 'minutes').format('hh:mm A',)
    this.setState({
      openBookedDetails: true,
      openLocationDetails: false,
      selectedSlot: slot,
      selectedLocation: location,
      calendarEvent: event,
    })
  }

  closeBookingDetailView = () => {
    this.setState({ openBookedDetails: false, openLocationDetails: true })
  }

  /***************************/
  openPostConfirmationView = data => {
    // let slots = this.createSlots(data)
    // this.setState({ slotsToBeRendered: slots })
    this.setState({
      openPostConfirmationPopUp: true,
      openLocationDetails: false,
    })
  }

  closePostConfirmationView = () => {
    this.setState({
      openPostConfirmationPopUp: false,
      openLocationDetails: true,
      invitationSent: false,
      confirmSendInvite: false,
    })
  }
  /***************************/

  /***************************/

  onCalendarChange = (date, id) => {
    // this.setState({ calendarDate: date })
    // this.findIndex(id, "changedDate", moment(date))
    // // let day = this.state.changedDate.subtract(1, 'days')
    // this.setState({changedDate: moment(date)})
    // // this.setState({ changedDate: day, selectedDateShow: day })
    // this.updateLocationDetailsOnDateChange(this.state.selectedLocation)
    // console.log('data-date-clendar --', moment(date).format('dddd'), moment(date).format('MMM DD'))
  }

  leftArrowClick = () => {
    // let day = this.state.changedDate.subtract(1, 'days')
    // this.setState({ changedDate: day, selectedDateShow: day })
    // this.updateLocationDetailsOnDateChange(this.state.selectedLocation)
  }

  rightArrowClick = () => {
    // let day = this.state.changedDate.add(1, 'days')
    // this.setState({ changedDate: day, selectedDateShow: day })
    // this.updateLocationDetailsOnDateChange(this.state.selectedLocation)
  }
  /***************************/
  openAddToCalendarView = () => {
    this.setState({ openAddToCalendarPopUp: true, openBookedDetails: false })
  }

  closeAddToCalendarView = () => {
    this.setState({ openAddToCalendarPopUp: false, openBookedDetails: true })
  }

  /***************************/
  openCancelAlertView = () => {
    this.setState({ openCancelAlert: true })
  }

  closeCancelAlertView = () => {
    this.setState({ openCancelAlert: false, openBookedDetails: true })
  }

  openInviteAlertView = () => {
    this.setState({ openInviteAlert: true })
  }

  closeInviteAlertView = () => {
    this.setState({
      openInviteAlert: false,
    })
  }

  handleSendInvite = () => {
    this.setState({ sendInvite: true })
  }

  handleInviteData = async e => {
    await this.setState({ inviteData: e.target.value })
  }

  /***************************/
  toggleDesc = () => {
    if (this.state.isLessOn) {
      this.setState({
        description: this.state.selectedLocation.description,
        isLessOn: false,
      })
    } else {
      this.setState({
        description:
          this.state.selectedLocation.description.substring(0, 100) + '...',
        isLessOn: true,
      })
    }
  }

  /***************************/
  createSlots = (data, date) => {
    let slotsToBeRendered = []
    const { selectedLocation } = this.state
    let selectedDateShow = (date && moment(date)) || this.state.selectedDateShow
    let newData = data || this.state.selectedLocation

    newData.availableTimes.map((availableTime, i) => {
      if (availableTime.weekday === selectedDateShow.weekday()) {
        // EARLIER BELOW CONDITION WAS THERE
        let startTime = moment(availableTime.startDate).set({
          year: selectedDateShow.year(),
          month: selectedDateShow.month(),
          date: selectedDateShow.date(),
        })
        let endTime = moment(availableTime.endDate).set({
          year: selectedDateShow.year(),
          month: selectedDateShow.month(),
          date: selectedDateShow.date(),
        })

        if (startTime.isAfter(endTime)) endTime.add(1, 'day')
        while (startTime.isBefore(endTime)) {
          let slot = {
            displayTime: startTime.format('hh:mm A'),
            dateTime: startTime.format(),
            startBookingDateTime: startTime.format(),
            endBookingDateTime: '',
            location: selectedLocation.name,
            locationId: selectedLocation._id,
            description: selectedLocation.description,
            bookingId: '',
            bookingStart: '',
            bookingEnd: '',
            booking: null,
            toBeEdited: true,
            isSelectedForBooking: false,
          }
          slotsToBeRendered.push(slot)
          if (data.building.name == 'Chesterbrook') {
            startTime.add(5, 'hours')
          } else {
            startTime.add(30, 'minutes')
          }
        }
      }
    })

    // slotsToBeRendered.map((slot) => {
    //   slotsToBeRendered.push(slot);
    // });

    // return slotsToBeRendered.filter(
    //   (_, i) => i > slotsToBeRendered.length / 2
    return slotsToBeRendered
    // );
  }

  /***************************/
  updateCurrentSelectedSlots = id => {
    // START "selected-date-start-prebooking time-btn"
    // END "selected-date-end-prebooking time-btn"
    // SINGLE "selected-date-single-prebooking time-btn"
    // MIDDLE "selected-date-middle-prebooking time-btn"
    const { slotsToBeRendered } = this.state.cardDataArray.find(
      data => data.id == id,
    )
    if (slotsToBeRendered.length) {
      for (const [index, timeslot] of slotsToBeRendered.entries()) {
        var newTimeSlot = timeslot
        let status = timeslot.isSelectedForBooking
        if (status) {
          newTimeSlot.slotPrebookingClassName =
            'selected-date-single-prebooking time-btn' // FOR BOOKED CLASS SLOT THAT IS FIRST
          var isLeftLinked = false
          var isRightLinked = false
          if (index < slotsToBeRendered.length - 1) {
            let nextSlot = slotsToBeRendered[index + 1]
            if (nextSlot && nextSlot.isSelectedForBooking) {
              isRightLinked = true
              newTimeSlot.slotPrebookingClassName =
                'selected-date-start-prebooking time-btn' // FOR BOOKED CLASS SLOT THAT IS FIRST
            }
          }
          if (index > 0) {
            let prevSlot = slotsToBeRendered[index - 1]
            if (prevSlot) {
              if (prevSlot.isSelectedForBooking) {
                isLeftLinked = true
                newTimeSlot.slotPrebookingClassName =
                  'selected-date-end-prebooking time-btn' // FOR BOOKED CLASS SLOT THAT IS LAST
              } else {
              }
            }
          }
          if (isLeftLinked && isRightLinked) {
            newTimeSlot.slotPrebookingClassName =
              'selected-date-middle-prebooking time-btn' // FOR BOOKED CLASS FALLING IN MIDDLE
          } else if (isLeftLinked && !isRightLinked) {
            newTimeSlot.slotPrebookingClassName =
              'selected-date-end-prebooking time-btn' // FOR BOOKED CLASS SLOT THAT IS LAST
          } else if (!isLeftLinked && isRightLinked) {
            newTimeSlot.slotPrebookingClassName =
              'selected-date-start-prebooking time-btn' // FOR BOOKED CLASS SLOT THAT IS FIRST
          }
        }
      }
    }
  }

  /***************************/
  getBookedClassName = (isSlotBookedByMe, index, bookedSlots, slot) => {
    var bookedSlotClassName = 'selected-date-single time-btn' // FOR SINGLE BOOKED CLASS SLOT
    if (isSlotBookedByMe) {
      var isLeftLinked = false
      var isRightLinked = false
      if (index < bookedSlots.length - 1) {
        const nextSlot = bookedSlots[index + 1]
        if (
          nextSlot &&
          nextSlot.bookingId &&
          nextSlot.bookingId === slot.booking._id
        ) {
          isRightLinked = true
          bookedSlotClassName = 'selected-date-start time-btn' // FOR BOOKED CLASS SLOT THAT IS FIRST
        }
      }
      if (index > 0) {
        let decIndex = index - 1

        let prevSlot = bookedSlots[decIndex]
        if (prevSlot.booking) {
          if (prevSlot.booking._id === slot.booking._id) {
            isLeftLinked = true
            bookedSlotClassName = 'selected-date-end time-btn' // FOR BOOKED CLASS SLOT THAT IS LAST
          } else {
          }
        }
      }
      if (isLeftLinked && isRightLinked) {
        bookedSlotClassName = 'selected-date-middle time-btn' // FOR BOOKED CLASS FALLING IN MIDDLE
      } else if (isLeftLinked && !isRightLinked) {
        bookedSlotClassName = 'selected-date-end time-btn' // FOR BOOKED CLASS SLOT THAT IS LAST
      } else if (!isLeftLinked && isRightLinked) {
        bookedSlotClassName = 'selected-date-start time-btn' // FOR BOOKED CLASS SLOT THAT IS FIRST
      }
    }

    return bookedSlotClassName
  }
  /*************** Modal State End ***************/

  // sendInviteUI = () => {
  //   this.setState({
  //     sendInvite: !this.state.sendInvite,
  //   })
  // }

  handleInviteModal = () => {
    this.setState({
      invitationSent: true,
    })
  }

  confirmSendInvitee = () => {
    this.setState({
      confirmSendInvite: true,
    })
  }

  render() {
    const {
      openPostConfirmationPopUp,

      openCancelAlert,
      openInviteAlert,
      openAddToCalendarPopUp,
      openBookedDetails,
      openLocationDetails,
      selectedLocation,
      selectedSlot,
      description,
      isLessOn,
      invitationSent,
      sendInvite,
      changedDate,
      selectedDateShow,
      confirmSendInvite,
      slotsToBeRendered,
      cardDataArray,
    } = this.state
    return (
      <div style={{ width: '100%' }}>
        {!this.props.bookingData && (
          <Backdrop
            className={this.props.classes.backdrop}
            open={this.state.loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {/************************************************************************************************/}
        {/******************** * MODAL INVITE SENT SUCCESSFULY ********************/}
        {invitationSent ? (
          <Modal
            isOpen={invitationSent}
            // onRequestClose={() => this.closePostConfirmationView()}
            contentLabel="Example Alert"
            className="reservation-alert-modal post-confirmation-modal"
          >
            <div className="post-confirmation" style={{ zIndex: '2' }}>
              <div className="imgbox">
                <img src="assets/img/icon_confirm_tick.svg" alt="Close" />
              </div>
              <h2>Sent Successfully!</h2>
              <button
                className="modal-close"
                onClick={() => this.closePostConfirmationView()}
              >
                <img src="assets/img/close-white-new.svg" alt="Close" />
              </button>
            </div>
          </Modal>
        ) : (
          ''
        )}
        {/* MODAL POST CONFIRMATION START *******************************************************************/}
        {openPostConfirmationPopUp ? (
          <Modal
            isOpen={openPostConfirmationPopUp}
            // onRequestClose={() => this.closePostConfirmationView()}
            contentLabel="Example Alert"
            className="reservation-alert-modal post-confirmation-modal"
          >
            <div className="post-confirmation">
              <div className="imgbox">
                <img src="assets/img/icon_confirm_tick.svg" alt="Close" />
              </div>
              <h2>Confirmed!</h2>
              <button
                className="modal-close"
                onClick={() => {
                  this.closePostConfirmationView()
                }}
              >
                <img src="assets/img/close-white-new.svg" alt="Close" />
              </button>
            </div>
          </Modal>
        ) : (
          ''
        )}
        {/* MODAL POST CONFIRMATINO END *********************************************************************/}

        {/* MODAL LOCTION DETAIL START *******************************************************************/}
        {/* {openLocationDetails ? (
          <Modal
            isOpen={openLocationDetails}
            // onRequestClose={() => this.closeLocationDetailView()}
            contentLabel="Example Alert"
            className="reservation-alert-modal booking-details"
          >
            <div className="location_box_row">
              //<div className="col-12 col-md-6 col-lg-4 col-xl-3 location_box_row">
              <div className="box-bg rooms-card">
                <div className="box_list_item card-wrap">
                  <img
                    className="thumb-img"
                    src={
                      selectedLocation.listImage || 'assets/img/user-img.png'
                    }
                    alt=""
                  />
                  <button
                    className="modal-close"
                    onClick={() => this.closeLocationDetailView()}
                  >
                    <img
                      src="../../assets/img/close-white-new.svg"
                      alt="Close"
                    />
                  </button>
                  //</div><div className="date-time-box">
                    <div className="d-flex-box">
                      <span>
                        {selectedLocation.costPoint > 1
                          ? selectedLocation.costPoint + ' points' || ''
                          : selectedLocation.costPoint + ' point' || ''}
                      </span>
                    </div>
                    <div className="d-flex-box main-title">
                      <h3>{selectedLocation.name || 'NA'}</h3>
                    </div>
                  </div>//
                </div>

                <div className="box_description" style={{ marginTop: '1rem' }}>
                  {selectedLocation.description ? (
                    <div className="col-desc br-btm">
                      <p>{description}</p>
                      //<p
                        className="more_less-btn"
                        onClick={() => this.toggleDesc()}
                      >
                        {isLessOn ? 'more' : 'less'}
                      </p>//
                    </div>
                  ) : (
                    ''
                  )}
                  {this.state.calendarOpen ? (
                    <Calendar
                      onChange={() => this.onCalendarChange()}
                      value={this.state.calendarDate}
                    />
                  ) : null}
                  <div className="calenday-wrap">
                    <div className="date-time-box">
                      <div className="event-date">
                        <button
                          className="modal-arrow"
                          onClick={() => this.leftArrowClick()}
                        >
                          <img
                            src="assets/img/left_arrow_blue.svg"
                            alt="Close"
                          />
                        </button>
                        <big onClick={() => this.onDateClick()}>
                          {changedDate.format('dddd') +
                            ', ' +
                            changedDate.format('MMM DD')}
                        </big>
                        //{this.state.calendarOpen ? (
                            <Calendar
                              onChange={() => this.onCalendarChange()}
                              value={this.state.calendarDate}
                            />
                          ) : null}//
                        <button
                          className="modal-arrow"
                          onClick={() => this.rightArrowClick()}
                        >
                          <img
                            src="assets/img/right_arrow_blue.svg"
                            alt="Close"
                          />
                        </button>
                      </div>
                    </div>
                    <div className="d-flex-box br-btm event-instructor">
                      <div className="event-atten">
                        {slotsToBeRendered.map((slot, index) => {
                          let isSlotBooked = false
                          let isSlotBookedByMe = false
                          let slotStartTime = moment(slot.dateTime)
                          let slotEndTime = moment(slot.dateTime).add(
                            30,
                            'minutes',
                          )
                          //---------------------------------------
                          selectedLocation.bookings
                            ? selectedLocation.bookings.map(
                                (booking, _bookingIndex) => {
                                  let bookingStartTime = moment(
                                    booking.startDate,
                                  )
                                  let bookingEndTime = moment(booking.endDate)

                                  // check whether slot is booked - Need to add condition for continous slotsToBeRendered
                                  if (
                                    !(
                                      slotStartTime.isBefore(
                                        bookingStartTime,
                                      ) || slotEndTime.isAfter(bookingEndTime)
                                    ) &&
                                    !isSlotBooked
                                  ) {
                                    isSlotBooked = true
                                    slot.bookingId = booking._id
                                    slot.bookingStart = bookingStartTime.format(
                                      'hh:mm',
                                    )
                                    slot.startBookingDateTime = bookingStartTime.format()
                                    slot.bookingEnd = bookingEndTime.format(
                                      'hh:mm',
                                    )
                                    slot.endBookingDateTime = bookingEndTime.format()
                                    if (
                                      booking.user._id === UserProfile.user_id()
                                    ) {
                                      isSlotBookedByMe = true
                                    }
                                    slot.booking = booking
                                  }
                                },
                              )
                            : selectedLocation.availableTimes.map(booking => {
                                let bookingStartTime = moment(booking.startDate)
                                let bookingEndTime = moment(booking.endDate)

                                // check whether slot is booked - Need to add condition for continous slotsToBeRendered
                                if (
                                  !(
                                    slotStartTime.isBefore(bookingStartTime) ||
                                    slotEndTime.isAfter(bookingEndTime)
                                  ) &&
                                  !isSlotBooked
                                ) {
                                  isSlotBooked = true
                                  slot.bookingId = booking._id
                                  slot.bookingStart = bookingStartTime.format(
                                    'hh:mm',
                                  )
                                  slot.bookingEnd = bookingEndTime.format(
                                    'hh:mm',
                                  )
                                  if (
                                    booking.user._id === UserProfile.user_id()
                                  ) {
                                    isSlotBookedByMe = true
                                  }
                                }
                              })

                          //---------------------------------------
                          // if (index < slotsToBeRendered.length / 2) {
                          //   return "";
                          // }
                          //---------------------------------------
                          var bookedSlotClassName = this.getBookedClassName(
                            isSlotBookedByMe,
                            index,
                            slotsToBeRendered,
                            slot,
                          )
                          //---------------------------------------
                          console.log(slot.isSelectedForBooking)
                          var availableSlotclassName = 'slot-available time-btn'
                          var availableSlotDisabled = false
                          if (
                            slotStartTime.isBefore(
                              moment().format('YYYY-MM-DDTHH:mm:ssZ'),
                            )
                          ) {
                            availableSlotDisabled = true
                            availableSlotclassName = 'slot-unavailable time-btn'
                          } else {
                            if (slot.isSelectedForBooking) {
                              availableSlotclassName =
                                slot.slotPrebookingClassName
                            }
                          }

                          return isSlotBooked ? (
                            isSlotBookedByMe ? (
                              <button
                                type="submit"
                                className={bookedSlotClassName}
                                onClick={() =>
                                  this.openBookingDetailView(
                                    slot,
                                    selectedLocation,
                                  )
                                }
                              >
                                {slot.displayTime.toString()}
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="slot-unavailable time-btn"
                                disabled={true}
                              >
                                {slot.displayTime.toString()}
                              </button>
                            )
                          ) : (
                            <button
                              type="submit"
                              className={availableSlotclassName}
                              disabled={availableSlotDisabled}
                              onClick={() =>
                                this.redefineCurrentBookingSlots(
                                  slot,
                                  this.state.selectedLocation,
                                  index,
                                )
                              }
                            >
                              {slot.displayTime.toString()}
                            </button>
                          )
                        })}
                      </div>
                    </div>
                    {slotsToBeRendered.filter(
                      ele => ele.isSelectedForBooking === true,
                    ).length !== 0 ? (
                      <div className="btn-wrap">
                        <button
                          type="button"
                          className="btn btn-primary book-btn"
                          onClick={() => this.bookSlots()}
                        >
                          BOOK
                        </button>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        ) : (
          ''
        )} */}
        {/* MODAL LOCTION DETAIL END *********************************************************************/}

        {/* MODAL BOOKED SLOT START **********************************************************************/}
        {openBookedDetails ? (
          <Modal
            isOpen={openBookedDetails}
            // onRequestClose={() => this.closeBookingDetailView()}
            contentLabel="Example Alert"
            className="reservation-alert-modal booking-details"
          >
            <div className="location_box_row">
              <div className="box-bg rooms-card">
                {confirmSendInvite ? (
                  <Modal
                    isOpen={confirmSendInvite}
                    // onRequestClose={() => this.closeCancelAlertView()}
                    contentLabel="Cancel Alert"
                    className="reservation-alert-modal"
                    id="alert-cancel-modal"
                  >
                    <div className="modal-body">
                      <p>Send Invite to {this.state.inviteData}?</p>
                    </div>
                    <div className="modal-footer">
                      <div className="modal-btnbox">
                        <button
                          type="button"
                          className="btn"
                          onClick={() => {
                            this.invitePeople()
                            // this.confirmSendInvitee()
                            this.handleInviteModal()
                          }}
                        >
                          Yes
                        </button>
                        <button
                          type="button"
                          className="btn"
                          onClick={() => {
                            this.closePostConfirmationView()
                            this.setState({ inviteData: '' })
                          }}
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </Modal>
                ) : (
                  ''
                )}
                {openCancelAlert ? (
                  <Modal
                    isOpen={openCancelAlert}
                    // onRequestClose={() => this.closeCancelAlertView()}
                    contentLabel="Cancel Alert"
                    className="reservation-alert-modal"
                    id="alert-cancel-modal"
                  >
                    <div className="modal-body">
                      <h4>Cancel Reservation</h4>
                      <p>Are you sure you want to cancel your Reservation?</p>
                    </div>
                    <div className="modal-footer">
                      <div className="modal-btnbox">
                        <button
                          type="button"
                          className="btn"
                          onClick={() => this.closeCancelAlertView()}
                        >
                          No
                        </button>
                        <button
                          type="button"
                          className="btn"
                          onClick={() => {
                            this.unBookSlots()
                            this.closePostConfirmationView()
                          }}
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  </Modal>
                ) : (
                  ''
                )}
                <div className="box_list_item card-wrap">
                  <img
                    className="thumb-img"
                    src={
                      selectedLocation.listImage || 'assets/img/user-img.png'
                    }
                    alt=""
                    style={{
                      height: 260,
                    }}
                  />
                  <div
                    className="close-icon-card modal-close"
                    onClick={() => this.closeInviteAlertView()}
                  >
                    <Close
                      fill="#287FDE"
                      height="32px"
                      width="32px"
                      onClick={() => this.closeBookingDetailView()}
                    />
                  </div>
                  {/* <button
                    className="modal-close"
                    onClick={() => this.closeBookingDetailView()}
                  >
                    <img src="assets/img/close-white-new.svg" alt="Close" />
                  </button> */}
                </div>
                <div className="box_description">
                  <div className="room-attending-info">
                    <div className="info">
                      <div className="row">
                        {/* <div className="col-4"> */}
                        <div className="col-12">
                          {/* <label>Room</label> */}
                          {/* <h6>{this.state.selectedLocation.name}</h6> */}
                          <h6>Your Reservation</h6>
                        </div>
                      </div>
                    </div>
                    <div className="info">
                      <div className="date-info">
                        <div className="date-wrap">
                          <label>Date</label>
                          <h7>
                            {selectedDateShow.format('ddd') +
                              ', ' +
                              selectedDateShow.format('MMM DD')}
                          </h7>
                        </div>
                        <div className="date-wrap">
                          <label>Time</label>
                          <h7>
                            {/* {this.formatAMPM(new Date(selectedSlot.bookingStart))} -{' '} {this.formatAMPM(new Date(selectedSlot.bookingEnd)) } */}
                            {/* {selectedSlot.bookingStart} -{' '}
                            {selectedSlot.bookingEnd} */}
                            {selectedSlot.bookingStart} -{' '}
                            {selectedSlot.bookingEnd}
                          </h7>
                        </div>
                        <div className="attending-lable">
                          <label>Attending</label>
                          <h7>
                            {selectedSlot &&
                            selectedSlot.booking &&
                            selectedSlot.booking.invitees
                              ? selectedSlot.booking.invitees.length
                              : 0}
                          </h7>
                        </div>
                      </div>
                    </div>
                    <div className="btn-wrap">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => this.openAddToCalendarView()}
                      >
                        {/* <img src="assets/img/plus-white.svg" alt="Close" /> */}
                        Add to calendar
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          this.openInviteAlertView()
                        }}
                      >
                        {/* <img src="assets/img/plus-white.svg" alt="Close" /> */}
                        Send Invite
                      </button>
                      <button
                        type="button"
                        className="btn btn-theme"
                        onClick={() => {
                          this.openCancelAlertView()
                        }}
                      >
                        Cancel Reservation
                      </button>
                      {/* {this.state.sendInvite && (
                        <div className="searchContainer">
                        <i className="fa fa-search searchIcon"></i>
                        <input className="searchBox" type="search" name="search" placeholder="Search..." />
                        
                      </div>
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        ) : (
          ''
        )}
        {/* MODAL BOOKED SLOT END ************************************************************************/}
        {/* ****************************** */}

        {/* MODAL ADD TO CALENDAR START */}
        {openAddToCalendarPopUp ? (
          <ChooseCalender
            handleCloseModel={() => this.closeAddToCalendarView()}
            // handelSubmitModel={this.handleCalenderSubmit}
            eventData={event}
          />
        ) : (
          // <Modal
          //   isOpen={openAddToCalendarPopUp}
          //   onRequestClose={() => this.closeAddToCalendarView()}
          //   contentLabel="Example Alert"
          //   className="reservation-alert-modal add-to-calendar-modal"
          // >
          //   <button
          //     className="modal-close"
          //     onClick={() => this.closeAddToCalendarView()}
          //   >
          //     <img src="assets/img/close-blue.svg" alt="Close" />
          //   </button>
          //   <h2>Add to Calendar</h2>
          //   <div className="btnbox">
          //     <AddToCalendar
          //       event={event}
          //       listItems={items}
          //       buttonTemplate={icon}
          //       buttonLabel={"CHOOSE CALENDAR"}
          //       dropdownClass="drpdown"
          //       buttonClassOpen="drpdown_open"
          //       buttonClassClosed="drpdown_closed"
          //       optionsOpen={true}
          //     />
          //   </div>
          // </Modal>
          ''
        )}
        {/* MODAL ADD TO CALENDAR END *********************************************************************/}
        {/* ****************************** */}

        {/*************  MODAL SEND INVITE UI   ********************** */}

        {openInviteAlert ? (
          <div className="confirm-popup-wraper" style={{ zIndex: '99999' }}>
            <div className="rsvp-chooseCalender-model-wrapper fadeIn invite-alert-modal">
              <div
                className="close-icon-card"
                onClick={() => this.closeInviteAlertView()}
              >
                <Close
                  fill="#287FDE"
                  height="32px"
                  width="32px"
                  onClick={() => {
                    this.closeInviteAlertView()
                    this.setState({ inviteData: '' })
                  }}
                />
              </div>
              <div className="chooseCalender-model">
                <h3 style={{ color: '#747878', marginBottom: '2rem' }}>
                  Please enter email to send invite
                </h3>
                <div className="searchContainer">
                  <img
                    src="assets/img/ic-search.svg"
                    className="searchIcon"
                    alt="Close"
                  />
                  <input
                    className="searchBox"
                    type="search"
                    name="search"
                    value={this.state.inviteData}
                    onChange={this.handleInviteData}
                    placeholder="Enter email address"
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                >
                  {/* <h6
                    id="invite-name"
                    style={{ float: 'left' }}
                    onClick={() => {
                      this.handleSendInvite()
                    }}
                  >
                    Ravina
                  </h6> */}
                  <span
                    style={{
                      float: 'left',
                      fontWeight: '500',
                      fontSize: '1.2rem',
                      cursor: 'pointer',
                    }}
                  >
                    {this.state.inviteData}
                  </span>
                  <button
                    type="button"
                    className={`btn btn-primary`}
                    style={
                      !this.state.inviteData
                        ? {
                            opacity: '0.5',
                          }
                        : {
                            opacity: '1',
                          }
                    }
                    onClick={() => {
                      // this.invitePeople()
                      // this.handleInviteModal()
                      if (this.state.inviteData) {
                        this.closeInviteAlertView()
                        this.confirmSendInvitee()
                      }
                    }}
                    // disabled={!this.state.inviteData ? true : false}
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          // <Modal
          //   isOpen={openInviteAlert}
          //   onRequestClose={() => this.closeInviteAlertView()}
          //   contentLabel="Send Invite"
          //   className="confirm-popup-wrapper"

          // >
          //   <div className="modal-body invite-modal">
          //     <div className="close-icon-card" id="invite-close-icon" onClick={() => this.closeInviteAlertView()} >
          //       <Close fill="#287FDE" height="32px" width="32px" onClick={() => this.closeInviteAlertView()} />
          //     </div>
          //     <div className="searchContainer">
          //       <img src="assets/img/ic-search.svg" className="searchIcon"  alt="Close" />
          //       <input
          //         className="searchBox"
          //         type="search"
          //         name="search"
          //         placeholder="Search..."
          //       />
          //     </div>
          //   </div>
          // </Modal>
          ''
        )}

        {/********* * MODAL SEND INVITE CONFIRM UI *****************/}
        {/* {sendInvite ? (
          <Modal
            isOpen={sendInvite}
            // onRequestClose={() => this.closeCancelAlertView()}
            contentLabel="Cancel Alert"
            className="reservation-alert-modal"
            id="send-invitee-modal"
          >
            <div className="modal-body">
              <p>Send Invite to Ravina</p>
            </div>
            <div className="modal-footer">
              <div className="modal-btnbox">
                <button
                  type="button"
                  className="btn"
                  onClick={() => this.closeCancelAlertView()}
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn"
                  onClick={() => this.unBookSlots()}
                >
                  Yes
                </button>
              </div>
            </div>
          </Modal>
        ) : (
          ''
        )} */}
        {/********MODAL CONFIRM INVITE OR NOT    *********** */}

        {/* MODAL CANCEL ALERT VIEW START *****************************************************************/}

        {/* MODAL CANCEL ALERT VIEW END *******************************************************************/}

        {/* ****************************** */}
        {/* ****************************** */}
        <div
          className={`${
            this.props.bookingData ? 'myBookingClass ' : 'main-wrap '
          } location-layout-wrapper`}
        >
          <div className="breadcrumb-row" />
          <div className="row event-title-box views_row">
            {this.props.bookingData
              ? this.state.myBookingArray &&
                this.state.myBookingArray.map((location, index) => {
                  return (
                    <>
                      {index == 0 &&
                        this.props.bookingData &&
                        !this.state.loading && (
                          <p className="my-booking-header">
                            Room Bookings List
                          </p>
                        )}
                      <BookingCard
                        index={index}
                        location={location}
                        key={location.myBookingId}
                        UserProfile={UserProfile}
                        //   locationCardClick={this.locationCardClick}
                        createSlots={this.createSlots}
                        fetchLocationDetails={this.fetchLocationDetails}
                        fetchLocations={this.fetchLocations}
                        getBookedClassName={this.getBookedClassName}
                        formatAMPM={this.formatAMPM}
                        isLessOn={false}
                        closeTab={this.state.closecardflag}
                        onDateClick={() => {}}
                        openBookingDetailView={this.openBookingDetailView}
                        toggleDesc={this.toggleDesc}
                        redefineCurrentBookingSlots={
                          this.redefineCurrentBookingSlots
                        }
                        myState={{ ...location }}
                        myBookingData={this.props.bookingData}
                      />
                    </>
                  )
                })
              : this.state.locations &&
                this.state.locations.map((location, index) => {
                  let slotsToBe = []
                  return (
                    <div>
                      <BookingCard
                        index={index}
                        location={location}
                        key={location._id}
                        selectedDateShow={selectedDateShow}
                        slotsToBeRendered={slotsToBe}
                        onDateClick={this.onDateClick}
                        UserProfile={UserProfile}
                        locationCardClick={this.locationCardClick}
                        leftArrowClick={this.leftArrowClick}
                        rightArrowClick={this.rightArrowClick}
                        bookSlots={this.bookSlots}
                        onCalendarChange={this.onCalendarChange}
                        createSlots={this.createSlots}
                        fetchLocationDetails={this.fetchLocationDetails}
                        fetchLocations={this.fetchLocations}
                        redefineCurrentBookingSlots={
                          this.redefineCurrentBookingSlots
                        }
                        getBookedClassName={this.getBookedClassName}
                        // timeConvert={this.timeConvert}
                        formatAMPM={this.formatAMPM}
                        isLessOn={false}
                        // openLocationDetailView={this.openLocationDetailView}
                        updateLocationDetailsOnDateChange={
                          this.updateLocationDetailsOnDateChange
                        }
                        unAvailPopup={this.unAvailPopup}
                        preBookingFlag={this.state.preBookingFlag}
                        preBookingModelData={this.state.preBookingModelData}
                        updateCurrentSelectedSlots={
                          this.updateCurrentSelectedSlots
                        }
                        openBookingDetailView={this.openBookingDetailView}
                        toggleDesc={this.toggleDesc}
                        // myState={this.state}
                        // calendarOpen={this.state.calendarOpen}
                        // calendarDate={this.state.calendarDate}
                        refetchData={this.refetchData}
                        myState={
                          cardDataArray &&
                          cardDataArray.length &&
                          cardDataArray.filter(
                            data => data.id == location._id,
                          )[0]
                        }
                        bookingSlot={
                          (cardDataArray &&
                            cardDataArray.length &&
                            cardDataArray.filter(
                              data => data.id == location._id,
                            ).length &&
                            cardDataArray.filter(
                              data => data.id == location._id,
                            )[0].selectedLocation.bookings) ||
                          []
                        }
                        closeCard={this.state.closeCard}
                      />
                    </div>
                  )
                })}
          </div>
          {this.state.locations &&
            this.state.locations.length == 0 &&
            !this.props.bookingData &&
            !this.state.msgflag && (
              <div className="no-found-class">
                NO CONFERENCE ROOMS FOUND FOR BOOKING
              </div>
            )}
        </div>
        {/* ****************************** */}
        {/* ****************************** */}
      </div>
    )
  }
}

// const enhance = compose(withStyles({ withTheme: true }))
// export default enhance(Bookings)

const mapStateToProps = state => {
  return {
    profileDetails: state.profileDetails,
  }
}

const enhance = compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps),
  // firestoreConnect([
  //   { collection: 'posts', orderBy: ['createdAt', 'desc'] },
  //   { collection: 'notifications', limit: 3, orderBy: ['time', 'desc'] },
  // ]),
)

export default enhance(withRouter(Bookings))
