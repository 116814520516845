//// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import '../dashboard/Events/eventlist.css' // !not using this now..
import './amenities.css'
import getAmenities from '../../requests/getAmenities'
import BackdropLoader from '../BackdropLoader'
import AmentyCard from './AmentyCard'
import { connect, useDispatch, useSelector } from 'react-redux'
import $ from 'jquery'
import getProfileDetailsLegacy from '../../requests/getProfileDetailsLegacy'
let l = console.log

function Amenities(props) {
  const history = useHistory()
  l('Amenities-props', props)
  l('amenity-history', history);

  useEffect(() => {
    l('profileDetailsAmenity', props.profileDetails)

    const main = async () => {
      const amenitiesRequest = await getAmenities()
      if (amenitiesRequest.status === 'fulfilled') {
        props.dispatch({
          type: 'amenities/list',
          payload: amenitiesRequest.value.data.result,
        })
        // dispatching profile details to redux now.
        const profileDetailsReq = await getProfileDetailsLegacy()
        if (profileDetailsReq.status === 'fulfilled') {
          const isStatusZero =
            profileDetailsReq.value && profileDetailsReq.value.status === 0
          if (isStatusZero) {
            return // handle status code 0 from backend.
          }
          const data = profileDetailsReq.value.data
          props.dispatch({
            type: 'profileDetails/update',
            payload: data,
          })
        }
        if (profileDetailsReq.status === 'rejected') {
          alert('rejected profile details request.')
        }
      }
      if (amenitiesRequest.status === 'rejected') {
        alert('rejected amenities request..')
      }
    }

    main()

    // ? Fixing the sidebar closing (COPIED TERRIFIC CODE FROM OTHER COMPONENT `EventList.js`).
    if (window.innerWidth >= 655) {
      $(document).ready(function() {
        document.body.classList.add('open-sidebar')

        $('.modal-toggle').on('click', function(e) {
          e.preventDefault()
          $('.modal').toggleClass('is-visible')
        })
      })
    } else {
      $(document).ready(function() {
        document.body.classList.remove('open-sidebar')

        $('.modal-toggle').on('click', function(e) {
          e.preventDefault()
          $('.modal').toggleClass('is-visible')
        })
      })
    }
  }, [])

  // ! todo: i.e., redirect if user doesn't have amenities tab.
  useEffect(() => {
    if (props.profileDetails.isProfileDetailsLoaded) {
      const isNotAmenities = !props.profileDetails.activeBuilding.isAmenities
      if (isNotAmenities) {
        history.push('/')
      }
    }
  })

  const amenitiesElementsArray = props.amenities.amenitiesList.map(amenity => {
    // @ts-ignore
    return <AmentyCard amenity={amenity} />
  })

  const testing = Array(6).fill(amenitiesElementsArray[0])

  const isAmenitiesList = props.amenities.amenitiesList.length !== 0 // ! old bad logic.
  console.log('isAmenitiesList-november', isAmenitiesList)

  const isAmenitiesLoading = props.amenities.amenitiesListLoading
  const isProfileDetailsLoading = !props.profileDetails.isProfileDetailsLoaded
  const isAllLoading = isAmenitiesLoading || isProfileDetailsLoading
  console.log(
    'isAll, isAmenities, isProfile',
    isAllLoading,
    isAmenitiesLoading,
    isProfileDetailsLoading,
  )

  // l(
  //   'isAmenitiesLoading, isProfileDetailsLoading, isAllLoading',
  //   isAmenitiesLoading,
  //   isProfileDetailsLoading,
  //   isAllLoading,
  // )

  l('~sahil - isAmenitiesList', isAmenitiesList)
  l('~sahil88', props)
  return isAllLoading ? (
    BackdropLoader
  ) : (
    <div className="amenty-container main-wrap">
      {isAmenitiesList ? (
        amenitiesElementsArray
      ) : (
        <h2 className="amenities-info">
          No Amenities found for active building.
        </h2>
      )}
      {/* {testing} */}
    </div>
  )
}

const mapStateToProps = state => {
  return { amenities: state.amenities, profileDetails: state.profileDetails }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Amenities)
