import React, { useState } from 'react'
import Close from '../../../assets/image/close'
import { AddCalender } from '../assets/js/healper'
import ICalendarLink from 'react-icalendar-link'

const ChooseCalender = props => {
  const [className, setclassName] = useState('')

  const closeModel = () => {
    setclassName('fadeOut')
    props.handleCloseModel()
  }

  const event = {
    title: props.eventData.title,
    description: props.eventData.description,
    startTime: props.eventData.startTime,
    endTime: props.eventData.endTime,
    location: props.eventData.title,
  }

  console.log('this is  a log in a Calender thing -----> ', props.eventData)

  return (
    <div className="confirm-popup-wraper">
      <div className={`rsvp-chooseCalender-model-wrapper fadeIn ${className}`}>
        <div className="close-icon-card" onClick={closeModel}>
          <Close fill="#287FDE" height="32px" width="32px" />
        </div>
        <div className="chooseCalender-model">
          <h5>Add To Calendar</h5>
          {/* <div
            onClick={() => window.open(AddCalender("icloud", props.eventData))}
          >
            <p>APPLE CALENDAR</p>
          </div> */}
          <ICalendarLink event={event}>
            <div>
              <p>APPLE CALENDAR</p>
            </div>
          </ICalendarLink>
          {/* <a href={AddCalender("icloud", props.eventData)}>
            <p>APPLE CALENDAR</p>
          </a> */}
          <div
            onClick={() => window.open(AddCalender('google', props.eventData))}
          >
            <p>GOOGLE CALENDAR</p>
          </div>
          {/* <a href={AddCalender("google", props.eventData)}>
            <p>GOOGLE CALENDAR</p>
          </a> */}
          <div
            onClick={() =>
              window.open(AddCalender('outlookcom', props.eventData))
            }
          >
            <p>OUTLOOK</p>
          </div>
          {/* <a href={AddCalender("outlookcom", props.eventData)}>
            <p>OUTLOOK</p>
          </a> */}
          {/* <div
            onClick={() => window.open(AddCalender("yahoo", props.eventData))}
          >
            <p>YAHOO</p>
          </div> */}
          {/* <a href={AddCalender("yahoo", props.eventData)}>
            <p>YAHOO</p>
          </a> */}
          {/* <div className="cancle-button-wrapper">
          <button onClick={closeModel}>Cancel</button>
          {/* <button onClick={props.handelSubmitModel}>Confirm</button> \
        </div> */}
        </div>
      </div>
    </div>
  )
}

export default ChooseCalender
