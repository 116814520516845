import Select from 'react-select'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect, withRouter } from 'react-router-dom'
import $ from 'jquery'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import 'react-sweet-progress/lib/style.css'
import { signOut } from '../../store/actions/authActions'
import { withStyles } from '@material-ui/core'
import profile_image from '../../assets/img/ic-user.svg'
import '../dashboard/assets/css/home.css'
import '../dashboard/assets/css/style.css'
import UserProfile from '../auth/UserProfile'
import ApiService from '../../common/ApiService'
import config from '../../config/config'
import { getIsSidebarOpenCb, setIsSidebarOpenCb } from './sideMenuHelper'
import { reactSelectStyle1 } from '../../config/configReactSelect'
import postChangeActiveBuilding from '../../requests/postChangeActiveBuilding'
import getAmenities from '../../requests/getAmenities'
import getBookings from '../../requests/getBookings';
// ? This file is not redundant ~sahil.
const styles = theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#287fde',
  },
})
let l = console.log
class TopHeader extends Component {
  constructor(props) {
    super(props)

    this.wrapperRef = React.createRef()
    // this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this)

    this.state = {
      openProfileDropdown: false,
      loading: false,
      headerTitle: 'Events',
    }
    let isSidebarOpen = window.innerWidth >= 655
    // We are using logic i.e., only show the sidebar when the screen has equal or more than width of 655px, else don't show the sidemenu on first load(i.e., on mobiles).
    localStorage.setItem('isSidebarOpen', String(isSidebarOpen))
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.dropDownClose()
    }

    if (window.Intercom) {
      window.Intercom('hide')
    }
  }

  componentWillReceiveProps(nextProps) {
    switch (nextProps.active) {
      case 'home':
        this.setState({ headerTitle: 'Dashboard' })
        break
      case 'events':
        this.setState({ headerTitle: 'Events' })

        break
      case 'rooms':
        this.setState({ headerTitle: 'Rooms' })
        break
      case 'explore':
        this.setState({ headerTitle: 'Explore' })
      break
      case 'bookings' :
        this.setState({headerTitle: 'Bookings'})
        break;
      default:
        this.setState({ headerTitle: 'Dashboard' })
        break
    }

    // if (window.innerWidth <= 1000) {
    //   this.handleClickOfMenu();
    // }
  }

  handleHamburger = () => {
    // isSidebarOpen
    let isSidebarOpen = getIsSidebarOpenCb()

    if (isSidebarOpen === true) {
      // console.log("if condition is true");
      $(document).ready(function() {
        document.body.classList.remove('open-sidebar')
        setIsSidebarOpenCb(false) //~sahil

        $('.modal-toggle').on('click', function(e) {
          e.preventDefault()
          $('.modal').toggleClass('is-visible')
        })
      })
    } else {
      // console.log("if condition is false");
      $(document).ready(function() {
        document.body.classList.add('open-sidebar')
        setIsSidebarOpenCb(true) //~sahil

        $('.modal-toggle').on('click', function(e) {
          e.preventDefault()
          $('.modal').toggleClass('is-visible')
        })
      })
    }
  }

  /** profile drop down */
  profileDropDownClick = actionType => {
    if (actionType === 'logout') {
      localStorage.removeItem('emailForSignIn')
      localStorage.removeItem('accessToken')
      localStorage.removeItem('role')
      // this.props.history.push("/signin");
      localStorage.removeItem('email')
      UserProfile.removeUser()
      this.props.signOut()
      this.props.history.push('/') // use better app like way of managing logout.
      // window.location.href = config.base_url
      // !temp comment.
    } else if (actionType === 'upload') {
      this.refs.fileUploader.click()
      this.uploadAvatar()
    } else if (actionType === 'deleteIcon') {
      this.deleteAvatar()
    } else this.setState({ openProfileDropdown: false })
  }

  // On file select (from the pop up)
  onFileChange = event => {
    const component = this
    if (event.target.files[0]) {
      let files = event.target.files || event.dataTransfer.files
      if (!files.length) return

      let thumb = event.target.files[0]

      console.log(thumb)
      const formData = new FormData()
      formData.append('avatar', thumb, thumb.name)
      component.uploadAvatar(formData)
    }
  }

  uploadAvatar = formData => {
    this.showLoader()
    this.setState({ openProfileDropdown: false })
    ApiService.uploadAvatar(formData).then(res => {
      console.log(res.data)

      if (res.status === 200) {
        // dispatching the newly fetched profileDetails with new avatar to the redux store.
        this.props.dispatch({
          type: 'profileDetails/update',
          payload: res.data,
        })

        // !fix this ^^ with type...
        UserProfile.setUser(res.data)
      }
      this.hideLoader()
    })
    // ********************************/
  }

  deleteAvatar = formData => {
    this.showLoader()
    this.setState({ openProfileDropdown: false })
    ApiService.deleteAvatar(formData).then(res => {
      console.log(res.data)

      if (res.status === 200) {
        // dispatching the newly fetched profileDetails with new avatar to the redux store.
        this.props.dispatch({
          type: 'profileDetails/update',
          payload: res.data,
        })

        // !fix this ^^ with type...
        UserProfile.setUser(res.data)
      }
      this.hideLoader()
    })
    // ********************************/
  }

  // addDefaultSrc = ev => {
  //   ev.target.src = '../assets/img/user-img.png'
  // }

  showLoader = () => {
    this.setState({ loading: true })
  }

  hideLoader = () => {
    this.setState({ loading: false })
  }

  dropDownClose = () => {
    if (this.state.openProfileDropdown === true) {
      this.setState({ openProfileDropdown: false })
    }
  }

  render() {
    let { openProfileDropdown } = this.state
    const { auth } = this.props

    if (localStorage.getItem('accessToken') === false && !auth.uid) {
      alert('got it 9')
      return <Redirect to="/signin" />
    }
    // console.log("auth uid " + auth.uid);

    let imgURL = UserProfile.image()
    if (imgURL !== '') {
      // profile_image = imgURL
    }
    console.log('test-img-api', imgURL)
    console.log('test-img-local', profile_image)
    console.log("check-active-building-----",  this.props)
    // if (firebase.auth().currentUser && firebase.auth().currentUser.photoURL)
    //   profile_image = firebase.auth().currentUser.photoURL;

    const onChangeReactSelect = async item => {
    // debugger
      const settledRequest = await postChangeActiveBuilding(item.value)
      if (settledRequest.value) {
        // ? Setting profile details i.e., updated one!
        this.props.dispatch({
          type: 'profileDetails/update',
          payload: settledRequest.value,
        })

        const amenitiesRequest = await getAmenities()
        if (amenitiesRequest.status === 'fulfilled') {
          this.props.dispatch({
            type: 'amenities/list',
            payload: amenitiesRequest.value.data.result,
          })
        }
        if (amenitiesRequest.status === 'rejected') {
          alert('rejected..')
        }
        const bookingsRequest = await getBookings()
        if (bookingsRequest.status === 'fulfilled') {
          this.props.dispatch({
            type: 'bookings/list',
            payload: bookingsRequest.value.data.result,
          })
        }
        if (bookingsRequest.status === 'rejected') {
          alert('rejected..')
        }
      }
      if(["614b3099d594b61ae0e66e67"].includes(item.value)){
        this.props.history.push('/events')
      }
    }

    console.log("test-test---->", this.props)

    l('profileDetailsTopHeader', this.props.profileDetails)
    l('profileDetailsImage', this.props.profileDetails.image)

    const profileImage =
      this.props.profileDetails && this.props.profileDetails.image
    console.log('profileImage111', profileImage)

    const profileDetails = this.props.profileDetails
    const activeBuildingName =
      this.props.profileDetails &&
      this.props.profileDetails.activeBuilding &&
      this.props.profileDetails.activeBuilding.name

    let nameChars = ''
    if (this.props.profileDetails.fullName) {
      const firstChar = this.props.profileDetails.fullName.charAt(0)
      const secondChar = this.props.profileDetails.fullName.split(' ')[1][0]

      nameChars = firstChar + secondChar
    }

    return (
      <div>
        <Backdrop
          className={this.props.classes.backdrop}
          open={this.state.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <nav className="navbar navbar-home">
          <div className="navbar-collapse">
            <div className="navbrand-logo">
              <a className="logo" href="/">
                {/* <img src="../assets/img/logo-bs.svg" alt="Logo" /> */}
                {/** << old code */}
                <img src={'../assets/img/logo-bs.svg'} alt="Logo" />
              </a>
              <a
                // href="#"
                className="hamburg-icon"
                onClick={this.handleHamburger}
              >
                <img
                  src="../assets/img/hamburger-icon.png"
                  alt="hamburger-icon"
                />
                {/* <div className="testing">testing</div> */}
              </a>
            </div>

            {/* <div> */}
            {/* <Link to="/" className="navbar-brand"> */}
            {/* {" test "} */}
            {/* <a href='#' className="navbar-brand"> */}
            {/* {this.state.headerTitle} */}
            {/* </a> */}
            {/* </Link> */}
            {/* </div> */}
            <div className="right-side-menu">
              <div
                style={{
                  color: 'black',
                  fontSize: '1.7rem',
                  marginRight: '24px',
                }}
              >
                {activeBuildingName}
              </div>
              <div className="username-dropdown" ref={this.wrapperRef}>
                {this.props.children}
                <a
                  href="#"
                  className="username"
                  onClick={() =>
                    this.setState({ openProfileDropdown: !openProfileDropdown })
                  }
                >
                  {profileImage ? (
                    <img
                      className="profile-pic"
                      // onError={this.addDefaultSrc}
                      // src={profileImage}
                      src={profile_image}
                      // src={profileImage || profile_image}
                      alt="User"
                    />
                  ) : (
                    <div
                      className="profile-pic-characters"
                      // onError={this.addDefaultSrc}
                      alt="User"
                    >
                      <span className="profile-pic-characters--characters">
                        {nameChars}
                      </span>
                    </div>
                  )}
                </a>
                {openProfileDropdown ? (
                  <div className="dropdown-menu-header-bar">
                    <ul>
                      <li className="menu-item">
                        {this.props.profileDetails.fullName}{' '}
                        {' (' + this.props.profileDetails.email + ')'}
                      </li>
                      {/* <li className="menu-item">
                        
                      </li> */}
                      <li>
                        <div
                          style={{
                            padding: '10px 15px',
                            display: 'flex',
                            flexDirection: 'column',
                            fontSize: '17px',
                          }}
                        >
                          <div>Active Building: </div>
                          <div style={{ marginTop: 5 }}>
                            <Select
                              menuPosition="fixed"
                              styles={reactSelectStyle1}
                              onChange={onChangeReactSelect}
                              value={{
                                label: this.props.profileDetails.activeBuilding
                                  .name,
                                value: this.props.profileDetails.activeBuilding
                                  ._id,
                              }}
                              // options={[{ label: 'my-label', value: 'my-value' }]}
                              options={this.props.profileDetails.buildings.map(
                                building => ({
                                  value: building._id,
                                  label: building.name,
                                }),
                              )}
                              placeholder={'Change activeBuilding'}
                            />
                          </div>
                        </div>
                      </li>
                      <li>
                        <a
                          // href="#"
                          onClick={() => this.profileDropDownClick('upload')}
                        >
                          Update Icon
                        </a>
                      </li>
                      <li>
                        <a
                          // href="#"
                          onClick={() =>
                            this.profileDropDownClick('deleteIcon')
                          }
                        >
                          Delete Icon
                        </a>
                      </li>
                      <li>
                        <a
                          // href="#"
                          onClick={() => this.profileDropDownClick('logout')}
                        >
                          Logout
                        </a>
                      </li>
                    </ul>
                    <input
                      type="file"
                      onChange={e => this.onFileChange(e)}
                      ref="fileUploader"
                      style={{ display: 'none' }}
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </nav>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    posts: state.firestore.ordered.posts,
    auth: state.firebase.auth,
    notifications: state.firestore.ordered.notifications,
    profileDetails: state.profileDetails,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    signOut: () => dispatch(signOut()),
    dispatch,
  }
}

const enhance = compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([
    { collection: 'posts', orderBy: ['createdAt', 'desc'] },
    { collection: 'notifications', limit: 3, orderBy: ['time', 'desc'] },
  ]),
)

export default withRouter(enhance(TopHeader))
