import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import getProfileDetails from '../../requests/getProfileDetails'
import UserProfile from '../auth/UserProfile'
import '../dashboard/assets/css/home.css'
import '../dashboard/assets/css/style.css'
import { setIsSidebarOpenCb } from './sideMenuHelper'
import { connect } from 'react-redux'
import onDemandNormal from '../../assets/img/onDemandNormal.svg'
import onDemandSelected from '../../assets/img/onDemandSelected.svg'
import amenities from '../../assets/img/Vector (3).svg'
import eventIcon from '../../assets/img/item2_Events.svg'
import eventActiveIcon from '../../assets/img/item2_Events_selected.svg'
import amenitiesGray from '../../assets/img/Vector (4).svg'
import normalBooking from '../../assets/img/item3_Rooms.svg'
import selectedBooking from '../../assets/img/item3_Rooms_selected.svg'
import myBookings from '../../assets/img/Calender.svg'
import myGrayBookings from '../../assets/img/Calender (1).svg'
import roomBookingNormal from "../../assets/img/calendar.svg"
import cafeIcon from "../../assets/img/cafeIcon.svg"

class SideMenu extends Component {
  constructor(props) {
    super(props)
    // console.log("proogjy......." , props)

    this.state = {
      selectedTab: 'events'
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setMenuActive(nextProps.active)
  }

  async componentDidMount() {
    this.setMenuActive(this.props.active)

    if (window.Intercom) {
      window.Intercom('boot', {
        app_id: 'qvtk5bl8',
        email: UserProfile.email(),
        user_id: UserProfile.user_id(),
        name: UserProfile.user_name(),
      })
    }
  }

  setMenuActive = active => {
    this.setState({
      selectedTab: active,
    });
  }

  render() {
    let building = this.props.profileDetails && this.props.profileDetails.activeBuilding
    let roomBookingFlag = building &&  ["614b3099d594b61ae0e66e67"].includes(building._id)
    // console.log("checking-active--",  this.state);
    // console.log("check-classes---booking-", this.state.classBookings,)
    // console.log("check-classes---explore-ondemand-", this.state.classExplore)
    const isMobile = window.innerWidth < 655
    console.log('~sahil99', this.props)

    console.log('~sahil, this.state.isAmenities', this.state.isAmenities)
    console.log(
      '~sahil, this.state.isAmenities !== null',
      this.state.isAmenities !== null,
    )
    return (
      <div>
        <div className="sidebar-menu">
          {this.props.profileDetails.isProfileDetailsLoaded && (
            <ul>
              <li>
                <a
                  // href="#/"
                  className={this.state.selectedTab == "events" ? "active" : ""}
                  onClick={() => {
                    this.props.history.push('/events')
                    if (isMobile) {
                      document.body.classList.remove('open-sidebar')
                      setIsSidebarOpenCb(false)
                    }
                  }}
                >
                  <span className="icon">
                    <img
                      src={
                        this.state.selectedTab == "events"
                          ? eventActiveIcon
                          : eventIcon
                      }
                      alt=""
                    />
                  </span>
                  <span className="text">Events</span>
                </a>
              </li>

              {building && building.showCafeTab && <li>
                <a
                  // href="#/"
                  className={this.state.selectedTab == "cafe" ? "active" : ""}
                  onClick={() => {
                    window.open(building.detailsUrl, "_blank")
                    if (isMobile) {
                      document.body.classList.remove('open-sidebar')
                      setIsSidebarOpenCb(false)
                    } else {
                      document.body.classList.add('open-sidebar')
                      setIsSidebarOpenCb(true)
                    }
                  }}
                >
                  <span className="icon">
                    <img
                      src={
                        this.state.selectedTab === 'cafe'
                          ? cafeIcon
                          : cafeIcon
                      }
                      alt="bookings..."
                      style={{
                        width: 27,
                        height: 27,
                      }}
                    />
                  </span>
                  <span className="text">Cafe</span>
                </a>
              </li>}

              {building && (building.showRoomsTab|| roomBookingFlag ) && (
                <li>
                  <a
                    // href="#/"
                    className={this.state.selectedTab == "conferenceroom" ? "active" : ""}
                    onClick={() => {
                      roomBookingFlag ?  
                      window.open("https://www.ng1.angusanywhere.com/AA/LogIn/?ReturnUrl=%2fAA%2f", "_blank"):
                      this.props.history.push('/conferenceroom')
                      if (isMobile) {
                        document.body.classList.remove('open-sidebar')
                        setIsSidebarOpenCb(false)
                      } else {
                        document.body.classList.add('open-sidebar')
                        setIsSidebarOpenCb(true)
                      }
                    }}
                  >
                    <span className="icon">
                      <img
                        src={
                          roomBookingFlag ? roomBookingNormal :
                          this.state.selectedTab === 'conferenceroom'
                            ? selectedBooking
                            : normalBooking
                        }
                        style={roomBookingNormal && {
                          width: 27,
                          height: 27,
                        }}
                        alt="bookings..."
                      />
                    </span>
                    <span className="text">Conference Rooms</span>
                  </a>
                </li>
              )}
              {building && building.isAmenities && (
                <li>
                  <a
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    className={this.state.selectedTab == "amenities" ? "active" : ""}
                    onClick={() => {
                      this.props.history.push('/amenities')
                      if (isMobile) {
                        document.body.classList.remove('open-sidebar')
                        setIsSidebarOpenCb(false)
                      }
                    }}
                  >
                    <span className="icon">
                      <img
                        style={{
                          width: 24,
                          height: 30,
                        }}
                        className={
                          this.state.classAmenities === 'active'
                            ? 'amenities-active-icon'
                            : 'amenities-icon'
                        }
                        src={
                          this.state.selectedTab === 'amenities'
                            ? amenitiesGray
                            : amenities
                        }
                        alt=""
                      />
                    </span>
                    <span className="text">Amenities</span>
                  </a>
                </li>
              )}
              <li>
                <a
                  // href="#/"
                  className={this.state.selectedTab == "onDemand" ? "active" : ""}
                  onClick={() => {
                    this.props.history.push('/onDemand')
                    if (isMobile) {
                      document.body.classList.remove('open-sidebar')
                      setIsSidebarOpenCb(false)
                    }
                  }}
                >
                  <span className="icon">
                    <img
                      src={
                        this.state.selectedTab == "onDemand"
                          ? onDemandSelected
                          : onDemandNormal
                      }
                      style={{
                        width: 24,
                        height: 30,
                      }}
                      alt="On demand image here.."
                    />
                  </span>
                  <span className="text">On Demand</span>
                </a>
              </li>
             <li>
                <a style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                  // href="#/"
                  className={this.state.selectedTab=="my-bookings"?"active":""}
                  onClick={() => {
                    this.props.history.push('/my-bookings')
                    if (isMobile) {
                      document.body.classList.remove('open-sidebar')
                      setIsSidebarOpenCb(false)
                    } else {
                      document.body.classList.add('open-sidebar')
                      setIsSidebarOpenCb(true)
                    }
                  }}
                >
                  <span className="icon">
                    <img
                      src={
                        this.state.selectedTab=="my-bookings"
                          ? myGrayBookings
                          : myBookings
                      }
                      style={{
                        width: 24,
                        height: 30,
                      }}
                      alt="my-bookings"
                    />
                  </span>
                  <span className="text">MY BOOKINGS</span>
                </a>
              </li>
            </ul>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    // posts: state.firestore.ordered.posts,
    // auth: state.firebase.auth,
    // notifications: state.firestore.ordered.notifications,
    profileDetails: state.profileDetails,
  }
}

export default connect(
  mapStateToProps,
  // mapDispatchToProps, // !moved logic to EventList file.
)(withRouter(SideMenu)) // ~sahil.

{
  /* <li>
              <a
                href="#/"
                className={this.state.classRooms}
                onClick={() => this.props.history.push("/rooms")}
              >
                <span className="icon">
                  <img src={this.state.iconRooms} alt="" />
                </span>
                <span className="text">Rooms</span>
              </a>
            </li> */
}
