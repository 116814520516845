import React, { Component } from 'react'
// import '@coreui/dist/css/coreui.min.css';
import { CForm, CFormControl, CFormLabel, CButton } from '@coreui/react'
import LogoBar from './LogoBar.js'
import { useAuth0 } from '@auth0/auth0-react'

// const LogoutButton = () => {
//   const { logout } = useAuth0();

//   return (
//     <button onClick={() => logout({ returnTo: window.location.origin })}>
//       Log Out
//     </button>
//   );
// };

// export default LogoutButton;
function AdminLogin() {
  const { loginWithRedirect } = useAuth0()
  return (
    <div className="admin-page login-back-img">
      {LogoBar}
      <div class="admin-page_container">
        <h1 className="admin_heading">Admin Login</h1>
        <CForm className="admin_form">
          <CButton
            color="primary"
            type="submit"
            onClick={() => {
              loginWithRedirect()
            }}
          >
            Login
          </CButton>
        </CForm>
      </div>
    </div>
  )
}

export default AdminLogin
