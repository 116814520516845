const initState = {
    bookingsList: [],
    bookingsListLoading: true, // ! to do to add logic for infinite loading bug.
    bookingsDetails: [],
  }
  
  const bookingsReducer = (state = initState, action) => {
    switch (action.type) {
      case 'bookings/list':
        return {
          ...state,
          bookingsList: action.payload,
          bookingsListLoading: false,
        }
  
      case 'bookings/bookingDetails':
        return {
          ...state,
          bookingsDetails: [...state.bookingsDetails, action.payload],
        }
      default:
        return state
    }
  }
  
  export default bookingsReducer
  