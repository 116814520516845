function getDate(date = null) {
  if (date == null) return false

  let postDate = new Date(date)

  let weekday = new Intl.DateTimeFormat('en', {
    weekday: 'short',
  }).format(postDate)
  let month = new Intl.DateTimeFormat('en', {
    month: 'short',
  }).format(postDate)
  let day = new Intl.DateTimeFormat('en', {
    day: 'numeric',
  }).format(postDate)

  let eventDate = weekday + ', ' + month + ' ' + day

  return eventDate
}

function getTime(time = null) {
  // Thu, Dec 31 && 7:00 pm format

  if (time == null) return false

  let postTime = new Date(time)

  let hour = new Intl.DateTimeFormat('en', {
    hour: 'numeric',
    minute: 'numeric',
  }).format(postTime)

  let eventTime = hour

  return eventTime
}

export { getDate, getTime }
