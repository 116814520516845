import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import $ from 'jquery'
import { Redirect, withRouter } from 'react-router-dom'
import 'react-sweet-progress/lib/style.css'
import Modal from 'react-modal'
import './explore.css'
import Select from 'react-select'

import { Backdrop, CircularProgress, withStyles } from '@material-ui/core'
import ReactPlayer from 'react-player'
import ApiService from '../../../common/ApiService'
import config from '../../../config/config'
import {
  FirebaseEvent,
  firebaseTrackEvent,
} from '../../../config/FirebaseManager'
import { SegmentType } from './ExploreCategories'
import UserProfile from '../../auth/UserProfile'

const styles = theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#287fde',
  },
})

const optionsForTypes = [
  { value: 'video', label: 'Video' },
  { value: 'audio', label: 'Audio' },
  { value: 'pdf', label: 'Pdf' },
  { value: 'web', label: 'Web' },
]

const optionsForAppTypes = [
  { value: '1', label: 'Betterspaces' },
  { value: '2', label: 'b.well' },
  { value: '3', label: 'Merritt' },
  { value: '0', label: 'All' },

  // newly added ~sahil.
  { value: '4', label: 'Premiere' },
  { value: '5', label: 'Commerce' },
  { value: '6', label: 'Center' },
]

class ExploreList extends Component {
  constructor(props) {
    super(props)

    // content_viewopened ****************************
    firebaseTrackEvent(FirebaseEvent.content_viewopened, {})
    // ***************************************

    let role = UserProfile.role() //localStorage.getItem("role");
    let isUserAdmin = false
    if (role !== null && role !== '' && role === 'admin') {
      isUserAdmin = true
    }

    this.state = {
      searchTerm: '',
      isSearchOn: false,
      loading: false,
      isUpdateOn: false,
      isUserAdmin: isUserAdmin,

      exploreContentOrigional: [],
      exploreContentFiltered: [],
      exploreCategories: [],
      exploreInstructors: [],
      explorebuildings: [],

      openPlayerModal: false,
      openAddContentModal: false,

      // playingMedia: false,
      currentContent: null,
      playingURL: '',
      playingTitle: '',
      mediaType: '',

      acceptContentType: 'application/pdf',
      disabledContentInput: 'disabled',

      id: '',
      order: '-1',
      description: '',
      mediaURL: '',
      thumb: null,
      selectedOptionCategory: null,
      selectedOptionInstructor: null,
      selectedOptionType: null,
      selectedOptionAppType: null,
      selectedOptionBuildings: [],
      selectedContent: null,
    }
  }

  /*********** BACK BUTTON CLICK ************/
  backToCategories = () => {
    this.props.history.goBack()
  }

  /*********** MODAL SHOW CONTENT VIEW ************/
  openAddContentView = () => {
    this.setState({ openAddContentModal: true })
  }

  closeAddContentView = () => {
    this.setState({ openAddContentModal: false })
    this.clearForm()
  }
  /** ******************************************* */

  /************* EDIT BUTTON CLICKED *************/
  editButtonClicked = content => {
    console.log(content)

    //description
    this.setState({
      openAddContentModal: true,
      isUpdateOn: true,
      description: content.description,
    })
    //***************************

    //category
    let exploreContentFiltered = this.state.exploreCategories.find(element => {
      return element.value === content.category
    })
    this.handleChangeCategory(exploreContentFiltered)
    //***************************

    //type
    let type = optionsForTypes.find(element => {
      return element.value === content.type
    })
    this.handleChangeType(type)
    //***************************

    //appType
    let appType = optionsForAppTypes.find(element => {
      return element.value === String(content.appBundleId)
    })
    this.handleChangeAppType(appType)
    //***************************

    //Buildings
    let buildings = []
    for (const [, building] of content.buildings.entries()) {
      let fliteredBuilding = this.state.explorebuildings.find(element => {
        return element.value === building
      })
      buildings.push(fliteredBuilding)
    }
    this.handleChangeBuildings(buildings)
    //***************************

    //Instructor
    let instructor = this.state.exploreInstructors.find(element => {
      return element.value === content.instructor._id
    })
    this.handleChangeInstructor(instructor)
    //***************************

    //Thumb, IGNORE
    // let instructor = this.state.exploreInstructors.find((element) => {
    //   return element.value === String(content.instructor._id);
    // });
    // this.handleChangeInstructor(instructor);
    //***************************

    //Content
    this.setState({ mediaURL: content.download })
    //***************************

    //order
    if (content.order !== '') {
      this.setState({ order: content.order })
    }
    //***************************

    //id
    if (content._id !== '') {
      this.setState({ id: content._id })
    }
    //***************************
  }
  /** ******************************************* */

  /************* DELETE BUTTON CLICKED *************/
  deleteButtonClicked = content => {
    console.log(content)

    if (window.confirm('Are you sure you want to delete this?')) {
      this.deleteHandler(content._id)
    }
  }

  deleteHandler(props) {
    console.log(props)

    let accessToken = localStorage.getItem('accessToken')
    console.log('access token: ', accessToken)

    this.showLoader()
    fetch(config.api_url + '/api/v1/users/deleteContent/', {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
        Authorization: accessToken,
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify({ _id: props }),
    })
      .then(res => res.json())
      .then(data => {
        console.log('response delete: ', data)

        if (data.code === 200) {
          this.fetchContentList()
        } else {
          this.hideLoader()
        }
      })
  }

  /** ******************************************* */

  /************* PUBLISH BUTTON CLICKED *************/
  publishButtonClicked = content => {
    let accessToken = localStorage.getItem('accessToken')
    console.log('access token: ', accessToken)

    this.showLoader()
    fetch(config.api_url + '/api/v1/users/publishContent/', {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
        Authorization: accessToken,
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify({ _id: content._id, isPublished: true }),
    })
      .then(res => res.json())
      .then(data => {
        console.log('response publish: ', data)

        if (data.code === 200) {
          this.fetchContentList()
        } else {
          this.hideLoader()
        }
      })
    // ********************************/
  }
  /** ******************************************* */

  async componentDidMount() {
    if (window.innerWidth >= 655) {
      $(document).ready(function() {
        document.body.classList.add('open-sidebar')

        $('.modal-toggle').on('click', function(e) {
          e.preventDefault()
          $('.modal').toggleClass('is-visible')
        })
      })
    } else {
      $(document).ready(function() {
        document.body.classList.remove('open-sidebar')

        $('.modal-toggle').on('click', function(e) {
          e.preventDefault()
          $('.modal').toggleClass('is-visible')
        })
      })
    }

    let accessToken = localStorage.getItem('accessToken')
    console.log('access token: ', accessToken)

    // FETCH Content list ****************/
    this.fetchContentList()
    // ********************************/

    // FETCH Content categories ****************/
    fetch(config.api_url + '/api/v1/users/contentsCategory/', {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
        Authorization: accessToken,
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(), // body data type must match "Content-Type" header
    })
      .then(res => res.json())
      .then(data => {
        if (data.code === 200) {
          this.setState({
            exploreCategories: [],
          })

          console.log('data: ', data)
          let categories = data.data.contentsCategory
          console.log('categories: ', categories)
          categories.forEach(doc => {
            let item = { value: doc._id, label: doc.title }
            this.state.exploreCategories.push(item)
          })
          // ********************************/
          this.setState({
            exploreInstructors: [],
          })

          let instructors = data.data.contentsInstructors
          instructors.forEach(doc => {
            let item = { value: doc._id, label: doc.name }
            this.state.exploreInstructors.push(item)
          })
        }
      })
    // ********************************/

    // FETCH Buildings ****************/
    fetch(config.api_url + '/api/v1/buildings/list', {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
        Authorization: accessToken,
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(), // body data type must match "Content-Type" header
    })
      .then(res => res.json())
      .then(data => {
        this.setState({
          explorebuildings: [],
        })

        let instructors = data
        instructors.forEach(doc => {
          let item = { value: doc._id, label: doc.name }
          this.state.explorebuildings.push(item)
        })
      })
    // ********************************/
  }

  fetchContentList() {
    let accessToken = localStorage.getItem('accessToken')
    console.log('access token: ', accessToken)

    // FETCH Content list ****************/
    this.showLoader()

    fetch(config.api_url + '/api/v1/users/contents/', {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
        Authorization: accessToken,
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(), // body data type must match "Content-Type" header
    })
      .then(res => res.json())
      .then(data => {
        if (data.code === 200) {
          this.setState({
            exploreContentOrigional: data.data.contents,
            exploreContentFiltered: data.data.contents,
          })
        }
        this.hideLoader()

        switch (this.props.history.location.selectedSegment) {
          case SegmentType.category:
            // FILTER CATEGORY
            this.setState({
              exploreContentFiltered: this.state.exploreContentOrigional.filter(
                content =>
                  content.title &&
                  content.category === this.props.history.location.id,
              ),
            })
            break
          case SegmentType.duration:
            // FILTER DURATION
            this.setState({
              exploreContentFiltered: this.state.exploreContentOrigional.filter(
                content =>
                  content.duration &&
                  content.duration === this.props.history.location.id,
              ),
            })
            break
          case SegmentType.instructor:
            // FILTER INSTRUCTOR
            this.setState({
              exploreContentFiltered: this.state.exploreContentOrigional.filter(
                content =>
                  content.instructor &&
                  content.instructor._id === this.props.history.location.id,
              ),
            })
            break
          default:
            break
        }
      })
    // ********************************/
  }

  exploreContent_clicked = content => {
    // console.log(content.type);

    switch (content.type) {
      case 'audio':
        this.openPlayerModalView(content)
        break
      case 'video':
        this.openPlayerModalView(content)
        break
      case 'web':
        window.open(content.download, '_blank')
        break
      case 'pdf':
        window.open(content.download, '_blank')
        break
      default:
        break
    }
  }

  showLoader = () => {
    this.setState({ loading: true })
  }

  hideLoader = () => {
    this.setState({ loading: false })
  }

  /*************** Modal Audio/Video ***************/
  openPlayerModalView = content => {
    this.setState({
      playingURL: content.download,
      playingTitle: content.description,
      openPlayerModal: true,
      mediaType: content.type,
      currentContent: content,
    })

    // media_open ****************************
    let eventDetails = {
      content_name: content.description,
      content_id: content._id,
      content_category: content.title,
    }
    firebaseTrackEvent(FirebaseEvent.media_open, eventDetails)
    // ***************************************
  }

  closePlayerModalView = () => {
    this.setState({ openPlayerModal: false })

    // media_closed ****************************
    let eventDetails = {
      content_name: this.state.currentContent.description,
      content_id: this.state.currentContent._id,
      content_category: this.state.currentContent.title,
    }
    firebaseTrackEvent(FirebaseEvent.media_closed, eventDetails)
    // ***************************************
  }
  /** ******************************************* **/

  /**************** HANDLE SELECTION ****************/
  clearForm() {
    this.setState({
      isUpdateOn: false,
      order: '-1',
      description: '',
      mediaURL: '',
      thumb: null,
      selectedOptionCategory: null,
      selectedOptionInstructor: null,
      selectedOptionType: null,
      selectedOptionAppType: null,
      selectedOptionBuildings: [],
      selectedContent: null,
    })
  }

  // createFile(file, type) {
  //   let reader = new FileReader();
  //   reader.onload = (response) => {
  //     if (type === "thumb") {
  //       this.setState({ thumb: response.target.result });
  //     } else if (type === "content") {
  //       this.setState({ selectedContent: response.target.result });
  //       console.log(this.state.selectedContent);
  //     }
  //   };
  //   reader.readAsDataURL(file);
  // }

  handleChangeThumbnail = selectedThumb => {
    let files = selectedThumb.target.files || selectedThumb.dataTransfer.files
    if (!files.length) return

    // this.createFile(files[0], "thumb");
    this.setState({ thumb: selectedThumb.target.files[0] })
  }

  handleChangeContent = selectedContent => {
    let files =
      selectedContent.target.files || selectedContent.dataTransfer.files
    if (!files.length) return

    // this.createFile(files[0], "content");
    this.setState({
      selectedContent: selectedContent.target.files[0],
      mediaURL: '',
    })
    console.log(`selectedContent:`, selectedContent)
  }

  handleChangeText = text => {
    this.setState({
      [text.target.id]: text.target.value,
    })
  }

  handleChangeCategory = selectedOptionCategory => {
    this.setState({ selectedOptionCategory })
    console.log(`selectedOptionCategory:`, selectedOptionCategory)
  }

  handleChangeInstructor = selectedOptionInstructor => {
    this.setState({ selectedOptionInstructor })
    console.log(`selectedOptionInstructor:`, selectedOptionInstructor)
  }

  handleChangeType = selectedOptionType => {
    this.setState({ selectedOptionType })
    console.log(`selectedOptionType:`, selectedOptionType)

    switch (selectedOptionType.value) {
      case 'video':
        this.setState({
          acceptContentType: 'video/*',
          mediaURL: '',
          selectedContent: null,
          disabledContentInput: '',
        })
        break

      case 'audio':
        this.setState({
          acceptContentType: '.mp3,audio/*',
          mediaURL: '',
          selectedContent: null,
          disabledContentInput: '',
        })
        break

      case 'pdf':
        this.setState({
          acceptContentType: 'application/pdf',
          mediaURL: '',
          selectedContent: null,
          disabledContentInput: '',
        })
        break

      case 'web':
        this.setState({
          // acceptContentType: ".html, .webarchive",
          acceptContentType: '.html',
          mediaURL: '',
          selectedContent: null,
          disabledContentInput: '',
        })
        break

      default:
        break
    }
  }

  handleChangeAppType = selectedOptionAppType => {
    this.setState({ selectedOptionAppType })
    console.log(`selectedOptionAppType:`, selectedOptionAppType)
  }

  handleChangeBuildings = selectedOptionBuildings => {
    this.setState({ selectedOptionBuildings })
    console.log(`selectedOptionBuildings:`, selectedOptionBuildings)
  }

  handleSubmit = e => {
    e.preventDefault()

    var validateURL = true

    if (this.state.selectedOptionCategory === null) {
      alert('Please select category')
      return
    } else if (
      this.state.description === null ||
      this.state.description === ''
    ) {
      alert('Please add description')
      return
    } else if (this.state.selectedOptionType === null) {
      alert('Please select media type')
      return
    } else if (this.state.selectedOptionAppType === null) {
      alert('Please select app type')
      return
    } else if (
      (this.state.mediaURL === null || this.state.mediaURL === '') &&
      this.state.selectedContent === null
    ) {
      alert('Please add content URL or Select content file')
      return
    }

    if (this.state.mediaURL !== null && this.state.mediaURL !== '') {
      validateURL = true
    } else {
      validateURL = false
    }

    //**************** CREATE PARAMETERS ****************************
    const formData = new FormData()
    // Parameter: category as String
    let category = this.state.selectedOptionCategory.value
    if (category !== '') {
      formData.append('category', category)
      console.log('category: ', category)
    }

    // Parameter: description as String
    let description = this.state.description
    if (description !== '') {
      formData.append('description', description)
      console.log('description: ', description)
    }

    // Parameter: appBundleId as String
    let appType = this.state.selectedOptionAppType.value
    if (appType !== '') {
      formData.append('appBundleId', appType)
      console.log('appBundleId: ', appType)
    }

    // Parameter: type as String
    let mediaType = this.state.selectedOptionType.value
    if (mediaType !== '') {
      formData.append('type', mediaType)
      console.log('type: ', mediaType)
    }

    // Parameter: buildings as String (comma seprated multiple building ids)
    let buildings = ''
    if (
      this.state.selectedOptionBuildings !== null &&
      this.state.selectedOptionBuildings !== []
    ) {
      this.state.selectedOptionBuildings &&
        this.state.selectedOptionBuildings.map(building => {
          if (buildings === '') {
            buildings = building.value
          } else {
            buildings += ',' + building.value
          }
          return ''
        })

      if (buildings !== '') {
        formData.append('buildings', buildings)
        console.log('buildings: ', buildings)
      }
    }

    // Parameter: instructor as String
    if (this.state.selectedOptionInstructor !== null) {
      let instructor = this.state.selectedOptionInstructor.value
      if (instructor !== '') {
        formData.append('instructor', instructor)
        console.log('instructor: ', instructor)
      }
    }

    // Parameter: isPublished as Boolean Or _id if update is ON
    // For Update API use _id and for Add API use isPublished
    if (this.state.isUpdateOn) {
      formData.append('_id', this.state.id)
      console.log('_id: ', this.state.id)
    } else {
      let isPublished = false
      formData.append('isPublished', isPublished)
      console.log('isPublished: ', isPublished)
    }

    // Parameter: order as String
    let order = this.state.order // set static -1 value
    formData.append('order', order)
    console.log('order: ', order)

    if (this.state.thumb !== null) {
      formData.append('file', this.state.thumb, this.state.thumb.name)
    }
    // **************************************************************************

    if (validateURL) {
      // STEP : use below API to upload content and thumb(thumb only if avaialbe)
      // API: config.api_url+"/api/v1/users/uploadContent/"

      // Parameter: download as String
      let download = this.state.mediaURL
      formData.append('download', download)
      console.log('download: ', download)

      this.uploadContent(formData)
    } else {
      // STEP 1: getSignedURL, API: config.api_url+"/api/v1/users/getSignedUrl/"
      // STEP 2: uploadMediaToGCloud
      // STEP 3: use below API to upload content and thumb(thumb only if avaialbe)
      // API: config.api_url+"/api/v1/users/uploadContent/"

      let accessToken = localStorage.getItem('accessToken')
      console.log(this.state.selectedContent.name)
      var ext = this.state.selectedContent.name.substr(
        this.state.selectedContent.name.lastIndexOf('.') + 1,
      )
      console.log(ext)

      // Get Signed URL ****************/
      this.showLoader()
      fetch(config.api_url + '/api/v1/users/getSignedUrl/', {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        headers: {
          'Content-Type': 'application/json',
          Authorization: accessToken,
        },
        body: JSON.stringify({ extension: ext }), // body data type must match "Content-Type" header
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      })
        .then(res => res.json())
        .then(data => {
          if (data.code === 200) {
            console.log(data.data.filename)
            console.log(data.data.url)
            let download = config.DOWNLOAD_PRE_URL_BETTERSPACES
            download += data.data.filename
            formData.append('download', download)
            console.log('download: ', download)
            formData.append('filename', data.data.filename)
            console.log('filename: ', data.data.filename)

            ApiService.uploadMediaToGCloud(
              data.data.url,
              mediaType,
              this.state.selectedContent,
            )
              .then(res => {
                console.log(res.data)
                this.uploadContent(formData)

                // alert("File uploaded successfully.");
                // this.fetchContentList();
              })
              .then(
                result => {
                  console.log(result)
                },
                error => {
                  console.log(error)
                },
              )
          } else {
            console.log(data)
            this.hideLoader()
          }
        })
    }
  }

  uploadContent = formData => {
    this.showLoader()
    ApiService.uploadContent(formData).then(res => {
      console.log(res.data)

      if (res.data.code === 200) {
        if (res.data.data.error === '') {
          this.closeAddContentView()
          alert('File uploaded successfully.')
          this.fetchContentList()
        } else if (res.data.data.error !== '') {
          this.hideLoader()
          alert(res.data.data.error)
        } else {
          this.hideLoader()
          alert('Error in uploading.')
        }
      }
    })
    // ********************************/
  }

  // SEARCH Methods
  editSearchTerm = e => {
    this.setState({ searchTerm: e.target.value })

    if (this.state.searchTerm === '') {
      this.setState({
        exploreContentFiltered: this.state.exploreContentOrigional,
      })
    } else {
      let filtered = this.state.exploreContentOrigional.filter(
        content =>
          content.description
            .toLowerCase()
            .includes(this.state.searchTerm.toLowerCase()) ||
          (content.title &&
            content.title
              .toLowerCase()
              .includes(this.state.searchTerm.toLowerCase())),
      )

      this.setState({ exploreContentFiltered: filtered })
    }
  }

  clearSearchTerm = () => {
    this.setState({ searchTerm: '', isSearchOn: false })
    this.setState({
      exploreContentFiltered: this.state.exploreContentOrigional,
    })
  }

  setSearchOn = () => {
    this.setState({ searchTerm: '', isSearchOn: true })
    // this.setState({
    //   exploreContentFiltered: this.state.exploreContentOrigional,
    // });
  }

  isSearchOn
  /** ******************************************* */

  render() {
    const { auth } = this.props
    let {
      isSearchOn,
      loading,
      isUpdateOn,
      isUserAdmin,
      openPlayerModal,
      openAddContentModal,
      selectedOptionCategory,
      selectedOptionInstructor,
      selectedOptionType,
      selectedOptionAppType,
      selectedOptionBuildings,
      exploreContentFiltered,
      exploreCategories,
      description,
      explorebuildings,
      exploreInstructors,
      mediaURL,
      acceptContentType,
      disabledContentInput,
      playingTitle,
      mediaType,
      playingURL,
    } = this.state

    if (localStorage.getItem('accessToken') === false && !auth.uid) {
      console.log(
        '🚀 ~ file: ExploreCategories.js ~ line 287 ~ ExploreCategories ~ render ~ localStorage',
        localStorage.getItem('accessToken'),
      )
      alert('got it 6')
      return <Redirect to="/signin" />
    }

    console.log('auth uid ' + auth.uid)
    return (
      <div>
        <Backdrop className={this.props.classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <div class="explore_main-wrap explore-layout-wrapper">
          {/* add content model code starts */}

          <div class="player-detail-inner" />
          <div class="player-detail">
            <div class="add-player-detail-button">
              <button
                onClick={() => this.backToCategories()}
                type="button"
                class="btn-back"
              >
                <span>
                  <img src="assets/img/prev-arrow-icon.png" alt="" />
                </span>
              </button>
            </div>
            <div class="add-player-detail-button">
              {isUserAdmin ? (
                <button
                  onClick={() => this.openAddContentView()}
                  type="button"
                  class="modal-toggle"
                >
                  <span>
                    <img src="assets/img/ic-plus.svg" alt="" />
                  </span>
                </button>
              ) : (
                ''
              )}

              <Modal
                isOpen={openAddContentModal}
                onRequestClose={() => this.closeAddContentView()}
                contentLabel="Example Modal"
              >
                <div className="modal-body">
                  <button
                    className="modal-close-blue"
                    onClick={() => this.closeAddContentView()}
                  >
                    <img src="../../../assets/img/close-blue.svg" alt="Close" />
                  </button>
                  <h2 className="modal-heading">
                    {isUpdateOn ? 'Edit Content' : 'Create Content'}
                  </h2>
                </div>

                <form onSubmit={this.handleSubmit} className="input-form">
                  {/* **** */}
                  <div className="input-form-group">
                    <Select
                      placeholder="Category"
                      value={selectedOptionCategory}
                      onChange={this.handleChangeCategory}
                      options={exploreCategories}
                    />
                  </div>

                  {/* **** */}
                  <div className="input-form-group">
                    <input
                      type="text"
                      //required
                      id="description"
                      name="description"
                      placeholder="Description.."
                      value={description}
                      onChange={this.handleChangeText}
                      class="form-input"
                    />
                  </div>

                  {/* **** */}
                  <div className="input-form-group">
                    <Select
                      placeholder="Type"
                      value={selectedOptionType}
                      onChange={this.handleChangeType}
                      options={optionsForTypes}
                    />
                  </div>

                  {/* **** */}
                  <div className="input-form-group">
                    <Select
                      placeholder="App Type"
                      value={selectedOptionAppType}
                      onChange={this.handleChangeAppType}
                      options={optionsForAppTypes}
                    />
                  </div>

                  {/* **** */}
                  <div className="input-form-group">
                    <Select
                      closeMenuOnSelect={false}
                      placeholder="Buildings"
                      isMulti
                      value={selectedOptionBuildings}
                      onChange={this.handleChangeBuildings}
                      options={explorebuildings}
                    />
                  </div>

                  {/* **** */}
                  <div className="input-form-group">
                    <Select
                      placeholder="Instructor"
                      value={selectedOptionInstructor}
                      onChange={this.handleChangeInstructor}
                      options={exploreInstructors}
                    />
                  </div>

                  {/* **** */}
                  <div className="input-form-group">
                    Thumbnail
                    <div className="input-form-group">
                      <input
                        input
                        type="file"
                        accept="image/x-png, image/jpeg"
                        // required
                        id="thumbnail"
                        name="thumbnail"
                        placeholder="Thumbnail.."
                        class="form-input"
                        onChange={this.handleChangeThumbnail}
                      />
                    </div>
                  </div>

                  {/* **** */}
                  <div>Content</div>
                  <div className="input-form-group">
                    <input
                      type="text"
                      //required
                      id="mediaURL"
                      name="mediaURL"
                      placeholder="URL.."
                      value={mediaURL}
                      onChange={this.handleChangeText}
                      class="form-input"
                    />
                  </div>

                  <div className="input-form-group-central-div">OR</div>
                  {/* **** */}
                  <div className="input-form-group">
                    <input
                      type="file"
                      accept={acceptContentType}
                      disabled={disabledContentInput}
                      //required
                      id="content"
                      name="content"
                      placeholder="Content.."
                      class="form-input"
                      onChange={this.handleChangeContent}
                    />
                  </div>

                  {/* CREATE/UPDATE Content */}
                  <button type="submit" className="publish-btn">
                    {isUpdateOn ? 'Update' : 'Create'}
                  </button>
                </form>
              </Modal>
            </div>

            {isSearchOn ? (
              <div class="searchbar-input-div">
                <input
                  className="searchbar-input"
                  type="text"
                  value={this.state.searchTerm}
                  onChange={this.editSearchTerm}
                  placeholder="Search with title or description!"
                />
              </div>
            ) : (
              <div class="add-player-detail-button">
                <button
                  onClick={() => this.setSearchOn()}
                  type="button"
                  class="modal-toggle-search"
                >
                  <img src="assets/img/ic-search.svg" alt="Close" />
                </button>
              </div>
            )}

            {isSearchOn ? (
              <div class="add-player-detail-button">
                <button
                  onClick={() => this.clearSearchTerm()}
                  type="button"
                  class="modal-toggle-close"
                >
                  <img src="../../../assets/img/close-blue.svg" alt="Close" />
                  {/* <img src="assets/img/ic-close-black.svg" alt="Close" /> */}
                </button>
              </div>
            ) : (
              ''
            )}

            <div class="search-space-div" />
          </div>

          {/* Player model code starts */}
          <div class="row explore_views_row">
            <Modal
              isOpen={openPlayerModal}
              onRequestClose={this.closePlayerModalView}
              contentLabel="Example Modal"
            >
              <div className="modal-body">
                <button
                  className="modal-close-blue"
                  onClick={this.closePlayerModalView}
                >
                  <img src="../../../assets/img/close-blue.svg" alt="Close" />
                </button>

                <div className="modal-heading">{playingTitle}</div>

                {mediaType === 'video' ? (
                  <div>
                    <ReactPlayer
                      url={playingURL}
                      width="100%"
                      pip={true}
                      ref="vidRef"
                      controls={true}
                      config={{
                        file: { attributes: { controlsList: 'nodownload' } },
                      }}
                    />
                  </div>
                ) : (
                  <div className="modal-header">
                    {/* <ReactAudioPlayer width="100%" src={playingURL} controls /> */}
                    <ReactPlayer
                      url={playingURL}
                      width="100%"
                      height={60}
                      pip={true}
                      ref="vidRef"
                      controls={true}
                      config={{
                        file: { attributes: { controlsList: 'nodownload' } },
                      }}
                    />
                  </div>
                )}
              </div>
            </Modal>

            {exploreContentFiltered &&
              exploreContentFiltered.map(content => {
                let iconURL = ''

                switch (content.type) {
                  case 'audio':
                    iconURL = 'assets/img/audio_icon_white.svg'
                    break
                  case 'video':
                    iconURL = 'assets/img/video_icon_white.svg'
                    break
                  case 'web':
                    iconURL = 'assets/img/web_icon.svg'
                    break
                  case 'pdf':
                    iconURL = 'assets/img/pdf_icon.svg'
                    break
                  default:
                    break
                }

                return (
                  // <div class="col-12 col-md-6 col-xl-3 explore_box_row">
                  <div class=" explore_box_row">
                    <div class="box-bg">
                      <div class="explore_box_list_item">
                        <div
                          class="explore_box_image"
                          onClick={() => this.exploreContent_clicked(content)}
                        >
                          <img
                            class="explore_thumb-img"
                            src={content.image || 'assets/img/placeholder.png'}
                            alt=""
                          />
                          <div class="explore_play_icon">
                            <img src={iconURL} alt="" />
                          </div>
                        </div>
                        <div class="explore_category_text">
                          <div class="explore_d-flex-box">
                            <span class="date">{content.title || 'NA'}</span>
                          </div>
                          <div class="explore_d-flex-box explore_main-title">
                            <h6>{content.description}</h6>
                          </div>

                          {/* EDIT */}
                          {isUserAdmin ? (
                            <div class="explore_d-flex-box-left explore_main-title">
                              {!content.isPublished ? (
                                <button
                                  className="publish-btn"
                                  onClick={() =>
                                    this.publishButtonClicked(content)
                                  }
                                >
                                  Publish
                                </button>
                              ) : (
                                ''
                              )}

                              <button
                                className="publish-btn"
                                onClick={() => this.editButtonClicked(content)}
                              >
                                Edit
                              </button>

                              <button
                                className="publish-btn dlt-btn"
                                onClick={() =>
                                  this.deleteButtonClicked(content)
                                }
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    posts: state.firestore.ordered.posts,
    auth: state.firebase.auth,
    notifications: state.firestore.ordered.notifications,
  }
}

const enhance = compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps),
  firestoreConnect([
    { collection: 'posts', orderBy: ['createdAt', 'desc'] },
    { collection: 'notifications', limit: 3, orderBy: ['time', 'desc'] },
  ]),
)

export default enhance(withRouter(ExploreList))
