import React from 'react'
import { NavLink } from 'react-router-dom'

const SignedOutLinks = () => {
  return (
    <ul className="right">
      <li>
        <NavLink to="/signup">Signup</NavLink>
      </li>
      <li>
        <NavLink to="/signin">Login With Mobile</NavLink>
      </li>
      <li>
        <NavLink to="/signinwemail">Login With Email</NavLink>
      </li>
    </ul>
  )
}

export default SignedOutLinks
