import axios from 'axios'
import UserProfile from '../components/auth/UserProfile'
import config from '../config/config'

class ApiService {
  // publishContent(id) {
  //   let accessToken = localStorage.getItem("accessToken");
  //   console.log(accessToken);
  //   return axios.post(
  //     config.api_url+"/api/v1/users/publishContent/",
  //     {
  //       headers: {
  //         // "content-type": "multipart/form-data",
  //         Authorization: accessToken,
  //       },
  //       body: JSON.stringify({ _id: id, isPublished: true }),
  //     }
  //   );
  // }

  async uploadAvatar(formData) {
    return await axios.post(
      config.api_url + '/api/v1/users/avatar/' + UserProfile.user_id(),
      formData,
      {
        headers: {
          'content-type': 'multipart/form-data',
          //'Authorization': accessToken
        },
        // body: JSON.stringify(params)
      },
    )
  }

  async deleteAvatar() {
    return await axios.delete(
      config.api_url + '/api/v1/users/avatar/' + UserProfile.user_id(),
      // formData,
      // {
      //   headers: {
      //     'content-type': 'multipart/form-data',
      //     //'Authorization': accessToken
      //   },
      //   // body: JSON.stringify(params)
      // },
    )
  }

  async uploadContent(formData) {
    //let accessToken = localStorage.getItem("accessToken");

    //return axios.post(config.api_url+"/api/v1/users/uploadContent/", formData);

    return await axios.post(
      config.api_url + '/api/v1/users/uploadContent/',
      formData,
      {
        headers: {
          'content-type': 'multipart/form-data',
          //'Authorization': accessToken
        },
        // body: JSON.stringify(params)
      },
    )
  }

  uploadMediaToGCloud(signedMediaUrl, mediaType, mediaData) {
    //let accessToken = localStorage.getItem("accessToken");

    return axios.put(signedMediaUrl, mediaData)

    // switch (mediaType) {
    //   case "web":
    //     return axios.put(signedMediaUrl, mediaData, {
    //       headers: {
    //         "content-type": "text/html",
    //         //'Authorization': accessToken
    //       },
    //     });

    //   case "pdf":
    //     return axios.put(signedMediaUrl, mediaData, {
    //       headers: {
    //         "content-type": "application/pdf",
    //         //'Authorization': accessToken
    //       },
    //       body: JSON.stringify({ mediaData }), // body data type must match "Content-Type" header
    //     });

    //   default:
    //     return axios.put(signedMediaUrl, mediaData, {
    //       headers: {
    //         //'Authorization': accessToken
    //       },
    //     });
    // }
  }
}

export default new ApiService()
