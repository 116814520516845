import React, { Component } from 'react'
import { connect } from 'react-redux'
import { signIn } from '../../store/actions/authActions'
import './signin.css'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import termsPdf from '../../common/assets/Better Spaces Terms Of Service.pdf'
import privacyPdf from '../../common/assets/Better Spaces Privacy.pdf'
import config from '../../config/config'
import LinearProgress from '@material-ui/core/LinearProgress'
import { Backdrop, CircularProgress, withStyles } from '@material-ui/core'
import UserProfile from '../auth/UserProfile'
import Select from 'react-select'

const styles = theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#287fde',
  },
})

class UpdateProfile extends Component {
  state = {
    firstName: '',
    lastName: '',
    company: null,
    optionsForCompanies: [],
    uid: '',
    loading: false,
  }

  async componentDidMount() {
    this.fetchCompanies()
  }

  // ***********
  hideLoader = () => {
    this.setState({ loading: false })
  }

  showLoader = () => {
    this.setState({ loading: true })
  }
  // ***********

  handleKeypress = e => {
    //it triggers by pressing the enter key
    if (e.key === 'Enter') {
      // console.log("enter pressed")
      this.onClickNext()
    }
  }

  // ***********
  openTermsPdf = () => {
    window.open(termsPdf)
  }

  openPrivacyPdf = () => {
    window.open(privacyPdf)
  }
  // ***********

  // ***********
  handleChange = e => {
    // console.log(e)
    this.setState({
      [e.target.id]: e.target.value,
    })
  }

  // ***********
  handleChangeCompany = company => {
    this.setState({ company })
    console.log(`company:`, company)
  }

  // ***********
  onClickNext = () => {
    //console.log(this.props);

    const { firstName, lastName, company } = this.state

    if (firstName === '') {
      window.alert('Please enter first name')
    } else if (lastName === '') {
      window.alert('Please enter last name')
    } else if (!company || company === '') {
      window.alert('Please enter company name')
    } else {
      this.UpdateProfileDetails()
    }
  }

  /*************** APIs Start ***************/
  fetchCompanies = () => {
    this.showLoader()

    fetch(config.api_url + '/api/v1/companies/list/', {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
        // Authorization: localStorage.getItem("accessToken"),
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    })
      .then(res => res.json())
      .then(data => {
        // console.log("fetchCompanies ", data);

        if (data.length > 0) {
          var companies = []
          data.map((company, _) => {
            // console.log("company ", company);
            let companyToAdd = { value: company._id, label: company.name }
            companies.push(companyToAdd)
          })

          this.setState({ optionsForCompanies: companies })
        } else {
          this.setState({ optionsForCompanies: [] })
        }

        this.hideLoader()
      })
      .catch(console.log)
  }

  // ***********
  async UpdateProfileDetails() {
    // Parameters:["companies": "5bace78b4346150c2ae949e3", "lastName": "Sadsa", "firstName": "Sdasd"]
    const { firstName, lastName, company } = this.state
    let params = JSON.stringify({
      companies: company.value,
      lastName: lastName,
      firstName: firstName,
    })

    const { auth } = this.props
    const token = localStorage.getItem('preLoginToken')

    this.showLoader()
    fetch(config.api_url + '/api/v1/users/update/' + UserProfile.user_id(), {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
      },
      body: params,
    })
      .then(res => res.json())
      .then(data => {
        //console.log("data ", data);

        this.setState({
          uid: auth.uid,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
        })
        localStorage.setItem('accessToken', token)
        localStorage.removeItem('preLoginToken')
        UserProfile.setUser(data)
        console.log(this.state)
        console.log(this.props)
        this.props.signIn(this.state)
        window.location.href = config.base_url
        // this.props.history.push({
        //   pathname: "/events",
        //   // email: email,
        // });
        this.hideLoader()
      })
      .catch(console.log)
  }
  /*************** APIs End ***************/

  render() {
    return (
      <div>
        <Backdrop
          className={this.props.classes.backdrop}
          open={this.state.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className={this.props.classes.root}>
          {this.state.loading ? <LinearProgress /> : null}
        </div>
        <div className="xs-text-center">
          <div className="loginpass-back-img">
            <div className="container-fluid">
              <div className="logo-header">
                <a href="#" className="logo">
                  <img
                    src="../assets/img/logo-bs.svg"
                    className="img-fluid"
                    alt="logo"
                  />
                </a>
              </div>
              <div className="modal-layout">
                <h2>Update Profile</h2>
                <div className="col-lg-12 col-md-12 col-sm-12 p-0 email-input mb-4">
                  <input
                    type="text"
                    id="firstName"
                    value={this.state.firstName}
                    className="form-control"
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeypress}
                    placeholder="First Name"
                  ></input>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 p-0 email-input mb-4">
                  <input
                    type="text"
                    id="lastName"
                    value={this.state.lastName}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeypress}
                    className="form-control"
                    placeholder="Last Name"
                  ></input>
                </div>

                <Select
                  // className="form-control"
                  placeholder="Company"
                  value={this.state.company}
                  onChange={this.handleChangeCompany}
                  options={this.state.optionsForCompanies}
                />

                {/* <div className="col-lg-12 col-md-12 col-sm-12 p-0 email-input mb-4">
                  <select
                    value={this.state.company}
                    onChange={this.handleChangeCompany}
                    className="form-control"
                  >
                    <option value="grapefruit">Grapefruit</option>
                    <option value="lime">Lime</option>
                    <option value="coconut">Coconut</option>
                    <option value="mango">Mango</option>
                  </select>
                </div> */}

                <div className="mb-4"></div>
                <div className="btn-next">
                  <a href="#" onClick={() => this.onClickNext()}>
                    Next
                  </a>
                </div>
                {/* <div>
                  <p className="text-center mb-2">
                    By updating profile you agree to our
                  </p>
                </div>
                <div className="link-info text-center">
                  <a href="#" onClick={() => this.openTermsPdf()}>
                    {" "}
                    Terms of Service{" "}
                  </a>{" "}
                  <span> & </span>{" "}
                  <a href="#" onClick={() => this.openPrivacyPdf()}>
                    {" "}
                    Privacy Policy{" "}
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    plan: state.auth.plan,
    authError: state.auth.authError,
    auth: state.firebase.auth,
    loading: state.auth.loading,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    signIn: creds => dispatch(signIn(creds)),
  }
}

const enhance = compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
)

export default enhance(withRouter(UpdateProfile))
