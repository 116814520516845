import React, { Component } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import AppRouteManage from './common/index'
import AdminLogin from './components/AdminLogin'

const PrivateRouteEvent = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const { pathname, search } = props.location
      const isLoggedIn = Boolean(localStorage.getItem('ccccccc'))
      if (
        pathname === '/events/' &&
        search &&
        search.split('=')[0] === '?event_id' &&
        !isLoggedIn
      ) {
        // localStorage.setItem('eventPath', config.base_url+`${pathname}${search}`)
        localStorage.setItem('eventPath', `${pathname}${search}`)
      }
      return isLoggedIn ? <Component {...props} /> : <Redirect to="/signin" />
    }}
  />
)

const AppRouteDynamic = () =>
  localStorage.getItem('accessToken') ? (
    <AppRouteManage />
  ) : (
    <Redirect to="/signin" />
  )

class App extends Component {
  render() {
    const isLoggedIn = Boolean(localStorage.getItem('accessToken'))
    const isLoggedOut = !isLoggedIn
    return (
      <div className="App">
        <BrowserRouter>
          <Switch>
            {/* {isLoggedIn && <Redirect from="/signin" to="/events" />} */}
            {/* {isLoggedOut && <Redirect from="/explore" to="/signin" />} */}
            {/* {isLoggedOut && <Redirect from="/events" to="/signin" />} */}

            <Route path="/" component={AppRouteManage} exact />
            <Route path="/post/:id" component={AppRouteManage} />
            <Route path="/signin" component={AppRouteManage} />
            <Route path="/magiclink" component={AppRouteManage} />
            <Route path="/signinwithpassword" component={AppRouteManage} />
            <Route path="/updateprofile" component={AppRouteManage} />
            <Route path="/signup" component={AppRouteManage} />
            <Route path="/create" component={AppRouteManage} />
            <Route path="/dashboard" component={AppRouteManage} />
            <Route path="/welcome" component={AppRouteManage} />
            <Route path="/events" component={AppRouteDynamic} />
            <Route path="/onDemand" component={AppRouteDynamic} />
            <Route path="/amenities" component={AppRouteDynamic} />
            <Route path="/conferenceroom" component={AppRouteDynamic} />
            <Route path="/my-bookings" component={AppRouteDynamic} />
            <Route path="/contents" component={AppRouteManage} />
            <Route path="/rooms" component={AppRouteManage} />
            <Route path="/bannerurl" component={AppRouteManage} />
            <Route path="/admin_login" component={AppRouteManage} />
            <Route path="/admin_user" component={AppRouteManage} />
          </Switch>
        </BrowserRouter>
        {/* <AdminLogin /> */}
      </div>
    )
  }
}

export default App
