import config from '../config/config'

export default async () => {
  let res = await fetch(`${config.api_url}/api/v1/users/profileDetails`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify({
      uid: localStorage.getItem('user_id_real'), // ? This is fixed now!!
      email: localStorage.getItem('emailForSignIn'),
    }),
  })

  let [settledRequest] = await Promise.allSettled([res.json()])
  return settledRequest
}
