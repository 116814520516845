import React, { Component } from 'react'
import { withRouter } from 'react-router'
import TopHeader from '../components/Global/TopHeader'
import SideMenu from '../components/Global/SideMenu'
import LocationList from '../components/dashboard/Rooms/LocationList'
import EventList from '../components/dashboard/Events/EventList'
import Amenities from '../components/amenities'
import Welcome from '../components/auth/Welcome'
import CreatePost from '../components/posts/CreatePost'
import ExploreCategories from '../components/dashboard/Explore/ExploreCategories'
import ExploreList from '../components/dashboard/Explore/ExploreList'
import SignUp from '../components/auth/SignUp'
import SigninOtp from '../components/SignIn/SigninOtp'
import SigninOtpSubmit from '../components/SignIn/SigninOtpSubmit'
import SignInWithPassword from '../components/SignIn/SignInWithPassword'
import UpdateProfile from '../components/SignIn/UpdateProfile'
import { connect } from 'react-redux'
import getProfileDetailsLegacy from '../requests/getProfileDetailsLegacy'
import Bookings from '../components/booking/Bookings'
import MyBookings from '../components/mybooking/MyBookings'
// import Bookings from '../components/booking/Bookings'

//// import Rooms from "../components/dashboard/Rooms";
//// import RoomList from "../components/dashboard/Rooms/RoomList";
//// import Home from "../components/dashboard/Home";
//// import Events from "../components/dashboard/Events";
//// import Explore from "../components/dashboard/Explore/Explore";
//// import config from "../config/config";

let l = console.log

class AppRouteManage extends Component {
  componentDidMount = async () => {}

  render() {
    let mainScreen = ''
    let path = window.location.pathname
    let activeLink = ''
    let applySideBar = ''

    switch (path) {
      case '/rooms':
        mainScreen = <LocationList />
        activeLink = 'rooms'
        applySideBar = true
        break

      case '/onDemand':
        mainScreen = <ExploreCategories />
        activeLink = 'onDemand'
        applySideBar = true
        break

      case '/contents':
        if (localStorage.getItem('accessToken')) {
          mainScreen = <ExploreList />
          activeLink = 'onDemand'
          applySideBar = true
        } else {
          // window.alert("signin");
          console.log('redirect SignInPasswordless')
          mainScreen = <SigninOtp />
        }
        break

      case '/events':
        mainScreen = <EventList />
        activeLink = 'events'
        applySideBar = true
        break

      case '/amenities':
        mainScreen = <Amenities />
        activeLink = 'amenities'
        applySideBar = true
        break

      case '/conferenceroom':
        mainScreen = <Bookings />
        activeLink = 'conferenceroom'
        applySideBar = true
        break
      case '/my-bookings':
        mainScreen = <MyBookings />
        activeLink = 'my-bookings'
        applySideBar = true
        break
      case '/events/:id':
        mainScreen = <EventList />
        activeLink = 'events'
        applySideBar = true
        break

      // case '/':
      //   mainScreen = <Home />
      //   activeLink = 'home'
      //   applySideBar = true
      //   break

      case '/welcome':
        mainScreen = <Welcome />
        break

      case '/create':
        mainScreen = <CreatePost />
        break

      case '/signup':
        mainScreen = <SignUp />
        break

      case '/magiclink':
        if (localStorage.getItem('accessToken')) {
          mainScreen = <EventList />
          activeLink = 'events'
          applySideBar = true
        } else {
          //window.alert("magiclink");
          console.log('redirect SignInMagicLink')
          mainScreen = <SigninOtpSubmit />
        }
        break

      case '/signinwithpassword':
        if (localStorage.getItem('accessToken')) {
          mainScreen = <EventList />
          activeLink = 'events'
          applySideBar = true
        } else {
          mainScreen = <SignInWithPassword />
        }
        break

      case '/updateprofile':
        if (localStorage.getItem('accessToken')) {
          mainScreen = <EventList />
          activeLink = 'events'
          applySideBar = true
        } else {
          mainScreen = <UpdateProfile />
        }
        break

      case '/signin':
      default:
        const isLoggedIn = localStorage.getItem('accessToken')
        if (isLoggedIn) {
          mainScreen = <EventList />
          activeLink = 'events'
          applySideBar = true
        } else {
          // window.alert("signin");
          console.log('redirect SignInPasswordless')
          mainScreen = <SigninOtp />
        }
        break
    }

    l('indexProfileDetails', this.props.profileDetails)

    // return !this.props.profileDetails.isProfileDetailsLoaded ? (
    //   <h1>Loading...</h1>
    // ) : (
    // ^^ this logic sucks!!

    return (
      <>
        {Boolean(applySideBar) && (
          <>
            <TopHeader active={activeLink} />
            <SideMenu active={activeLink} />
          </>
        )}

        {mainScreen}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    // auth: state.firebase.auth,
    // notifications: state.firestore.ordered.notifications,
    profileDetails: state.profileDetails,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(AppRouteManage))
