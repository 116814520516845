// @ts-nocheck
import config from '../config/config'

export default async buildingId => {
  const userObjectId = localStorage.getItem('userObjectId')

  let res = await fetch(
    `${config.api_url}/api/v1/users/update/${userObjectId}`,
    {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: localStorage.getItem('accessToken'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        activeBuilding: buildingId,
      }),
    },
  )

  let [settledRequest] = await Promise.allSettled([res.json()])
  return settledRequest
}
