import React, { Component } from 'react'
import { connect } from 'react-redux'
import { signIn } from '../../store/actions/authActions'
import './signin.css'
import { compose } from 'redux'
import { Redirect, withRouter } from 'react-router-dom'
import LinearProgress from '@material-ui/core/LinearProgress'
import { Backdrop, CircularProgress, withStyles } from '@material-ui/core'
import firebase from 'firebase'
// import firebase from 'firebase/compat/app'
import UserProfile from '../auth/UserProfile'
import config from '../../config/config'
import getProfileDetailsLegacy from '../../requests/getProfileDetailsLegacy'
let l = console.log

const styles = theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#287fde',
  },
})

class SignInMagicLink extends Component {
  state = {
    firstName: '',
    lastName: '',
    uid: '',
    email: '',
    password: '',
    loading: false,
    otp: '',
  }

  componentDidMount() {
    const firebaseId = localStorage.getItem('emailForSignInFirebaseId')

    if (!firebaseId) {
      alert('got it 11')
      window.location = '/signin'
    } // ~sahil

    this.verifyEmail()
  }

  verifyEmail() {
    // Confirm the link is a sign-in with email link.
    console.log('verifyEmail')
    console.log(window.location.href)
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      var component = this
      component.showLoader()
      var email = localStorage.getItem('emailForSignIn')
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('Please provide your email for confirmation')
      }
      // The client SDK will parse the code from the link for you.
      firebase
        .auth()
        .signInWithEmailLink(email, window.location.href)
        .then(function(result) {
          // Clear email from storage.
          result.user.getIdToken(false).then(token => {
            //localStorage.removeItem("emailForSignIn")
            // component.hideLoader();

            component.setState({
              uid: result.user.uid,
              firstName: result.user.displayName,
              lastName: '',
              email: result.user.email,
              token: token,
            })

            localStorage.setItem('uid', result.user.uid)

            component.fetchProfileDetails(
              result.user.email,
              result.user.uid,
              token,
            )
            // component.props.signIn(component.state);
          })

          // component.fetchProfileDetails(result.user.email,result.user.uid)

          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
        })
        .catch(function(error) {
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
          console.log('login error', error)

          component.hideLoader()
        })
    }
  }

  onChangeOtp = e => {
    const value = e.target.value
    this.setState(state => ({ ...state, otp: value }))
  }

  submitOtp = e => {
    this.showLoader()

    /**This is verify otp request. */
    const appIdentifier =
      process.env.REACT_APP_ENV === 'production'
        ? 'com.betterspaces'
        : 'devWebApp'

    console.log('appIdentifier:1:verifyOtp', appIdentifier)

    const firebaseId = localStorage.getItem('emailForSignInFirebaseId')

    // if (!firebaseId) {
    //   window.location = '/signin'
    // }

    fetch(config.authWithOtp, {
      method: 'PUT', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        appIdentifier,
        apiversion: '1.1',
      },
      body: JSON.stringify({
        enteredOtp: this.state.otp,
        firebaseId,
      }),
    })
      .then(res => res.json())
      .then(data => {
        if (!data.success) {
          alert(data.message)

          this.hideLoader()
          return
        }

        const token = data.token

        // const config2 = {
        //   apiKey: 'AIzaSyCKU5pQhiZ2JkzP7lee34jYiT7jl9XSHN0',
        //   authDomain: 'fir-crud-2d38f.firebaseapp.com',
        //   databaseURL: 'https://fir-crud-2d38f.firebaseio.com',
        //   projectId: 'fir-crud-2d38f',
        //   storageBucket: 'fir-crud-2d38f.appspot.com',
        //   messagingSenderId: '401961946698',
        //   appId: '1:401961946698:web:ffb765b70cc9323220a0b3',
        //   measurementId: 'G-DXRD1LPWG5',
        // }

        firebase
          .auth()
          .signInWithCustomToken(token)
          .then(async userCredential => {
            const user = userCredential.user
            localStorage.setItem('user', JSON.stringify(user))
            let goodUserCredentials = JSON.parse(
              JSON.stringify(userCredential.user),
            )

            localStorage.setItem('user_id_real', goodUserCredentials.uid)

            const fullToken = goodUserCredentials.stsTokenManager.accessToken
            localStorage.setItem('accessToken', fullToken)

            const profileDetailsReq = await getProfileDetailsLegacy()

            if (profileDetailsReq.status === 'fulfilled') {
              const isStatusZero =
                profileDetailsReq.value && profileDetailsReq.value.status === 0

              if (isStatusZero) {
                return // handle status code 0 from backend.
              }
              const data = profileDetailsReq.value.data
              this.props.dispatch({
                type: 'profileDetails/update',
                payload: data,
              })
              localStorage.setItem('userObjectId', data._id)

              // ? Moving to home page and hiding loader now!
              this.props.history.push('/')
              this.hideLoader()
            }
            if (profileDetailsReq.status === 'rejected') {
              alert('rejected..')
            }

            // this.props.history.push('/')
            // this.hideLoader()

            // window.location.reload()
            // ...
          })
          .catch(error => {
            const errorCode = error.code
            const errorMessage = error.message

            alert('catch..')
            this.hideLoader()
            // ...

            l('err-log', errorCode)
            l('err-log', errorMessage)
          })
      })
      .catch(e => {})
  }

  async fetchProfileDetails(email, uid, token) {
    this.showLoader()
    fetch(config.api_url + '/api/v1/users/profileDetails', {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ uid: uid, email: email }),
    })
      .then(res => res.json())
      .then(data => {
        console.log('data ', data.data)
        this.hideLoader()
        if (
          data.data.firstName.length === 0 &&
          data.data.lastName.length === 0 &&
          data.data.fullName.length === 0
        ) {
          UserProfile.setUser(data.data)
          this.loadUpdateProfileView(token)
        } else {
          localStorage.setItem('accessToken', token)
          UserProfile.setUser(data.data)
          this.props.signIn(this.state)
          window.location.href = config.base_url
        }
      })
      .catch(console.log)
  }
  // ***********
  hideLoader = () => {
    this.setState({ loading: false })
  }

  showLoader = () => {
    this.setState({ loading: true })
  }

  signInWithPassword = () => {
    this.props.history.push({
      pathname: '/signinwithpassword',
    })
  }

  loadUpdateProfileView = token => {
    localStorage.setItem('preLoginToken', token)
    this.props.history.push({
      pathname: '/updateprofile',
    })
  }

  handleKeypress = e => {
    //it triggers by pressing the enter key
    if (e.key === 'Enter') {
      // console.log("enter pressed")
      this.submitOtp()
    }
  }

  render() {
    const { plan, auth, loading } = this.props
    if (auth.uid && plan) {
      if (plan === '-') return <Redirect to="/events" />
      else return <Redirect to="/" />
    }

    return (
      <div>
        <Backdrop
          className={this.props.classes.backdrop}
          open={this.state.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className={this.props.classes.root}>
          {loading ? <LinearProgress /> : null}
        </div>
        <div className="xs-text-center">
          <div className="email-confirmed-back-img">
            <div className="container-fluid">
              <div className="logo-header">
                <a href="#" className="logo">
                  <img
                    src="../assets/img/logo-bs.svg"
                    className="img-fluid"
                    alt="logo"
                  />
                </a>
              </div>
              <div className="modal-layout">
                <div className="text-center">
                  <h2 className="mb-30">Passcode sent successfully</h2>
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12 p-0 email-input">
                  <input
                    style={{ textAlign: 'center' }}
                    onKeyPress={this.handleKeypress}
                    value={this.state.otp}
                    onChange={this.onChangeOtp}
                    className="form-control"
                    placeholder="Enter Passcode here..."
                  ></input>
                </div>

                <br />
                <div className="btn-next" onClick={this.submitOtp}>
                  <a href="#">Verify Passcode</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    plan: state.auth.plan,
    authError: state.auth.authError,
    auth: state.firebase.auth,
    loading: state.auth.loading,
    profileDetails: state.profileDetails, // ~sahil
  }
}

const mapDispatchToProps = dispatch => {
  return {
    signIn: creds => dispatch(signIn(creds)),
    dispatch,
  }
}

const enhance = compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
)

export default enhance(withRouter(SignInMagicLink))
