import config from '../config/config'

export default async (id) => {
    const USER_ID = localStorage.getItem('uid')
    let res = await fetch(`${config.api_url}/api/v1/users/myList/${id}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            Authorization: localStorage.getItem('accessToken'),
            'Content-Type': 'application/json',
        },
    })
    let [settledRequest] = await Promise.allSettled([res.json()])
    return settledRequest
}