var config = {}
// TEST PUSH
var env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : 'development'

console.log('REACT_APP_ENV: ' + process.env.REACT_APP_ENV)
console.log('LOADING CONFIG FOR ENV: ' + env)

// window.alert(config.base_url)
config.base_url = window.location.origin
// window.alert(config.base_url)

switch (env) {
  case 'development':
    config.DOWNLOAD_PRE_URL_BETTERSPACES =
      'https://storage.googleapis.com/betterspacesqa/'
    config.googleClientId =
      '401961946698-grca3qbj9h67a2d0058rp4ie907cedat.apps.googleusercontent.com'
    config.api_url = 'https://bspaces-api-dev-ugloxd7xqq-uc.a.run.app'

    config.firebase_url = config.base_url + '/magiclink'
    config.yourSubDomain = 'betterspaces' // Its sub domain of your deep link
    config.appIdentifier = 'com.betterspaces.bspaces-development'
    config.packageName = 'com.betterspaces.bspaces.dev'
    config.yourApiKey = 'AIzaSyAs-sQna-XwkpE0KyJvaZW2j3ReIGlfRy0' //Its Betterspaces development apiKey from iOS google plist, so that it works on bot mobile and web.

    config.dynamicLinkDomain = 'fircrud.page.link'
    config.apiKey = 'AIzaSyCKU5pQhiZ2JkzP7lee34jYiT7jl9XSHN0'
    config.configFb = {
      apiKey: 'AIzaSyCKU5pQhiZ2JkzP7lee34jYiT7jl9XSHN0',
      authDomain: 'fir-crud-2d38f.firebaseapp.com',
      databaseURL: 'https://fir-crud-2d38f.firebaseio.com',
      projectId: 'fir-crud-2d38f',
      storageBucket: 'fir-crud-2d38f.appspot.com',
      messagingSenderId: '401961946698',
      appId: '1:401961946698:web:ffb765b70cc9323220a0b3',
      measurementId: 'G-DXRD1LPWG5',
    }

    // Status Codes, Int
    config.StatusCode = {
      success: 200,
      invalidToken: 400,
      unableToBooklocation: 401,
    }
    config.authWithOtp =
      'https://us-central1-betterspacesqa.cloudfunctions.net/authentication'

    break

  case 'production':
    // betterspacesprod ------------------------------------------------------------------------------------------
    config.DOWNLOAD_PRE_URL_BETTERSPACES =
      'https://storage.googleapis.com/betterspacesprod/'
    config.googleClientId =
      '73573321427-6r781v8f0c05oht8n41raruodgg1f6ub.apps.googleusercontent.com'
    config.api_url = 'https://apiprod.betterspaces.com'
    config.firebase_url = config.base_url + '/magiclink' // FOR DEPLOYMENT
    config.yourSubDomain = 'betterspacesprod' // Its sub domain of your deep link
    // config.yourSubDomain = "betterspacesevent"; // Its sub domain of your deep link
    config.appIdentifier = 'com.betterspaces'
    config.packageName = 'com.betterspaces.bspaces.production'
    config.yourApiKey = 'AIzaSyDgorY_ODu9R80IgMb2p8TOmEhoMgaBjm0' //Its Betterspaces production apiKey from iOS google plist, so that it works on bot mobile and web.

    config.dynamicLinkDomain = 'betterspacesprod.page.link'
    config.apiKey = 'AIzaSyDVRIjz1ofRhKMh5LQPCeMZQ-mw3GXFl8g'
    config.configFb = {
      databaseURL: 'https://bspweb.web.app/',
      apiKey: 'AIzaSyDVRIjz1ofRhKMh5LQPCeMZQ-mw3GXFl8g',
      authDomain: 'bspweb.web.app',
      projectId: 'betterspacesprod',
      storageBucket: 'betterspacesprod.appspot.com',
      messagingSenderId: '73573321427',
      appId: '1:73573321427:web:7f4813ee0f43fd45316226',
      measurementId: 'G-FTJWTH4T1N',
    }
    config.authWithOtp =
      'https://us-central1-betterspacesprod.cloudfunctions.net/authentication'

    break
  default:
    config.googleClientId =
      '401961946698-grca3qbj9h67a2d0058rp4ie907cedat.apps.googleusercontent.com'
    config.api_url = 'https://bspaces-api-dev-ugloxd7xqq-uc.a.run.app'
    // config.base_url = "http://localhost:3000";
    // config.base_url = "https://fir-crud-2d38f.firebaseapp.com";
    config.firebase_url = config.base_url + '/magiclink'
    config.yourSubDomain = 'fircrud' // Its sub domain of your deep link
    config.dynamicLinkDomain = 'fircrud.page.link'
    config.appIdentifier = 'com.betterspaces.bspaces-development'
    config.packageName = 'com.betterspaces.bspaces.dev'
    config.yourApiKey = 'AIzaSyAs-sQna-XwkpE0KyJvaZW2j3ReIGlfRy0' //Its Betterspaces development apiKey from iOS google plist, so that it works on bot mobile and web.

    break
}

export default config
