import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import 'react-sweet-progress/lib/style.css'
import '../assets/css/home.css'
import '../assets/css/style.css'
import './eventlist.css'
import $ from 'jquery'
import Modal from 'react-modal'
import UserProfile from '../../auth/UserProfile'
import {
  Backdrop,
  CircularProgress,
  withStyles,
  Button,
} from '@material-ui/core'
import 'bootstrap/dist/css/bootstrap.min.css'
import config from '../../../config/config'
import moment from 'moment'
import EventModal from './EventModal'
import { getDate, getTime } from '../../../common/common.js'

import {
  FirebaseEvent,
  firebaseTrackEvent,
} from '../../../config/FirebaseManager'
import EventCard from './EventCard'
import ConfirmeCard from './ConformeCard'
import ChooseCalender from './ChooseCalender'
// import exclamation from "../images/exclamation-circle.svg"
// import { BsExclamationCircle} from "react-icons/vsc";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  WhatsappIcon,
  LinkedinIcon,
  TwitterIcon,
  EmailIcon,
} from 'react-share'

import './share.css'
import { Link } from 'react-router-dom'
import getProfileDetailsLegacy from '../../../requests/getProfileDetailsLegacy'

let l = console.log

const styles = theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    // color: '#287fde',
    color: '#287fde',
  },
})

var currentDate = new Date()
var fridayDate = new Date()

let items = [
  { google: 'Google' },
  { outlookcom: 'Outlook' },
  { applecom: 'Apple Calendar' },
]

let icon = { textOnly: 'none' }
let path = ''
var eventId = ''

class EventList extends React.Component {
  constructor(props) {
    super(props)
    firebaseTrackEvent(FirebaseEvent.events_screen, {})
    this.handleModal = this.handleModal.bind(this)
  }

  state = {
    events: [],
    upcomingEvnts: [],
    photoURL: '',
    // userId: this.props.auth.uid || localStorage.getItem('user_id'), //!old code. ~sahil.
    userId: localStorage.getItem('user_id_real'),
    dropdown: false,
    selectedDate: '',
    allEventsRSVPed: false,
    loading: true,
    user: null,
    openAddContentModal: false,
    attendingArray: [],
    isOpen: false,
    eventModel: [],
    session: false,
    confirmEventModel: false,
    openconfirmModel: false,
    openCalenderModel: false,
    eventData: {},
    openEventDetailExternalModal: false,
    openConfirmAlert: false,
    openSharePopUp: false,
    shareUrl: '',
    shareTitle: '',
    shareImage: '',
    copyMessage: 'Copy',
    bannerList: {},
    visible: '',
    showimage: false,
  }

  hideLoader = () => {
    this.setState({ loading: false })
  }

  showLoader = () => {
    this.setState({ loading: true })
  }

  /***************************/

  confirmCancel_RSVP_clicked = () => {
    console.log('confirmCancel_RSVP_clicked')
  }
  /***************************/

  /***************************/
  openConfirmAlertView = () => {
    this.setState({ openConfirmAlert: true })
  }

  closeConfirmAlertView = () => {
    this.setState({ openConfirmAlert: false })
  }

  confirm_RSVP_clicked = () => {
    console.log('confirm_RSVP_clicked')
  }
  /***************************/

  /***************************/
  openSharePopUpView = () => {
    this.setState({ openSharePopUp: true })
  }

  closeSharePopUpView = () => {
    this.setState({ openSharePopUp: false, copyMessage: 'Copy' })
  }
  /***************************/

  handleModal = (data, session, confirmFalse) => {
    this.setState({
      eventModel: data,
      session,
    })
    this.rsvp(session, data, confirmFalse)
  }

  async componentDidMount(props) {
    if (window.innerWidth >= 655) {
      $(document).ready(function() {
        document.body.classList.add('open-sidebar')

        $('.modal-toggle').on('click', function(e) {
          e.preventDefault()
          $('.modal').toggleClass('is-visible')
        })
      })
    } else {
      $(document).ready(function() {
        document.body.classList.remove('open-sidebar')

        $('.modal-toggle').on('click', function(e) {
          e.preventDefault()
          $('.modal').toggleClass('is-visible')
        })
      })
    }

    currentDate = this.getMonday(new Date())
    fridayDate = this.getFriday(currentDate)

    const mo = new Intl.DateTimeFormat('en', { month: 'long' }).format(
      currentDate,
    )
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(
      currentDate,
    )
    const da_fr = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(
      fridayDate,
    )

    this.setState({
      selectedDate: mo
        .concat(' ')
        .concat(da)
        .concat(' - ')
        .concat(da_fr),
    })

    const isNotProfileDetailsLoaded = !this.props.profileDetails
      .isProfileDetailsLoaded

    if (isNotProfileDetailsLoaded) {
      const profileDetailsReq = await getProfileDetailsLegacy()

      if (profileDetailsReq.status === 'fulfilled') {
        const isStatusZero =
          profileDetailsReq.value && profileDetailsReq.value.status === 0

        if (isStatusZero) {
          return // handle status code 0 from backend.
        }
        const data = profileDetailsReq.value.data
        this.props.dispatch({
          type: 'profileDetails/update',
          payload: data,
        })
      }
      if (profileDetailsReq.status === 'rejected') {
        alert('rejected..')
      }

      return
    } else {
      const profileDetails = this.props.profileDetails

      if (profileDetails) {
        UserProfile.setUser(profileDetails)
        this.fetchEvents(true)
        this.fetchBanner()
      }
      this.setState({
        user: profileDetails,
        selectedDate: mo
          .concat(' ')
          .concat(da)
          .concat(' - ')
          .concat(da_fr),
      })
    }
  }

  componentDidUpdate = prevProps => {
    const currentProps = this.props

    //Typical usage, don't forget to compare the props
    //  if (this.props.userName !== prevProps.userName) {
    //   this.fetchData(this.props.userName);
    // }

        // if (this.props.eventData && (prevProps.eventData.length !== currentProps.eventData.length)) {
        //   this.fetchEvents(true)
        // }
    const isNotProfileDetailsLoaded = !this.props.profileDetails
      .isProfileDetailsLoaded
    if (isNotProfileDetailsLoaded) return //? return if profile details not loaded.

    const currentActiveBuildingId = this.props.profileDetails.activeBuilding._id
    const previousActiveBuildingId =
      prevProps.profileDetails &&
      prevProps.profileDetails.activeBuilding &&
      prevProps.profileDetails.activeBuilding._id

    if (
      previousActiveBuildingId &&
      currentActiveBuildingId !== previousActiveBuildingId
    ) {
      UserProfile.setUser(this.props.profileDetails)
      this.fetchEvents(true)
      this.fetchBanner()
      this.setState({ user:this.props.profileDetails});
    }

    const isUpcomingEvents = this.state.events.length !== 0
    if (isUpcomingEvents) {
      return // ? Stop EXECUTING IF component state already has the upcomingEvents.
    }

    const mo = new Intl.DateTimeFormat('en', { month: 'long' }).format(
      currentDate,
    )
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(
      currentDate,
    )
    const da_fr = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(
      fridayDate,
    )

    const currentIsAmenities =
      this.props &&
      this.props.profileDetails &&
      this.props.profileDetails.activeBuilding &&
      this.props.profileDetails.activeBuilding.isAmenities

    const prevIsAmenities =
      prevProps.profileDetails &&
      prevProps.profileDetails.activeBuilding &&
      prevProps.profileDetails.activeBuilding.isAmenities
    console.log(
      'profileDetailsEventList',
      currentIsAmenities !== prevIsAmenities,
    )

    const isNotChangedIsAmenities = currentIsAmenities === prevIsAmenities
    // code control comes here only when the profileDetails is loaded.
    if (isNotChangedIsAmenities) {
      return // ? Stop EXECUTING IF CURRENT PROPS HAS NO ISAMENITIES.
    }
    const profileDetails = this.props.profileDetails

    if (profileDetails) {
      UserProfile.setUser(profileDetails)
      this.fetchEvents(true)
      this.fetchBanner()
    }
    this.setState({
      user: profileDetails,
      selectedDate: mo
        .concat(' ')
        .concat(da)
        .concat(' - ')
        .concat(da_fr),
    })
  }

  getMonday(d) {
    d = new Date(d)
    d.setHours(0)
    d.setMinutes(0)
    d.setSeconds(0)
    var day = d.getDay(),
      diff = d.getDate() - day + (day === 0 ? -6 : 1) // adjust when day is sunday

    return new Date(d.setDate(diff))
  }

  getFriday(d) {
    // Code to check that date and dayOfWeek are valid left as an exercise ;)
    var resultDate = new Date(d.getTime())

    resultDate.setDate(d.getDate() + ((7 + 5 - d.getDay()) % 7)) // 5 is for friday
    resultDate.setHours(23)
    resultDate.setMinutes(59)
    resultDate.setSeconds(59)
    return resultDate
  }

  nextWeek = () => {
    currentDate.setDate(currentDate.getDate() + 7)
    this.fetchDataByWeek()
  }

  previousWeek = () => {
    currentDate.setDate(currentDate.getDate() - 7)
    this.fetchDataByWeek()
  }

  fetchDataByWeek() {
    currentDate = this.getMonday(currentDate)
    fridayDate = this.getFriday(currentDate)

    const mo = new Intl.DateTimeFormat('en', { month: 'long' }).format(
      currentDate,
    )
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(
      currentDate,
    )
    const da_fr = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(
      fridayDate,
    )

    this.setState({
      selectedDate: mo
        .concat(' ')
        .concat(da)
        .concat(' - ')
        .concat(da_fr),
    })

    this.fetchEvents()
  }

  // fetching banner........
  async fetchBanner() {
    const component = this
    this.showLoader()
    try {
      fetch(config.api_url + '/api/v1/buildings/announcements', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('accessToken'),
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(),
      })
        .then(res => res.json())
        .then(resp => {
          console.log('banner list: ', resp)
          if (resp.data && resp.data.list && resp.data.list.length > 0) {
            this.setState({
              bannerList: resp.data.list[0],
            })
            localStorage.setItem('bannerImage', resp.data.list[0].url)
            console.log('checkresponseurl', resp.data.list[0].url)
          }
          component.hideLoader()
        })
      // .catch(console.log);
    } catch (e) {
      console.log('fetch news-banner error', e)
    }
  }

  //  End of banner

  async fetchEvents(isLoadedFirstTime, index) {
    const component = this
    this.showLoader()

    const isLoggedIn = window.localStorage.getItem('accessToken')

    console.log('accessToken' + isLoggedIn)

    const isProfileFetched = UserProfile.building_id()

    if (!isProfileFetched) {
      alert('refreshing page because user details not found..')
      window.location.reload() // ~sahil temp..
    }

    console.log(UserProfile.building_id())
    try {
      // ~sahil
      fetch(
        config.api_url +
          '/api/v1/buildings/upcoming/' +
          UserProfile.building_id(),
        {
          method: 'GET', // *GET, POST, PUT, DELETE, etc.
          headers: {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem('accessToken'),
          },
          redirect: 'follow', // manual, *follow, error
          referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: JSON.stringify(), // body data type must match "Content-Type" header
        },
      )
        .then(res => res.json())
        .then(data => {
          component.hideLoader()
          console.log('Eventsdata= ', data)

          if (data.code === 400) {
            // window.alert(data.msg);
            // if (window.confirm(data.msg + "\nDo you want to logout?")) {
            console.log('InvalidToken: ' + localStorage.getItem('accessToken'))
            this.props.history.push('/signin')

            UserProfile.removeUser()
            // }
          } else {
            let description = ''
            if (index >= 0 && this.state.upcomingEvnts.length > index) {
              if (
                this.state.upcomingEvnts[index] &&
                this.state.upcomingEvnts[index].description
              ) {
                description = this.state.upcomingEvnts[index].description
              }
            }

            let upcomingEvents = []
            let eventsForCalendar = []
            let allEventsRSVPed = false
            data &&
              data.data &&
              data.data.list &&
              data.data.list.length &&
              data.data.list.map(post => {
                if (
                  moment(post.startDate).isSameOrAfter(moment(currentDate)) &&
                  moment(post.startDate).isSameOrBefore(moment(fridayDate))
                ) {
                  // upcomingEvents.push(post);
                  // allEventsRSVPed = post.attendingflag;
                }
                post.collapsible = true

                post.description = description
                upcomingEvents.push(post)
                allEventsRSVPed = post.attendingflag
                var endDateTime = new Date(post.ses_det[0].startDateTime)
                endDateTime.setMinutes(endDateTime.getMinutes() + post.duration)

                let event = {
                  title: post.name,
                  description: post.description,
                  startTime: new Date(post.ses_det[0].startDateTime),
                  endTime: endDateTime,
                }

                eventsForCalendar.push(event)
              })
            let allUpcomingEvent = this.props.eventData ? upcomingEvents.filter((data) => {return this.props.eventData.some((val) => val.id == data.id)}) : upcomingEvents;
            this.setState({
              events: eventsForCalendar,
              upcomingEvnts: allUpcomingEvent,
              allEventsRSVPed: allEventsRSVPed,
            })

            if (isLoadedFirstTime) {
              if (localStorage.getItem('eventPath')) {
                path = localStorage.getItem('eventPath')
                // localStorage.removeItem("eventPath");
              } else {
                path = window.location.pathname
              }

              console.log(window.location.pathname)
              console.log(window.location.href)

              eventId = path.substring(path.lastIndexOf('/') + 1)

              let idx = this.state.upcomingEvnts.findIndex(
                obj => obj.id === eventId,
              )

              // console.log(idx);
              if (idx < 0) {
                if (localStorage.getItem('eventPath')) {
                  path = localStorage.getItem('eventPath')
                  // localStorage.removeItem("eventPath");
                } else {
                  path = window.location.href
                }
                eventId = path.substring(path.lastIndexOf('event_id') + 9)

                idx = this.state.upcomingEvnts.findIndex(
                  obj => obj.id === eventId,
                )
              }

              console.log('eventId: ', eventId)

              this.setState({
                openEventDetailExternalModal: eventId.length > 0 && idx >= 0,
              })

              if (localStorage.getItem('eventPath')) {
                localStorage.removeItem('eventPath')
              }

              if (this.state.openEventDetailExternalModal) {
                this.fetchEventDetails(this.state.upcomingEvnts[idx], idx)
              }
            }
          }
        })
      // .catch(console.log);
    } catch (e) {
      console.log('fetch event error', e)
    }
  }

  joinMeeting = event => {
    if (event.videoUrl.length > 0) {
      window.open(event.videoUrl)
    }
  }

  rsvp = async (session, post, confirmFalse) => {
    console.log(
      '🚀 ~ file: EventList.js ~ line 306 ~ EventList ~ rsvp= ~ session, post',
      session,
      post,
    )
    const user_id = UserProfile.user_id()
    console.log('step1')
    const component = this
    this.showLoader()
    await fetch(
      config.api_url +
        '/api/v1/events/register/' +
        session.split(',')[1] +
        '/' +
        user_id,
      {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('accessToken'),
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({
          author: UserProfile.user_name(),
          platform: 'android',
        }), // body data type must match "Content-Type" header
      },
    )
      .then(res => res.json())
      .then(data => {
        // console.log("data ", data.data);
        component.hideLoader()
        this.setState({ isOpen: false })
        let eventDetails = {
          event_id: post._id,
          event_date: moment(post.startDate).format('YYYY-MM-DD'),
          event_time: session.split(',')[0],
          event_name: post.name,
        }
        firebaseTrackEvent(FirebaseEvent.rsvp_event, eventDetails)

        let idx = this.state.upcomingEvnts.findIndex(obj => obj.id === post._id)

        this.fetchEvents(false, idx)
        this.setState({
          openconfirmModel: !confirmFalse,
          openEventDetailExternalModal: false,
        })
      })
    // .catch(console.log);
    console.log("event-time-testing----->>>>>", session);
  }

  cancelrsvp = event => {
    const user_id = UserProfile.user_id()
    let session_id = event.ses_det[0]._id
    let sessionTime = ''

    //this is to find which session out of multiple sessions, has user RSVPed
    event.ses_det.map(session => {
      session.attending.map(user => {
        if (user == user_id) {
          session_id = session._id
          sessionTime = new Intl.DateTimeFormat('en', {
            hour: 'numeric',
            minute: 'numeric',
          }).format(new Date(session.startDateTime))
        }
      })
    })

    const component = this
    this.showLoader()
    fetch(
      config.api_url +
        '/api/v1/events/unregister/' +
        session_id +
        '/' +
        user_id,
      {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('accessToken'),
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({
          author: UserProfile.user_name(),
          platform: 'android',
        }), // body data type must match "Content-Type" header
      },
    )
      .then(res => res.json())
      .then(data => {
        // console.log("data ", data.data);
        component.hideLoader()
        let eventDetails = {
          event_id: event._id,
          event_time: sessionTime,
          event_date: moment(event.startDate).format('YYYY-MM-DD'),
          event_name: event.name,
        }
        firebaseTrackEvent(FirebaseEvent.cancel_rsvp_event, eventDetails)
        // this.props.eventData && this.props.refetch()
        let filtered = this.state.upcomingEvnts.filter((d)=>d.id !== event.id)
        this.setState({ upcomingEvnts :filtered  });
        this.props.removeCard(event.id,"event")
        let idx = this.state.upcomingEvnts.findIndex(
          obj => obj.id === event._id,
        )
        !this.props.eventData && this.fetchEvents(false, idx)
      })
    // .catch(console.log);
  }

  fetchAttendeesDetails = async event => {
    const component = this
    this.showLoader()
    await fetch(config.api_url + '/api/v1/events/eventdetail/' + event._id, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('accessToken'),
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      // body data type must match "Content-Type" header
    })
      .then(res => res.json())
      .then(data => {
        console.log('fetchEventDetails ', data.data.result)
        component.hideLoader()

        if (data.code === 200) {
          if (data.data.result.sessions) {
            let attending = []
            data.data.result.sessions.map(session => {
              session.attending.map(user => {
                attending.push(user.fullName)
              })
            })

            if (attending.length > 0) {
              let eventDetails = {
                attendings: attending,
                event_title: data.data.result.eventLink.title,
                event_id: data.data.result.eventLink._id,
                event_date: moment(data.data.result.eventDate).format(
                  'YYYY-MM-DD',
                ),
              }
              console.log(eventDetails)
              firebaseTrackEvent(FirebaseEvent.event_attendees, eventDetails)
            }

            this.setState({
              openAddContentModal: attending.length > 0,
              attendingArray: attending,
            })
          }
        } else {
        }
      })

    // if (event.attendingArray && event.attendingArray.length > 0) {
    //   this.setState({
    //     openAddContentModal: true,
    //     attendingArray: event.attendingArray,
    //   });
    // }
  }
  fetchEventDetails = async (event, index) => {
    let oldEvent = this.state.upcomingEvnts[index]
    if (oldEvent.description) {
      // console.log(oldEvent.description)
      return
    }

    const component = this
    this.showLoader()
    await fetch(config.api_url + '/api/v1/events/eventdetail/' + event._id, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('accessToken'),
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      // body data type must match "Content-Type" header
    })
      .then(res => res.json())
      .then(data => {
        console.log('fetchEventDetails ', data.data.result)
        component.hideLoader()

        if (data.code === 200) {
          var oldEvents = this.state.upcomingEvnts
          let oldEvent = this.state.upcomingEvnts[index]
          oldEvent.description = data.data.result.eventLink.desc
          oldEvent.capacity = data.data.result.capacity

          oldEvents[index] = oldEvent
          this.setState({ upcomingEvents: oldEvents })
        } else {
        }
      })
    // .catch(console.log);
  }

  addToCalendar = post => {}

  rsvpAllClasses = async () => {
    //loop through all events are rsvp first session
    this.state.upcomingEvnts.map((post, index) => {
      const user_id = UserProfile.user_id()
      const component = this
      let listSize = this.state.upcomingEvnts.length - 1

      if (!post.attendingflag) {
        this.showLoader()
        fetch(
          config.api_url +
            '/api/v1/events/register/' +
            post.ses_det[0]._id +
            '/' +
            user_id,
          {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            headers: {
              'Content-Type': 'application/json',
              Authorization: localStorage.getItem('accessToken'),
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify({
              author: UserProfile.user_name(),
              platform: 'android',
            }), // body data type must match "Content-Type" header
          },
        )
          .then(res => res.json())
          .then(data => {
            // console.log("data ", data.data);
            component.hideLoader()

            let sessionTime = new Intl.DateTimeFormat('en', {
              hour: 'numeric',
              minute: 'numeric',
            }).format(new Date(post.ses_det[0].startDateTime))
            let eventDetails = {
              event_id: post._id,
              event_date: moment(post.ses_det[0].startDate).format(
                'YYYY-MM-DD',
              ),
              event_time: sessionTime,
              event_name: post.name,
            }
            firebaseTrackEvent(FirebaseEvent.rsvp_event, eventDetails)

            if (index === listSize) {
              this.fetchEvents()
              component.setState({ allEventsRSVPed: true })
            }
          })
        // .catch(console.log);
      } else {
        if (index === listSize) {
          this.fetchEvents()
          component.setState({ allEventsRSVPed: true })
        }
      }
    })
  }

  handleConfirmClose = () => {
    this.setState({
      openconfirmModel: false,
      openCalenderModel: false,
    })
  }

  handleCalenderClose = () => {
    this.setState({
      openCalenderModel: false,
    })
  }

  handleCalenderSubmit = () => {
    // this.setState({
    //   openCalenderModel: false,
    // });
  }

  handleConfirmSubmit = () => {
    this.setState({
      openconfirmModel: false,
      openCalenderModel: true,
    })
  }

  unRsvpAllClasses = () => {
    //loop through all events are rsvp first session
    let listSize = this.state.upcomingEvnts.length - 1
    this.state.upcomingEvnts.map((post, index) => {
      const user_id = UserProfile.user_id()
      const component = this
      this.showLoader()
      let session = post.ses_det[0]
      //this is to find which session out of multiple sessions, has user RSVPed
      post.ses_det.map(ses => {
        ses.attending.map(user => {
          if (user == user_id) {
            session = ses
          }
        })
      })

      fetch(
        config.api_url +
          '/api/v1/events/unregister/' +
          session._id +
          '/' +
          user_id,
        {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
          headers: {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem('accessToken'),
          },
          redirect: 'follow', // manual, *follow, error
          referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: JSON.stringify({
            author: UserProfile.user_name(),
            platform: 'android',
          }), // body data type must match "Content-Type" header
        },
      )
        .then(res => res.json())
        .then(data => {
          // console.log("data ", data);
          component.hideLoader()

          let sessionTime = new Intl.DateTimeFormat('en', {
            hour: 'numeric',
            minute: 'numeric',
          }).format(new Date(session.startDateTime))

          let eventDetails = {
            event_id: post._id,
            event_date: moment(session.startDate).format('YYYY-MM-DD'),
            event_time: sessionTime,
            event_name: post.name,
          }
          firebaseTrackEvent(FirebaseEvent.unrsvp_event, eventDetails)

          if (index === listSize) {
            this.fetchEvents()
            component.setState({ allEventsRSVPed: false })
          }
        })
      // .catch(console.log);
    })
  }

  /***************************/
  openEventDetailExternalView = () => {
    this.setState({ openEventDetailExternalModal: true })
  }

  clsoeEventDetailExternalView = () => {
    this.setState({ openEventDetailExternalModal: false })
  }
  /***************************/

  openAddContentView = post => {}

  closeAddContentView = () => {
    this.setState({ openAddContentModal: false })
    console.log(
      '🚀 ~ file: EventList.js ~ line 645 ~ EventList ~ openAddContentModal',
      this.state.openAddContentModal,
    )
  }

  onSelectRsvpTime = (evt, post, event, confirmFalse) => {
    const component = this
    this.setState({
      confirmEventModel: true,
      eventData: event,
    })
    component.handleModal(post, evt, confirmFalse)
  }

  onCloseRsvpTime = () => {
    this.setState({
      confirmEventModel: false,
    })
  }

  handleOpenConfirmRsvp = () => {
    this.setState({
      // confirmEventModel: false,
      // openconfirmModel: true,
    })
  }


  shareClicked = event => {
    let your_page_link = 'https://' + config.yourSubDomain + '.page.link/?link='
    let your_event_id = event.id
    let your_deep_link = config.base_url + '/events/?event_id=' + your_event_id
    let package_name = config.packageName
    let ibi = config.appIdentifier

    let dymanic_link =
      your_page_link +
      your_deep_link +
      '&apn=' +
      package_name +
      '&ibi=' +
      ibi +
      '&afl=' +
      your_deep_link +
      '&ifl=' +
      your_deep_link

    console.log(dymanic_link)

    fetch(
      'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=' +
        config.yourApiKey,
      {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({
          longDynamicLink: dymanic_link,
        }),
      },
    )
      .then(res => res.json())
      .then(data => {
        // console.log("dymanic link response: ", data);
        // console.log("dymanic link response: ", data.shortLink);

        this.setState({
          shareUrl: data.shortLink,
          shareTitle: event.name,
          shareImage: event.image,
        })
        this.openSharePopUpView()
      })
      .catch(console.log)
  }

  closecard = e => {
    console.log(e.target)
    this.setState({
      openEventDetailExternalModal: false,
    })
  }

  // openpdf = (e) => {
  // this.setState({
  //  url :
  // })
  // }

  render() {
    const isProfileDetailsLoaded =
      this.props.profileDetails &&
      this.props.profileDetails.isProfileDetailsLoaded

    l('isProfileDetailsLoadedEventList-->', isProfileDetailsLoaded)
    
    console.log("event-time-test-done--", this.state)

    const listItems = this.state.attendingArray.map(attendee => (
      <li>{attendee}</li>
    ))
    console.log('profile-details-eventlist', this.props.profileDetails)

    const { openSharePopUp, shareUrl, shareTitle, bannerList } = this.state
    console.log('bannerList.backgroundcolor', bannerList.backgroundcolor)

    return (
      <div className="modal_parent" id="closecard" onClick={this.closecard} style={{width:"100%"}}>
      {!this.props.eventData &&  <Backdrop
          className={this.props.classes.backdrop}
          open={this.state.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>}
        {/* MODAL SHARE VIEW START *****************************************************************/}
        {/* // ? This is share dialog box container. */}
        {openSharePopUp ? (
          <Modal
            isOpen={openSharePopUp}
            onRequestClose={() => this.closeSharePopUpView()}
            contentLabel=""
            className="share-modal"
            shouldCloseOnOverlayClick={true}
          >
            <div className="modal-body">
              <button
                className="modal-close-blue-event"
                onClick={() => this.closeSharePopUpView()}
              >
                <img src="../../../assets/img/close-blue.svg" alt="Close" />
              </button>

              <div className="social-media-text1">Share this class</div>

              <div className="Demo__container">
                <div className="Demo__some-network">
                  <TwitterShareButton
                    url={shareUrl}
                    title={shareTitle}
                    className="Demo__some-network__share-button"
                  >
                    <TwitterIcon className="social-media-icon" />
                  </TwitterShareButton>
                </div>
                <div className="Demo__some-network">
                  <EmailShareButton
                    body={'Join me in this BetterSpaces event \n'}
                    url={shareUrl}
                    subject={shareTitle}
                    // body="body"
                    className="Demo__some-network__share-button"
                    onClick={(_, link) => {
                      window.open(link)
                    }}
                  >
                    <EmailIcon className="social-media-icon" />
                  </EmailShareButton>
                </div>

                <div className="Demo__some-network">
                  <FacebookShareButton
                    url={shareUrl}
                    quote={shareTitle}
                    className="Demo__some-network__share-button"
                  >
                    <FacebookIcon className="social-media-icon" />
                  </FacebookShareButton>
                </div>

                <div className="Demo__some-network">
                  <LinkedinShareButton
                    url={shareUrl}
                    className="Demo__some-network__share-button"
                  >
                    <LinkedinIcon className="social-media-icon" />
                  </LinkedinShareButton>
                </div>
              </div>

              <div className="social-media-text2">Or copy link</div>
              <div className="social-media-textbox">
                <div className="social-media-textbox-text">{shareUrl}</div>
                <CopyToClipboard
                  text={shareUrl}
                  onCopy={() => this.setState({ copyMessage: 'Copied' })}
                >
                  <span className="social-media-copytext">
                    <a href="#/">{this.state.copyMessage}</a>
                  </span>
                </CopyToClipboard>
              </div>
            </div>
          </Modal>
        ) : (
          ''
        )}
        {/* MODAL SHARE VIEW END *******************************************************************/}
        {/* MODAL ATTENDEES START *******************************************************************/}
        {this.state.openAddContentModal ? (
          <Modal
            isOpen={this.state.openAddContentModal}
            // onAfterOpen={afterOpenModal}
            onRequestClose={() => this.closeAddContentView()}
            // style={customStyles}
            ariaHideApp={false}
            className="attendees-modal"
            shouldCloseOnOverlayClick={true}
          >
            <div className="modal-body">
              <button
                className="modal-close-blue-event"
                onClick={() => this.closeAddContentView()}
              >
                <img
                  src="../../../assets/img/close-blue.svg"
                  alt="Closattendees-modale"
                />
              </button>

              <div className="attendees-title">Attendees</div>

              <div className="attending">
                <ul id="attending-list">{listItems}</ul>
              </div>
            </div>
          </Modal>
        ) : (
          ''
        )}
        {/* MODAL ATTENDEES END *******************************************************************/}
        {/* MODAL EXTERNAL EVENT DETAILS START *******************************************************************/}
        {this.state.openEventDetailExternalModal ? (
          <Modal
            isOpen={this.state.openEventDetailExternalModal}
            shouldCloseOnOverlayClick={true}
            // onAfterOpen={afterOpenModal}
            onRequestClose={() => this.clsoeEventDetailExternalView()}
            // style={customStyles}
            ariaHideApp={false}
            // contentLabel="Example Modal"
            style={{
              content: {
                border: 'unset',
                background: 'unset',
              },
            }}
          >
            <div className="views_row_external">
              {this.state.upcomingEvnts &&
                this.state.upcomingEvnts.map((post, index) => {
                  if (post.id !== eventId) {
                    return ''
                  }
                  let eventDate = getDate(post.startDate)
                  let eventTime = getTime(post.ses_det[0].startDateTime)
                  let attendingCount = 0
                  post.ses_det.map(session => {
                    attendingCount += session.attending.length
                  })
                  console.log(
                    '🚀 ~ file: EventList.js ~ line 732 ~ EventList ~ this.state.upcomingEvnts.map ~ this.state.events',
                    this.state.events,
                  )
                    // console.log('post' + JSON.stringify(post))
                    // var open = false
                    // console.log('post.id' + post.id)
                    // if (post.id === eventId) {
                    //   open = true
                    // }
                  return (
                    <EventCard
                     upcomingEvents={this.state.upcomingEvnts}
                    // ^^ ~added ~sahil.
                      index={index}
                      post={post}
                      event={this.state.events[index]}
                      eventDate={eventDate}
                      eventTime={eventTime}
                      attendingCount={attendingCount}
                      fetchEventDetails={this.fetchEventDetails}
                      fetchAttendeesDetails={this.fetchAttendeesDetails}
                      joinMeeting={this.joinMeeting}
                      items={items}
                      icon={icon}
                      cancelrsvp={this.cancelrsvp}
                      onSelectRsvpTime={this.onSelectRsvpTime}
                      handleSubmitEvent={this.handleOpenConfirmRsvp}
                      eventData={this.state.eventModel}
                      session={this.state.session}
                      rsvp={this.rsvp}
                      collapsible={false}
                      singleEvent={true}
                      isLessOn={false}
                      shareClicked={this.shareClicked}
                      closeSingleEvent={this.clsoeEventDetailExternalView}
                      myBookingeventData={this.props.eventData}
                      user = {this.state.user}

                    />
                  )
                })}
            </div>{' '}
          </Modal>
        ) : (
          ''
        )}
        {/* MODAL EXTERNAL EVENT DETAILS END *******************************************************************/}
        <EventModal
          flag={this.state.isOpen}
          eventData={this.state.eventModel}
          session={this.state.session}
          rsvp={this.rsvp}
        />
        <div className={`${this.props.eventData ? "myBookingClass" : "main-wrap"}  event-layout-wrapper`}>
          {/* <div> these are eventsList 1</div> */}
          <div className="breadcrumb-row"></div>
          {bannerList.title && !this.props.eventData &&   (
            <div
              className="news-banner"
              style={{
                background: bannerList.backgroundcolor.includes('rgb')
                  ? bannerList.backgroundcolor
                  : bannerList.backgroundcolor.includes('#')
                  ? bannerList.backgroundcolor
                  : `#${bannerList.backgroundcolor}`,
              }}
            >
              <div className="move-center">
                <div className="lunch-menu">{bannerList.title}</div>
                <div className="order-lunch">{bannerList.subtitle}</div>
                {/* <Link>   */}
                <Button
                  variant="contained"
                  // onClick={()=> onSubmit()}
                  style={{
                    width: 154,
                    height: 36,
                    borderRadius: 50,
                    backgroundColor: '#FCFCFC',
                    // ^^ redundant IMO ~sahil.
                    marginTop: 20,
                    marginBottom: 10,
                    // float: "right",
                  }}
                  // onClick={() => saveteam()}
                  // onClick={() => { history.push('/new-location') }}
                  //  onClick={() => this.setState({visible:bannerList.url, showimage: true,})}
                  //  onClick= {() => window.open(bannerList.url)}
                  // onClick={() => this.props.history.push('/bannerurl#/')}
                  // ~sahil
                  onClick={() => window.open(bannerList.url)}
                >
                  <div
                    style={{
                      color: bannerList.backgroundcolor.includes('rgb')
                        ? bannerList.backgroundcolor
                        : bannerList.backgroundcolor.includes('#')
                        ? bannerList.backgroundcolor
                        : `#${bannerList.backgroundcolor}`,
                      textTransform: 'none',
                    }}
                  >
                    {bannerList.buttonText}
                  </div>
                </Button>
                {/* </Link>      */}
              </div>
            </div>
          )}
          <div className="row event-title-box views_row">
         { this.props.eventData &&  <p className='my-booking-header'>Events Bookings List</p>}
            {this.state.upcomingEvnts &&
              this.state.upcomingEvnts.map((post, index) => {
                // Thu, Dec 31 && 7:00 pm format

                let eventDate = getDate(post.startDate)
                let eventTime = getTime(post.ses_det[0].startDateTime)

                let attendingCount = 0
                post.ses_det.map(session => {
                  attendingCount += session.attending.length
                })
                console.log(
                  '🚀 ~ file: EventList.js ~ line 732 ~ EventList ~ this.state.upcomingEvnts.map ~ this.state.events',
                  this.state.events,
                )
                console.log('post' + JSON.stringify(post))
                var open = false
                // const eventId = path.substring(path.lastIndexOf("/") + 1);
                // console.log("post.id", this.state.user);
                // if (post.id == eventId) {
                //   open = true;
                // }
                return (
                  <>
                  {/* {index==0&&this.props.eventData && !this.state.loading &&<p className='my-booking-header'>Events Bookings List</p>} */}
                  <EventCard
                    upcomingEvents={this.state.upcomingEvnts}
                    index={index}
                    post={post}
                    event={this.state.events[index]}
                    eventDate={eventDate}
                    eventTime={eventTime}
                    attendingCount={attendingCount}
                    fetchEventDetails={this.fetchEventDetails}
                    fetchAttendeesDetails={this.fetchAttendeesDetails}
                    joinMeeting={this.joinMeeting}
                    items={items}
                    icon={icon}
                    cancelrsvp={this.cancelrsvp}
                    onSelectRsvpTime={this.onSelectRsvpTime}
                    handleSubmitEvent={this.handleOpenConfirmRsvp}
                    eventData={this.state.eventModel}
                    session={this.state.session}
                    rsvp={this.rsvp}
                    collapsible={false}
                    isLessOn={false}
                    shareClicked={this.shareClicked}
                    myBookingeventData={this.props.eventData}
                    user = {this.state.user}
                  /></>
                )
              })}
          </div>{' '}
        </div>
        {this.state.openconfirmModel ? (
          <ConfirmeCard
            handleCloseModel={this.handleConfirmClose}
            handelSubmitModel={this.handleConfirmSubmit}
          />
        ) : (
          ''
        )}
        {this.state.openCalenderModel ? (
          <ChooseCalender
            handleCloseModel={this.handleCalenderClose}
            handelSubmitModel={this.handleCalenderSubmit}
            eventData={this.state.eventData}
          />
        ) : (
          ''
        )}
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
  }
}

const mapStateToProps = state => {
  return {
    posts: state.firestore.ordered.posts,
    auth: state.firebase.auth,
    notifications: state.firestore.ordered.notifications,
    profileDetails: state.profileDetails, // ~sahil
  }
}

const enhance = compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
  // firestoreConnect([
  //   { collection: 'posts', orderBy: ['createdAt', 'desc'] },
  //   { collection: 'notifications', limit: 3, orderBy: ['time', 'desc'] },
  // ]),
)

export default withRouter(enhance(EventList)) ///! OLD

const enhance2 = compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
  // firestoreConnect([
  //   { collection: 'posts', orderBy: ['createdAt', 'desc'] },
  //   { collection: 'notifications', limit: 3, orderBy: ['time', 'desc'] },
  // ]),
)

// const EventListParent = props => {
//   const isProfileDetailsLoaded =
//     props.profileDetails && props.profileDetails.isProfileDetailsLoaded

//   return isProfileDetailsLoaded ? <EventList /> : null
// }

// export default withRouter(enhance2(EventListParent))
