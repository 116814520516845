import React, {useEffect, useState} from 'react'
import getMyBookings from '../../requests/getMyBookings'
import _ from "lodash"
import EventList from '../dashboard/Events/EventList';
import Bookings from '../booking/Bookings';
import "./mybooking.css"
import {withRouter} from 'react-router-dom';
import {compose} from 'redux'
import UserProfile from '../auth/UserProfile'
import {connect} from 'react-redux'
import {Backdrop, CircularProgress, withStyles} from '@material-ui/core'
import * as firebase from 'firebase'
import getProfileDetailsLegacy from '../../requests/getProfileDetailsLegacy'
import $ from 'jquery'





firebase.firestore().settings({ timestampsInSnapshots: true })
firebase.auth()
firebase.analytics()

const styles = theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        // color: '#287fde',
        color: '#287fde',
    },
})

const MyBookings = (props) => {
    const [eventData, setEventData] = useState([]);
    const [bookingData, setBookingData] = useState([])
    const [loading, setloading] = useState(true);
    const [buildingId,setBuildingId]=useState("")

    useEffect(() => {
       if(props.profileDetails.activeBuilding){
         if(props.profileDetails.activeBuilding._id!==buildingId){
          getAllEventBookingList(props.profileDetails.activeBuilding._id)
         }
       }
    }, [props.profileDetails.activeBuilding ])

    useEffect(()=>{
      GetUser()
    },[])

    const GetUser  = async ()=> {
        const profileDetailsReq = await getProfileDetailsLegacy()
        if (profileDetailsReq.status === 'fulfilled') {
            const isStatusZero =
              profileDetailsReq.value && profileDetailsReq.value.status === 0
            if (isStatusZero) {
              return // handle status code 0 from backend.
            }
            const data = profileDetailsReq.value.data
            props.dispatch({
              type: 'profileDetails/update',
              payload: data,
            })
            getAllEventBookingList(profileDetailsReq.value.data.activeBuilding._id)
          }
          if (profileDetailsReq.status === 'rejected') {
            alert('rejected profile details request.')
          }
          if (window.innerWidth >= 655) {
            $(document).ready(function() {
              document.body.classList.add('open-sidebar')
              $('.modal-toggle').on('click', function(e) {
                e.preventDefault()
                $('.modal').toggleClass('is-visible')
              })
            })
          } else {
            $(document).ready(function() {
              document.body.classList.remove('open-sidebar')
              $('.modal-toggle').on('click', function(e) {
                e.preventDefault()
                $('.modal').toggleClass('is-visible')
              })
            })
          }
    }

    const getAllEventBookingList = async (id) => {
        await getMyBookings(id).then((result) => {
            let res = _.groupBy(result.value.data.list, "type")
            setEventData(res.event||[])
            setBookingData(res.booking||[])
            setloading(false)
        }).catch((err) => {
        });
    }

   const removeCard = (id,flag)=>{
          if(flag=="event"){
             let newEvent = eventData.filter((d)=>d.id!==id)
             setEventData(newEvent)
          }else{
            let newBook = bookingData.filter((d)=>d._id!==id)
            setBookingData(newBook)
          }
    }
    
    return (
        <div style={{width:"100%"}} >
            <Backdrop
             className={props.classes.backdrop}
             open={loading}
            >
            <CircularProgress color="inherit" />
            </Backdrop>
            <div className='main-wrap mybooking-layout-wrapper'>
           {!(eventData.length||bookingData.length) &&<div className="row event-title-box views_row"></div>}
            {eventData && eventData.length ? <>               
            <EventList eventData={eventData} refetch={getAllEventBookingList} removeCard={removeCard} >
            </EventList></> : ""}
            {bookingData && bookingData.length ? <>
            <Bookings bookingData={bookingData} refetch={getAllEventBookingList} removeCard={removeCard}>
            </Bookings>
            </>: ""}
             {
            !loading && eventData.length ==0 && bookingData.length==0 && <div className='no-found-class'>NO BOOKING FOUND </div>
            } 
            </div>
        </div>
    )
}

const mapStateToProps = state => {

    return {
        profileDetails: state.profileDetails,
    }
}

const enhance = compose(
  withStyles(styles, {withTheme: true}),
    connect(mapStateToProps),
    // firestoreConnect([
    //   { collection: 'posts', orderBy: ['createdAt', 'desc'] },
    //   { collection: 'notifications', limit: 3, orderBy: ['time', 'desc'] },
    // ]),
)

export default enhance(withRouter(MyBookings))
