import React, { Component } from 'react'
import { createPost } from '../../store/actions/postActions'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Select from 'react-select'
import Navbar from '../../components/layout/Navbar'
class CreatePost extends Component {
  async componentDidMount() {
    const response = await fetch(
      'https://api.betterspaces.com/api/v1/buildings/list',
    )
    const data = await response.json()
    var bboption = []
    await data.forEach(function(entry) {
      var obj = {}
      obj['value'] = entry._id
      obj['label'] = entry.name
      bboption.push(obj)
    })
    this.setState({ boptions: bboption })
  }

  state = {
    title: '',
    content: '',
    imagelink: '',
    zoomlink: '',
    zoomuser: '',
    zoompswd: '',
    zoompswd: '',
    zoompswd: '',
    buildingId: '',
    buildingName: '',
    selectedOption: null,
    boptions: [],
  }

  handleChangeDropDown = selectedOption => {
    this.setState({ selectedOption }, () => {
      this.setState({
        buildingId: this.state.selectedOption.value,
        buildingName: this.state.selectedOption.label,
      })
    })
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value,
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.createPost(this.state)
    this.props.history.push('/')
  }

  render() {
    const { auth } = this.props
    if (localStorage.getItem('accessToken') === false && !auth.uid) {
      alert('got it 10')
      return <Redirect to="/signin" />
    }
    const { selectedOption } = this.state
    const { boptions } = this.state
    return (
      <div className="container">
        <Navbar />
        <form onSubmit={this.handleSubmit} className="white z-depth-1">
          <h5 className="grey-text text-darken-3">New Post</h5>
          <div className="input-field">
            <label htmlFor="title">Title</label>
            <input type="text" id="title" onChange={this.handleChange} />
          </div>
          <div className="input-field">
            <label htmlFor="content">Post Content</label>
            <textarea
              id="content"
              className="materialize-textarea"
              onChange={this.handleChange}
            ></textarea>
          </div>
          <div className="input-field">
            <label htmlFor="imagelink">Image Link</label>
            <input type="text" id="imagelink" onChange={this.handleChange} />
          </div>
          <div className="input-field">
            <label htmlFor="zoomlink">Zoom Link</label>
            <input type="text" id="zoomlink" onChange={this.handleChange} />
          </div>
          <div className="input-field">
            <label htmlFor="zoomuser">Zoom Meeting ID</label>
            <input type="text" id="zoomuser" onChange={this.handleChange} />
          </div>
          <div className="input-field">
            <label htmlFor="zoompswd">Zoom Password</label>
            <input type="text" id="zoompswd" onChange={this.handleChange} />
          </div>

          <div className="input-field">
            <label htmlFor="zoompswd">Building</label>
            <Select
              value={selectedOption}
              onChange={this.handleChangeDropDown}
              options={boptions}
            />
          </div>

          <div className="input-field">
            <button className="btn pink lighten-1 z-depth-0">Create</button>
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createPost: post => dispatch(createPost(post)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePost)
