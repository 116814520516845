import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import getAmentyDetails from '../../requests/getAmentyDetails'
import betterDescription from './betterDescription'
import upArrow from '../../assets/img/upArrow.svg'
import downArrow from '../../assets/img/downArrow.svg'
import getProfileDetailsLegacy from '../../requests/getProfileDetailsLegacy'
let l = console.log

const AmentyCard = props => {
  l("amenity-cardd--", props);
  const { amenity } = props // amenty is passed to the component(not from redux) in file `Amenities.js`.
  const [isExpanded, setIsExpanded] = useState(false)
  // const [isExpanded, setIsExpanded] = useState(true) // !for testing only.
  // l('~sahil, amenty-card props', props)

  let description
  if (
    props.amenitiesDetails &&
    props.amenitiesDetails.length !== 0 &&
    amenity._id
  ) {
    l('~sahil, props.amenitiesDetails', props.amenitiesDetails)
    l('~sahil, props.amenity._id', props.amenity._id)
    let amenityDetailsFound = props.amenitiesDetails.find(
      amenityItem => amenityItem._id === amenity._id,
    )
    if (amenityDetailsFound) {
      description = amenityDetailsFound.description
    }
  }

  useEffect(() => {
    const main = async () => {
      if (!isExpanded) return // i.e., don't execute the effect if the isExpaned is false.

      const isFound =
        props.amenitiesDetails.findIndex(item => item._id === amenity._id) !==
        -1

      if (isFound) return

      const amenityDetailsRequest = await getAmentyDetails(amenity._id)
      if (amenityDetailsRequest.status === 'fulfilled') {
        props.setItemInAmenityDetailsList(amenityDetailsRequest.value)
        // console.log('~sahil, got value:AmentyCard', amenityDetailsRequest.value)
      }
      if (amenityDetailsRequest.status === 'rejected') {
        // console.log('shit, got reason:AmentyCard', amenityDetailsRequest.reason)
      }
    }

    main()
  }, [isExpanded])

  const dynamicArrow = (
    <div
      onClick={() => {
        // if (isExpanded) return // dont' do anything if already expanded. // ! for testing only!!

        setIsExpanded(!isExpanded)
        // alert(amenty._id)
      }}
    >
      {isExpanded ? (
        <img className="amenity__arrows" src={upArrow} />
      ) : (
        <img className="amenity__arrows" src={downArrow} />
      )}
    </div>
  )

  const title = (
    <div>
      <h4>{amenity.name || 'NA'}</h4>
    </div>
  )

  const reserveLinkFn = link => {
    // request to auth0 link....
    // https://dev-betterspaces.us.auth0.com/samlp/1SA1yj6TWT1rQeVDtaGG57b9AZNAn1vI
    window.open(link, '_blank')
  }

  // isExpanded ++++
  let shouldShowReserveButton = false

  // inherited code from arooshi.
  const isWebLink = amenity.webLink
  const isMaxSlot = amenity.maxSlotBook

  if (!isMaxSlot && isWebLink) {
    // ? there's no maxSlotBook, but there's a webLink.
    shouldShowReserveButton = true
  }

  return (
    <div className="amenty-card">
      <img
        className="amenty-image"
        src={amenity.listImage || 'assets/img/user-img.png'}
      />
      {/* {!isExpanded ? downArrowE : upArrowE}  */}
      {/* ^^ little bugg.. */}
      <div className="amenity-description">
        <div className="amenity-description__row">
          {title}
          {dynamicArrow}
        </div>
        <pre  className="desc-class"
 >   {isExpanded ? betterDescription(description) : null}</pre>
       {isExpanded && shouldShowReserveButton && (
          <button
            type="button"
            className="btn btn-primary book-btn"
            onClick={() => reserveLinkFn(amenity.webLink)}
            style={{ 'text-transform': 'capitalize' }}
          >
            Reserve
          </button>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => state.amenities

const mapDispatchToProps = dispatch => {
  return {
    setItemInAmenityDetailsList: amenity =>
      dispatch({ type: 'amenities/amenityDetails', payload: amenity }),
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AmentyCard)
